import { parseErrorMessage } from 'lib/api-utils'
import { FLASH_MESSAGE, UPDATE_CART_SUMMARY } from 'shared/constants'
import { addCartItem } from 'src/api/queries'
import { RegItem } from 'src/types/regItem'
import EzAddToCartConfirmationModal from 'src/components/EzAddToCartConfirmationModal'
import NiceModal from '@ebay/nice-modal-react'
import { track, useTracking } from 'lib/analytics'
import { Cart } from 'src/types/cart'

type partialRegItem = Pick<RegItem, 'id' | 'productId' | 'title' | 'price'>
interface OnSuccessProps {
  regItem: partialRegItem
  quantity?: number
}
export interface AddRegItemToCartProps {
  regItem: partialRegItem
  onSuccess?: ({ regItem, quantity }: OnSuccessProps) => void
  quantity?: number
  shouldShowConfirmation?: boolean
  additionalTrackingProps?: Record<string, unknown>
}

const isItemEnabledForEZAddToCart = (
  regItem: Pick<RegItem, 'canAddDirectlyToCart'>
) => !!regItem.canAddDirectlyToCart

interface trackAddToCartProps {
  tracker: any
  regItem: partialRegItem
  quantity: number
  cart: Cart
  additionalTrackingProps?: Record<string, unknown>
}
const trackAddToCart = ({
  tracker,
  quantity,
  cart,
  regItem,
  additionalTrackingProps = {},
}: trackAddToCartProps) => {
  tracker.trackEvent({
    cartItemQuantity: quantity,
    storeAdded: track.StoreAdded.BABYLIST,
    cartTypes: [track.CartTypes.STORE],
    cartStores: [track.CartStores.BABYLIST_STORE],
    cartTotalValue: cart?.checkoutTotals?.itemsSubtotal,
    productPrice: regItem.price,
    // no gift cards currently supported
    productType: track.ProductType.PRODUCT,
    // we don't subscribe at this point so sneding false
    subscribeToMarketingEmail: false,
    event: track.addedToCart,
    ...additionalTrackingProps,
  })
}

const useEZAddToCart = () => {
  const tracker = useTracking()
  const addRegItemToCart = ({
    regItem,
    onSuccess,
    quantity = 1,
    shouldShowConfirmation = true,
    additionalTrackingProps,
  }: AddRegItemToCartProps) => {
    addCartItem({
      regItemId: regItem.id,
      productId: regItem.productId,
      quantity,
    })
      .then((response) => {
        trackAddToCart({
          regItem,
          quantity,
          cart: response.cart,
          tracker,
          additionalTrackingProps,
        })
        if (onSuccess) onSuccess({ regItem, quantity })
        if (shouldShowConfirmation) {
          NiceModal.show(EzAddToCartConfirmationModal, { regItem, quantity })
        }
        PubSub.publish(UPDATE_CART_SUMMARY, { animateBadge: true })
      })
      .catch((resp) => {
        PubSub.publish(FLASH_MESSAGE, {
          message: parseErrorMessage(resp),
          variant: 'danger',
        })
      })
  }
  return { isItemEnabledForEZAddToCart, addRegItemToCart }
}

export default useEZAddToCart
