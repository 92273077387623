import { useLayoutEffect, useState, MutableRefObject } from 'react'
import { debounce } from 'lodash'
import { TRUNCATED_COPY_HEIGHT } from './ExpandableTextBox.constants'
import { ExpandableTextBoxProps } from './ExpandableTextBox.types'

const THROTTLE_MS = 50

type Size = ExpandableTextBoxProps['size']

export const useShouldTruncateFor = (
  ref: MutableRefObject<HTMLDivElement | null>,
  size: Size
) => {
  const [shouldTruncate, setShouldTruncate] = useState(false)

  useLayoutEffect(() => {
    const windowAvailable = typeof window === 'object'
    if (!windowAvailable || !ref.current) return () => undefined

    const calculateTruncationEligibility = () => {
      if (!ref.current) return

      const needsToTruncate =
        (ref.current.scrollHeight || ref.current.offsetHeight) >
        TRUNCATED_COPY_HEIGHT[size]
      setShouldTruncate(needsToTruncate)
    }

    calculateTruncationEligibility()
    const handleResize = debounce(calculateTruncationEligibility, THROTTLE_MS)

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [size])

  return shouldTruncate
}
