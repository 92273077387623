import React from 'react'

interface ToysRUsStoreProps {
  className?: string
  rounded?: boolean
}

const ToysRUsStore: React.FC<ToysRUsStoreProps> = ({
  className,
  rounded = true,
}: ToysRUsStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3733_58)">
      {rounded ? (
        <rect fill="#FAFAFA" height="24" rx="12" width="24" />
      ) : (
        <rect fill="#FAFAFA" height="24" width="24" />
      )}
      <path
        d="M19.7434 4.8649C15.74 2.87205 11.7858 2.66029 9.32552 3.37242C4.77834 4.69194 3.36854 10.2718 7.58146 13.0057C7.83634 13.171 7.67631 13.5192 7.35651 14.0762C6.9619 14.7672 6.22897 16.1286 4.93608 17.1098C4.15624 17.7027 3.6508 17.9475 4.29167 18.6784C4.29167 18.6784 5.71643 20.2173 6.76814 20.6659C8.03694 21.2015 8.35446 21.1125 9.29889 20.2581C9.89208 19.7215 11.0691 18.2531 12.489 15.9174C13.2252 14.7026 14.2108 14.6792 14.0763 16.6779C14.0763 16.6779 13.8823 18.6015 13.8293 18.9992C13.6706 20.1457 13.7941 20.5437 14.8567 20.5535C14.8567 20.5535 17.1419 20.4303 17.9345 20.3233C19.0501 20.1767 18.9895 20.0025 19.2816 19.1526C20.8426 14.6176 20.975 6.83187 20.975 6.83187C21.0409 5.7898 20.6993 5.34219 19.7434 4.8649ZM16.8201 9.06362C16.8201 9.06362 15.4027 10.0027 15.3672 10.0271C15.247 10.1186 15.0816 10.2254 15.0596 10.6598C15.051 10.8406 14.9957 12.1226 14.9924 12.2814C14.9891 12.4587 14.9097 12.9418 14.332 12.4488C14.332 12.4488 13.3662 11.6319 13.223 11.5097C13.191 11.4833 12.859 11.1216 12.3432 11.3364L10.8309 11.9195C10.8309 11.9195 9.92023 12.2778 10.2598 11.4886C10.5885 10.7214 10.8089 10.0821 10.8814 9.87694C10.9565 9.66315 10.9298 9.3611 10.746 9.14934C10.661 9.05449 9.93595 8.12857 9.76908 7.89931C9.76908 7.89931 9.17285 7.21355 10.167 7.25996C10.167 7.25996 11.8646 7.29623 11.891 7.29953C11.9197 7.30181 12.2372 7.3226 12.4644 6.9777C12.7444 6.55341 13.5646 5.55902 13.3936 5.76951C13.5369 5.59655 13.8699 5.16212 14.0903 5.88085C14.0903 5.88085 14.4022 6.82883 14.5501 7.22573C14.6515 7.4948 14.7154 7.67867 15.2204 7.83413L16.78 8.37862C16.7806 8.37913 17.5092 8.59622 16.8201 9.06362Z"
        fill="#0862AD"
      />
    </g>
    <defs>
      <clipPath id="clip0_3733_58">
        <rect fill="white" height="24" width="24" />
      </clipPath>
    </defs>
  </svg>
)

export default ToysRUsStore
