/* eslint-disable camelcase */ /* TODO: should the API be camelCase? */
import React, { useEffect, useState, useMemo } from 'react'

import { track } from 'lib/analytics'

import { getPlacementScreen } from 'lib/placements/getPlacementScreen'
import { PlacementAd, filteredAds } from 'lib/placements/filteredAds'

import { placementsDecisionPath } from 'lib/urls'
import fetch from 'lib/fetch'
import { useQuery } from '@tanstack/react-query'
import withReactQueryClientProvider from 'shared/HOC/withReactQueryClientProvider'
import Image from 'components/image'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button, Modal } from 'baby-design'
import { useMediaQuery, useTheme } from '@material-ui/core'
import css from './PlacementInterstitialModal.scss'

interface AdProps {
  ad: PlacementAd
  unitId: string
  show: boolean
  onHide: () => void
}

export interface PlacementsInterstitialModalProps {
  unitId: string
}

const Ad: React.FC<AdProps> = ({ unitId, ad, show, onHide }) => {
  const trackingContext = useMemo(
    () => ({
      eventLocation: track.EventLocation.WEB_TOP_NAV,
      adId: ad.adId,
      adTitle: ad.content.title,
      adUnitId: unitId,
      adCtaText: ad.content.ctaText || '',
    }),
    [ad, unitId]
  )

  useEffect(() => {
    track.targetedPlacementViewed(trackingContext)
  }, [trackingContext])

  useEffect(() => {
    if (ad.tracking?.impressionUrl) {
      fetch(ad.tracking.impressionUrl, { method: 'POST' })
    }
  }, [ad.tracking?.impressionUrl])

  const handleCtaClick = () => {
    track.targetedPlacementClicked(trackingContext)
  }

  const handleOnHide = () => {
    track.targetedPlacementDismissed(trackingContext)
    onHide()
  }
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { content, contentType } = ad
  const interstitialBigImage = 'placementInterstitialFullImage'
  const fullImage = contentType === interstitialBigImage

  const icon = (
    <Image
      className={fullImage ? css.fullImage : css.icon}
      role="presentation"
      src={content.imageUrl}
    />
  )
  const primaryButton = (
    <Button
      block
      className={css.primaryCta}
      href={content.ctaUrl}
      size={isMobile ? 'md' : 'lg'}
      target="_blank"
      variant="primary"
      onClick={handleCtaClick}
    >
      {content.ctaText}
    </Button>
  )

  const secondaryButton = content.secondaryCtaUrl ? (
    <Button
      block
      className={css.secondaryCta}
      href={content.secondaryCtaUrl}
      size={isMobile ? 'md' : 'lg'}
      target="_blank"
      variant="secondary"
      onClick={handleCtaClick}
    >
      {content.secondaryCtaText}
    </Button>
  ) : null

  return (
    <Modal
      showCloseButton
      handleClose={handleOnHide}
      icon={icon}
      iconAlignment={isMobile ? 'center' : 'left'}
      isOpen={show}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      subTitle={content.body}
      title={content.title}
    >
      {null}
    </Modal>
  )
}

const PlacementsInterstitialModal: React.FC<
  PlacementsInterstitialModalProps
> = ({ unitId }) => {
  const [show, setShow] = useState(false)
  const [currentAd, setCurrentAd] = useState<PlacementAd | null>(null)
  const onHide = () => setShow(false)

  let pathname = '/'
  let host = 'www.babylist.com'

  if (typeof window !== 'undefined') {
    pathname = window.location.pathname
    // eslint-disable-next-line prefer-destructuring
    host = window.location.host
  }

  const requestPath = `${placementsDecisionPath}?unit_ids=${unitId}`
  const result = useQuery({
    queryKey: [`placementInterstitial-${unitId}`, requestPath],
    queryFn: () => fetch(requestPath, { method: 'GET' }),
    retry: false,
  })

  useEffect(() => {
    if (result.isLoading) {
      return
    }
    if (result.isError) {
      console.error('Error fetching ads', result.error)
      return
    }
    const { decisions } = result.data
    let ads = []
    if (decisions && decisions.length) {
      ads = decisions[0].ads
    }
    if (ads.length > 0) {
      const currentScreen = getPlacementScreen(pathname, host)
      ads = filteredAds(ads, currentScreen)
      setCurrentAd(ads[0])
      setShow(true)
    }
  }, [result.isLoading])

  if (!currentAd) {
    return null
  }

  return <Ad ad={currentAd} show={show} unitId={unitId} onHide={onHide} />
}

export default withReactQueryClientProvider(PlacementsInterstitialModal)
