import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { debounce } from 'lodash'
import classNames from 'classnames'
import CopyAndIcon from '../CopyAndIcon/CopyAndIcon'
import TextLink from '../TextLink/TextLink'
import TitleAndCopy from '../TitleAndCopy/TitleAndCopy'
import {
  TRUNCATED_COPY_HEIGHT,
  TRANSITION_DURATION,
} from './ExpandableTextBox.constants'
import { useShouldTruncateFor } from './ExpandableTextBox.hooks'
import styles from './ExpandableTextBox.styles.scss'
import { ExpandableTextBoxProps } from './ExpandableTextBox.types'

const ExpandableTextBox: React.FC<ExpandableTextBoxProps> = ({
  fadeColor = 'white',
  copy,
  icon,
  size,
  title,
}) => {
  const [expanded, setExpanded] = useState(false)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const expandableBlockRef = useRef<HTMLDivElement>(null)
  const truncatedCopyRef = useRef<HTMLDivElement>(null)
  const shouldTruncate = useShouldTruncateFor(truncatedCopyRef, size)

  useEffect(() => {
    if (!shouldTruncate && expanded) {
      setExpanded(false)
    }

    if (
      !expandableBlockRef.current ||
      !truncatedCopyRef.current ||
      !shouldTruncate
    ) {
      return
    }

    if (expanded) {
      const maxHeight =
        truncatedCopyRef.current.offsetHeight + TRUNCATED_COPY_HEIGHT[size] / 2
      expandableBlockRef.current.style.maxHeight = `${maxHeight}px`
    } else {
      expandableBlockRef.current.style.maxHeight = `${TRUNCATED_COPY_HEIGHT[size]}px`
    }
  }, [shouldTruncate, expanded, size])

  useLayoutEffect(() => {
    const windowAvailable = typeof window === 'object'
    if (!windowAvailable || !expandableBlockRef.current) return () => undefined

    const adjustExpandableMaxHeight = debounce(() => {
      if (!expandableBlockRef.current) return

      if (expanded && !isTransitioning) {
        // Changing the transition property to 'none' and then back to '' allows the
        // expandable block to expand without triggering the transition effect when
        // the window is resized.
        expandableBlockRef.current.style.transition = 'none'
        expandableBlockRef.current.style.maxHeight = `${expandableBlockRef.current.scrollHeight}px`
        expandableBlockRef.current.style.transition = ''
      }
    }, 10)

    window.addEventListener('resize', adjustExpandableMaxHeight)
    return () => {
      window.removeEventListener('resize', adjustExpandableMaxHeight)
    }
  }, [expanded, isTransitioning])

  const toggleExpanded = () => {
    setExpanded(!expanded)
    setIsTransitioning(true)
    setTimeout(() => setIsTransitioning(false), TRANSITION_DURATION)
  }

  if (!copy) return null

  const moreOrLessLinkClasses = classNames(styles.more_or_less_link, {
    [styles.more_or_less_link__expanded]: expanded,
    [styles.more_or_less_link__collapsed]: !expanded,
  })
  const innerTextLinkClasses = classNames(styles.more_or_less_link_inner, {
    [styles.more_or_less_link_inner__noTitle]: !title,
  })
  const expandableBlockClasses = classNames(
    styles.expandable_block,
    styles[`expandable_block__${size}`]
  )
  const copyBlockClasses = classNames(styles.copy_block, {
    [styles.copy_block__truncated]: shouldTruncate && !expanded,
    [styles[`copy_block__truncated__${size}`]]: shouldTruncate && !expanded,
    [styles[`copy_block__truncated__${fadeColor}`]]:
      shouldTruncate && !expanded,
    [styles.copy_block__collapsed]: !expanded && !isTransitioning,
  })

  const copyComponent = (
    <div
      className={expandableBlockClasses}
      data-testid="expandable_block"
      ref={expandableBlockRef}
    >
      <span className={copyBlockClasses} ref={truncatedCopyRef}>
        {copy}
      </span>
      {shouldTruncate && !isTransitioning && (
        <TextLink
          aria-hidden="true"
          className={moreOrLessLinkClasses}
          innerContentClassName={innerTextLinkClasses}
          size={size}
          onClick={toggleExpanded}
        >
          {expanded ? 'Less' : 'More'}
        </TextLink>
      )}
    </div>
  )

  return (
    <div className={styles.ExpandableTextBox} data-testid="ExpandableTextBox">
      {icon ? (
        <CopyAndIcon
          copy={copyComponent}
          icon={icon}
          size={size}
          title={title}
        />
      ) : (
        <TitleAndCopy copy={copyComponent} size={size} title={title} />
      )}
    </div>
  )
}

export default ExpandableTextBox
