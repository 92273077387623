import React from 'react'
import classNames from 'classnames'
import {
  AdenAndAnaisStore,
  AmazonStore,
  BabyEarthStore,
  BabylistStore,
  GenericStore,
  TargetStore,
  WalmartStore,
  BedBathAndBeyondStore,
  BestBuyStore,
  BlueAirStore,
  BuyBuyBabyStore,
  CartersStore,
  ChaptersIndigoStore,
  CopperPearlStore,
  CostcoStore,
  CrateAndBarrelStore,
  EtsyStore,
  GapStore,
  HAndMStore,
  HonestStore,
  IkeaStore,
  KohlsStore,
  KyleCosmeticsStore,
  LaloStore,
  MacysStore,
  MagicBeansStore,
  MockingBirdStore,
  NordstromStore,
  OldNavyStore,
  PotteryBarnKidsStore,
  RHBabyAndChildStore,
  SkipHopStore,
  SpearmintLoveStore,
  StolleriaStore,
  TinyLandStore,
  ToysRUsStore,
  WayfairStore,
} from './svg'

import css from './StoreIcons.styles.scss'

import {
  StoreType,
  StoreComponents,
  StoreIconsProps,
  StoreIconProps,
} from './StoreIcons.types'

const storesData: StoreComponents = {
  adenandanais: AdenAndAnaisStore,
  amazon: AmazonStore,
  babyearth: BabyEarthStore,
  babylist: BabylistStore,
  walmart: WalmartStore,
  target: TargetStore,
  bedbathandbeyond: BedBathAndBeyondStore,
  bestbuy: BestBuyStore,
  blueair: BlueAirStore,
  buybuybaby: BuyBuyBabyStore,
  carters: CartersStore,
  chaptersindigo: ChaptersIndigoStore,
  copperpearl: CopperPearlStore,
  costco: CostcoStore,
  crateandbarrel: CrateAndBarrelStore,
  etsy: EtsyStore,
  gap: GapStore,
  default: GenericStore,
  handm: HAndMStore,
  honest: HonestStore,
  ikea: IkeaStore,
  kohls: KohlsStore,
  kyliecosmetics: KyleCosmeticsStore,
  lalo: LaloStore,
  macys: MacysStore,
  magicbeans: MagicBeansStore,
  mockingbird: MockingBirdStore,
  nordstrom: NordstromStore,
  oldnavy: OldNavyStore,
  potterybarnkids: PotteryBarnKidsStore,
  rhbabyandchild: RHBabyAndChildStore,
  skiphop: SkipHopStore,
  spearmintlove: SpearmintLoveStore,
  stolleria: StolleriaStore,
  tinyland: TinyLandStore,
  toysrus: ToysRUsStore,
  wayfair: WayfairStore,
}

export const StoreIcon: React.FC<StoreIconProps> = ({ store, className }) => {
  const StoreData = storesData[store.name] || storesData.default

  return (
    <div
      className={classNames(
        css.StoreIcons__underlay,
        css[`StoreIcons__underlay--icon`]
      )}
    >
      <span className={classNames(css.StoreIcons__visuallyHidden)}>
        {`${store.name} Icon`}
      </span>
      <span aria-hidden="true">
        <StoreData
          className={classNames(css.StoreIcons__icon, className)}
          data-testid="store-icon"
        />
      </span>
    </div>
  )
}

const StoreIcons: React.FC<StoreIconsProps> = ({
  className,
  stores,
  size = 'small',
  price,
  discount,
  inStock = true,
}) => {
  const StoreIconsClasses = classNames(
    css.StoreIcons,
    className,
    css[`StoreIcons__${size}`]
  )

  const additionalCount = Math.max(stores.length - 3, 0)

  return (
    <div className={StoreIconsClasses}>
      <div
        className={classNames(css.StoreIcons__wrapper)}
        data-testid="store-icons-wrapper"
      >
        {stores.slice(0, 3).map((store: StoreType) => (
          <StoreIcon key={store.name} store={store} />
        ))}
        {additionalCount > 0 && (
          <div
            className={classNames(
              css.StoreIcons__underlay,
              css[`StoreIcons__underlay--count`]
            )}
          >
            <div className={classNames(css.StoreIcons__additionalCount)}>
              +{additionalCount}
            </div>
          </div>
        )}
      </div>
      <span className={classNames(css.StoreIcons__price)}>
        {price}
        {discount && (
          <span className={classNames(css.StoreIcons__discount)}>
            {discount}
          </span>
        )}
        {!inStock && (
          <span className={classNames(css.StoreIcons__outOfStock)}>
            In stock soon
          </span>
        )}
      </span>
    </div>
  )
}

export default StoreIcons
