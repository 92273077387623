import React from 'react'

interface OldNavyStoreProps {
  className?: string
  rounded?: boolean
}

const OldNavyStore: React.FC<OldNavyStoreProps> = ({
  className,
  rounded = true,
}: OldNavyStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {rounded ? (
      <rect fill="#003764" height="24" rx="12" width="24" />
    ) : (
      <rect fill="#003764" height="24" width="24" />
    )}
    <path
      clipRule="evenodd"
      d="M2.45907 13.7941C1.55076 13.7941 0.983115 13.0401 0.981079 12.0168V12.0089C0.981079 10.9304 1.54567 10.2057 2.46178 10.2061C3.37789 10.2064 3.94079 10.9328 3.94011 12.0127C3.93774 13.0374 3.37009 13.7941 2.45907 13.7941ZM4.37951 13.7484V10.2667H5.17211V13.0711H6.42922L6.42888 13.7484H4.37951ZM9.52669 12.0049V12.0274C9.54527 12.5117 9.38979 12.9866 9.08865 13.3653C8.76428 13.7403 8.41684 13.7488 7.98185 13.7484H6.87336V10.2667H7.98423C8.41921 10.2667 8.76666 10.2684 9.09069 10.6417C9.39252 11.0228 9.54744 11.5005 9.52703 11.9871L9.52669 12.0049ZM13.6275 13.7484H12.8315L11.7294 11.7564V13.7478H10.9314V10.2667H11.7287L12.8335 12.2851V10.2667H13.6288L13.6275 13.7484ZM16.3517 13.7484L16.1705 13.0711H15.0583L14.8808 13.7484H14.0716L15.003 10.2667H16.2129L17.1457 13.7484H16.3517ZM18.9049 13.7484H17.8494L16.9594 10.2667H17.8389L18.378 12.6945L18.9209 10.2667H19.7993L18.9049 13.7484ZM21.981 12.6382V13.7484H21.0965V12.6379L20.0613 10.2667H20.9343L21.5393 11.7887L22.1466 10.2667H23.0193L21.981 12.6382ZM16.0019 12.4377H15.2256L15.6096 10.972L16.0019 12.4377ZM7.70802 10.9403H7.90516C8.01604 10.9237 8.12932 10.9393 8.23172 10.9851C8.33412 11.0309 8.4214 11.1051 8.48332 11.199C8.60875 11.4484 8.6741 11.724 8.6741 12.0034C8.6741 12.2829 8.60875 12.5585 8.48332 12.8079C8.42138 12.9026 8.33392 12.9777 8.23112 13.0243C8.12833 13.071 8.01446 13.0873 7.90278 13.0714H7.70802V10.9403ZM1.81642 12.0171C1.81642 11.637 1.91278 10.8728 2.46109 10.8728C3.0094 10.8734 3.10814 11.6418 3.1078 12.0174C3.10746 12.3931 2.93849 13.1161 2.46109 13.1157C1.9837 13.1154 1.81642 12.3972 1.81642 12.0171Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
)

export default OldNavyStore
