import { IneligibilityReason } from './IneligibilityReason'
import css from './InsuranceEligibilityValidatorTool.styles.scss'

interface HeadingProps {
  reason: IneligibilityReason
}

const copy = {
  [IneligibilityReason.StateNotSupported]: {
    title:
      "We're working hard to ship to your state, but we're not quite there.",
    subtitle:
      'Your insurance likely covers breast pumps so be sure to contact them directly to discuss your options.',
  },
  [IneligibilityReason.InsuranceNotSupported]: {
    title:
      "We're working hard to support your insurance, but we're not quite there.",
    subtitle:
      "It's likely that your insurance covers breast pumps! Contact them directly to discuss your options and let them know you'd like to use Babylist Health.",
  },
}

export default function IneligibilityHeading({ reason }: HeadingProps) {
  const text = copy[reason]
  return (
    <div data-testid="ineligibility-heading">
      <h2 className={css.InsuranceEligibilityValidatorTool__heading__title}>
        <b>{text.title}</b>
      </h2>

      <p className={css.InsuranceEligibilityValidatorTool__heading__subtitle}>
        {text.subtitle}
      </p>
    </div>
  )
}
