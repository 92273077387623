import { cloneElement } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Modal } from 'react-overlays'

// @FIXME: importing default in storybook is crapping out.
// work around is to use require and grab default
const Card = require('components/card').default
import { CloseButton } from 'components/buttons'

import { Backdrop } from './modal'
import css from './modal.scss'

const BubbleContentCard = ({ className, children }) => (
  <Card className={classNames(`bg-white`, className)}>{children}</Card>
)

export const BubbleModalHeader = ({
  children,
  onHide,
  className,
  showCloseButton = true,
  ...contentCardProps
}) => (
  <BubbleContentCard className="mbm" {...contentCardProps}>
    <div className="flex space-between">
      <div className={className}>{children}</div>
      {showCloseButton && (
        <div className="flex center prs">
          <CloseButton onClick={onHide} style={{ marginTop: '-2px' }} />
        </div>
      )}
    </div>
  </BubbleContentCard>
)

BubbleModalHeader.propTypes = {
  children: PropTypes.node,
  onHide: PropTypes.func,
}

export const BubbleModalBody = ({
  children,
  onHide,
  showCloseButton,
  title,
  ...contentCardProps
}) => (
  <BubbleContentCard className={css.modalBody} {...contentCardProps}>
    <div className={css.modalBodyTitleContainer}>
      <div />
      {title && (
        <p
          className={classNames(
            css.modalBodyTitleContainer__Heading,
            `phxl mbl-md`
          )}
        >
          {title}
        </p>
      )}
      {showCloseButton && (
        <CloseButton
          className={css.modalBodyTitleContainer__Close}
          onClick={onHide}
        />
      )}
    </div>
    {children}
  </BubbleContentCard>
)

export const BubbleModal = ({
  children,
  showHeaderToast = true,
  ...modalProps
}) => {
  const Header =
    showHeaderToast &&
    cloneElement(
      children.find(({ type }) => type === BubbleModalHeader),
      {
        onHide: modalProps.onHide,
        showCloseButton: showHeaderToast,
      }
    )
  const Body = cloneElement(
    children.find(({ type }) => type === BubbleModalBody),
    {
      onHide: modalProps.onHide,
      showCloseButton: !showHeaderToast,
    }
  )

  return (
    <Modal
      enforceFocus={showHeaderToast}
      renderBackdrop={Backdrop}
      className={css.modal}
      {...modalProps}
    >
      <div className={css.flexDialogContainer}>
        <div className={classNames(css.flexDialogContent, `pal`)}>
          {Header}
          {Body}
          {/* Need a spacer element to apply bottom padding. */}
          <div className="pbl" />
        </div>
      </div>
    </Modal>
  )
}

/**
 * Manually declare consumed PropTypes so storybook can statically
 * parse intended type for documentation vs extending with Modal.propTypes.
 */
BubbleModal.propTypes = {
  children: PropTypes.node,
  onHide: PropTypes.func,
  show: PropTypes.bool,
  showHeaderToast: PropTypes.bool,
  title: PropTypes.string,
}

BubbleModal.defaultProps = {
  show: false,
  showHeaderToast: true,
  title: '',
  onHide: () => {},
}

BubbleModal.Header = BubbleModalHeader
BubbleModal.Body = BubbleModalBody
