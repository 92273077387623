import React from 'react'

interface PotteryBarnKidsStoreProps {
  className?: string
  rounded?: boolean
}

const PotteryBarnKidsStore: React.FC<PotteryBarnKidsStoreProps> = ({
  className,
  rounded = true,
}: PotteryBarnKidsStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3733_40)">
      {rounded ? (
        <rect fill="#3167A2" height="24" rx="12" width="24" />
      ) : (
        <rect fill="#3167A2" height="24" width="24" />
      )}
      <path
        d="M15.1221 14.7322C15.1221 14.6099 15.275 14.4876 15.4738 14.457L15.8103 14.4111L15.8561 10.7255C15.8867 7.20806 15.8714 7.03983 15.5809 6.76456C15.4126 6.61163 15.275 6.4434 15.275 6.36694C15.275 6.25989 16.5596 5.77051 16.8349 5.77051C16.8961 5.77051 16.9572 7.14688 16.9572 8.82912C16.9572 11.4136 16.9878 11.8877 17.1866 11.8877C17.4466 11.8877 18.4254 11.0466 18.6548 10.6337C18.7924 10.389 18.7618 10.3278 18.4865 10.2514C18.3183 10.2055 18.196 10.0832 18.2266 9.99139C18.303 9.77729 20.7805 9.762 20.7805 9.9761C20.7805 10.0679 20.597 10.1749 20.3676 10.2361C20.1229 10.2973 19.5571 10.6949 19.0983 11.1078L18.2571 11.8419L19.3277 13.05C19.9241 13.6923 20.5817 14.304 20.8264 14.3958C21.0558 14.4876 21.2393 14.6405 21.2393 14.7475C21.2393 14.9005 20.8723 14.9463 19.8629 14.9463C18.7465 14.9463 18.4865 14.9005 18.4865 14.7169C18.4865 14.5793 18.6242 14.4876 18.8077 14.4876C19.1136 14.4876 19.1136 14.4723 18.7159 13.9982C17.7984 12.8818 17.2325 12.3465 17.0949 12.423C16.8961 12.5606 16.9267 14.0899 17.1408 14.304C17.2478 14.4111 17.4466 14.4876 17.5995 14.4876C17.7525 14.4876 17.8748 14.5946 17.8748 14.7169C17.8748 14.9005 17.6148 14.9463 16.4984 14.9463C15.4126 14.9463 15.1221 14.9005 15.1221 14.7322Z"
        fill="white"
      />
      <path
        d="M10.6635 14.9463C10.48 14.8699 10.22 14.8393 10.0977 14.8852C9.89887 14.9616 9.86828 14.4723 9.86828 11.0619C9.86828 7.34569 9.85299 7.13159 9.56242 6.84102C9.3942 6.6728 9.25656 6.47399 9.25656 6.39752C9.25656 6.25989 10.4341 5.77051 10.7553 5.77051C10.8929 5.77051 10.9388 6.32106 10.9388 7.85036V9.93022L11.367 9.80788C12.1622 9.56319 13.3398 9.63965 13.7833 9.99139C14.4715 10.4961 14.8079 11.0619 14.8844 11.8571C15.0679 13.7841 13.8292 15.0993 11.8564 15.084C11.3976 15.084 10.8623 15.0228 10.6635 14.9463ZM12.7893 14.3958C13.661 13.9523 13.9668 12.4077 13.401 11.3066C13.0798 10.6796 12.5599 10.3584 11.8564 10.3584C10.9541 10.3584 10.9388 10.4043 10.9388 12.1783C10.9388 13.8605 11.0153 14.1358 11.627 14.457C12.0399 14.6864 12.254 14.6864 12.7893 14.3958Z"
        fill="white"
      />
      <path
        d="M3.23929 17.9629C3.23929 17.8405 3.37693 17.7335 3.52986 17.7335C3.68279 17.7335 3.8663 17.6264 3.91218 17.4888C3.95806 17.3512 4.00394 15.8219 4.00394 14.0631C4.00394 11.4327 3.95806 10.8516 3.77455 10.6987C3.39222 10.3928 3.51456 10.1175 4.15687 9.78109C4.93682 9.38347 5.07446 9.39876 5.07446 9.84226C5.07446 10.2093 5.08975 10.2093 5.54854 9.9799C7.20019 9.15407 8.85184 9.99519 9.02007 11.7386C9.20358 13.6655 7.85779 15.1184 5.86969 15.1337H5.05916L5.10504 16.3877C5.15092 17.657 5.15092 17.657 5.59442 17.7335C5.83911 17.7794 6.0685 17.9017 6.11438 18.0088C6.16026 18.1464 5.80852 18.1923 4.70742 18.1923C3.49927 18.1923 3.23929 18.1464 3.23929 17.9629ZM7.16961 14.1855C7.67428 13.6349 7.82721 13.222 7.82721 12.4574C7.82721 11.5857 7.50605 10.9281 6.90962 10.6222C6.34378 10.3316 5.83911 10.3316 5.4109 10.6375C5.10504 10.8516 5.07446 10.9892 5.07446 12.4574C5.07446 13.9255 5.10504 14.0631 5.4109 14.2773C5.93087 14.6443 6.78728 14.5831 7.16961 14.1855Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3733_40">
        <rect fill="white" height="24" width="24" />
      </clipPath>
    </defs>
  </svg>
)

export default PotteryBarnKidsStore
