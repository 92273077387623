import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'

const useSecondhandItemsEnabled = () => {
  const { flagValue } = useFeatureFlag(
    '24f_ff_regex_secondhand_items_web',
    false
  )

  return flagValue
}

export default useSecondhandItemsEnabled
