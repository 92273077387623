/* eslint-disable import/no-extraneous-dependencies */
import { Dispatch, SetStateAction, useState } from 'react'
import StickyHeader from 'components/sticky-header'
import classNames from 'classnames'
import { sectionAnchor } from 'hello_baby/lib/helpers'
// @ts-ignore
import Tappable from 'react-tappable'
import { IconButton } from 'baby-design'
import { Chevron } from 'baby-design/icons'
import css from './TableOfContents.styles.scss'

export interface CollapsibleTableOfContentsProps {
  headings: Heading[]
  selectedHeading: string | null
  setSelectedHeading: Dispatch<SetStateAction<string | null>>
}

export const CollapsibleTableOfContents = ({
  headings,
  selectedHeading,
  setSelectedHeading,
}: CollapsibleTableOfContentsProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [headersDisplayed, setHeadersDisplayed] = useState(
    headings.length >= 6 ? headings.slice(0, 6) : headings
  )

  const handleCollapse = () => {
    if (isOpen) {
      setHeadersDisplayed(headings.slice(0, 6))
    } else {
      setHeadersDisplayed(headings)
    }
    setIsOpen(!isOpen)
  }
  return (
    <nav>
      <StickyHeader
        fromArticle
        className={css.TableOfContents__stickyHeader}
        headings={headings}
        selectedHeading={selectedHeading}
        setSelectedHeading={setSelectedHeading}
        title="In this Article"
      />
      <span className={css.TableOfContents__titleLine}>
        <h2>
          <strong>In this Article</strong>
        </h2>
      </span>
      <hr className={classNames('mbl', css.TableOfContents__titleLine)} />
      <div className={css.TableOfContents__mobileTocCollapsibleContainer}>
        <div className={css.TableOfContents__line} />
        <div className={css.TableOfContents__headingsContainer}>
          {headersDisplayed.map((heading) => (
            <a
              href={`#${sectionAnchor(heading.text)}`}
              key={heading.text}
              onClick={(e) => {
                e.preventDefault()
                const element: HTMLElement | null = document.getElementById(
                  sectionAnchor(heading.text)
                )
                if (element) {
                  element.scrollIntoView({ behavior: 'smooth' })
                }
              }}
            >
              <ul
                className={classNames(css.TableOfContents__mobileHeadingText, {
                  [css.TableOfContents__heading3]: heading.headingType === '3',
                })}
              >
                {heading.text}
              </ul>
            </a>
          ))}
          {headings.length > 6 && (
            <Tappable onClick={handleCollapse}>
              <IconButton
                aria-label="Toggle Contributers"
                className={classNames({
                  [css.TableOfContents__activeChevron]: isOpen,
                })}
                size="xs"
                variant="fill-tertiary"
                onClick={handleCollapse}
              >
                <Chevron />
              </IconButton>
            </Tappable>
          )}
        </div>
      </div>
    </nav>
  )
}
