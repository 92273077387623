import React from 'react'

interface CrateAndBarrelStoreProps {
  className?: string
  rounded?: boolean
}

const CrateAndBarrelStore: React.FC<CrateAndBarrelStoreProps> = ({
  className,
  rounded = true,
}: CrateAndBarrelStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {rounded ? (
      <g clipPath="url(#clip0_3112_3542)">
        <path
          d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
          fill="#FAFAFA"
        />
        <path
          clipRule="evenodd"
          d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="black"
          fillRule="evenodd"
        />
        <path
          d="M12.01 7.21512C14.9752 7.21512 16.5368 9.59181 16.7608 10.9907H19.5C19.1141 7.63614 16.1709 4.5 11.99 4.5C7.80913 4.5 4.50002 7.89587 4.50002 11.9902C4.50002 16.0845 7.77116 19.5 12.074 19.5C16.3769 19.5 18.9342 16.0432 19.3801 13.6271H16.6408C16.3968 14.4062 14.9352 16.8026 12.032 16.8026C9.43272 16.8026 7.2413 14.605 7.2413 12.0689C7.2413 9.29277 9.35071 7.21512 12.01 7.21512Z"
          fill="#252324"
        />
      </g>
    ) : (
      <g clipPath="url(#clip0_3733_44)">
        <rect fill="#FAFAFA" height="24" width="24" />
        <path
          clipRule="evenodd"
          d="M23 1H1V23H23V1ZM0 0V24H24V0H0Z"
          fill="black"
          fillRule="evenodd"
        />
        <path
          d="M12.01 7.21512C14.9752 7.21512 16.5368 9.59181 16.7608 10.9907H19.5C19.1141 7.63614 16.1709 4.5 11.99 4.5C7.80913 4.5 4.50002 7.89587 4.50002 11.9902C4.50002 16.0845 7.77116 19.5 12.074 19.5C16.3769 19.5 18.9342 16.0432 19.3801 13.6271H16.6408C16.3968 14.4062 14.9352 16.8026 12.032 16.8026C9.43272 16.8026 7.2413 14.605 7.2413 12.0689C7.2413 9.29277 9.35071 7.21512 12.01 7.21512Z"
          fill="#252324"
        />
      </g>
    )}
    {rounded ? (
      <defs>
        <clipPath id="clip0_3112_3542">
          <path
            d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
            fill="white"
          />
        </clipPath>
      </defs>
    ) : (
      <defs>
        <clipPath id="clip0_3733_44">
          <rect fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    )}
  </svg>
)

export default CrateAndBarrelStore
