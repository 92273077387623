import React, { ReactNode } from 'react'
import { IllustrationName, IllustrationProps } from './Illustration.types'

import styles from './Illustration.styles.scss'

import GrinningSunLight from './assets/GrinningSunLight.svg'
import GrinningSunDark from './assets/GrinningSunDark.svg'
import AvocadoLight from './assets/AvocadoLight.svg'
import BabyBalloonsLight from './assets/BabyBalloonsLight.svg'
import BirthdayCupcakeLight from './assets/BirthdayCupcakeLight.svg'
import BunInTheOvenLight from './assets/BunInTheOvenLight.svg'
import CrayonsLight from './assets/CrayonsLight.svg'
import FlowerSmileLight from './assets/FlowerSmileLight.svg'
import FlowerSunglassesLight from './assets/FlowerSunglassesLight.svg'
import GameControllerLight from './assets/GameControllerLight.svg'
import GreenSmileLight from './assets/GreenSmileLight.svg'
import HeartPuzzleLight from './assets/HeartPuzzleLight.svg'
import MeltingSmileLight from './assets/MeltingSmileLight.svg'
import ShootingStarLight from './assets/ShootingStarLight.svg'
import ThumbsDownLight from './assets/ThumbsDownLight.svg'
import ThumbsUpLight from './assets/ThumbsUpLight.svg'
import UpsideDownSmileLight from './assets/UpsideDownSmileLight.svg'
import AvocadoDark from './assets/AvocadoDark.svg'
import BabyBalloonsDark from './assets/BabyBalloonsDark.svg'
import BirthdayCupcakeDark from './assets/BirthdayCupcakeDark.svg'
import BunInTheOvenDark from './assets/BunInTheOvenDark.svg'
import CrayonsDark from './assets/CrayonsDark.svg'
import FlowerSmileDark from './assets/FlowerSmileDark.svg'
import FlowerSunglassesDark from './assets/FlowerSunglassesDark.svg'
import GameControllerDark from './assets/GameControllerDark.svg'
import GreenSmileDark from './assets/GreenSmileDark.svg'
import HeartPuzzleDark from './assets/HeartPuzzleDark.svg'
import MeltingSmileDark from './assets/MeltingSmileDark.svg'
import ShootingStarDark from './assets/ShootingStarDark.svg'
import ThumbsDownDark from './assets/ThumbsDownDark.svg'
import ThumbsUpDark from './assets/ThumbsUpDark.svg'
import UpsideDownSmileDark from './assets/UpsideDownSmileDark.svg'
import BabyPhoneDark from './assets/BabyPhoneDark.svg'
import BabyPhoneLight from './assets/BabyPhoneLight.svg'
import DirectionPostDark from './assets/DirectionPostDark.svg'
import DirectionPostLight from './assets/DirectionPostLight.svg'
import BabyBoxDark from './assets/BabyBoxDark.svg'
import BabyBoxLight from './assets/BabyBoxLight.svg'
import AquariusLight from './assets/zodiacs/AquariusLight.svg'
import AquariusDark from './assets/zodiacs/AquariusDark.svg'
import AriesLight from './assets/zodiacs/AriesLight.svg'
import AriesDark from './assets/zodiacs/AriesDark.svg'
import CancerLight from './assets/zodiacs/CancerLight.svg'
import CancerDark from './assets/zodiacs/CancerDark.svg'
import CapricornLight from './assets/zodiacs/CapricornLight.svg'
import CapricornDark from './assets/zodiacs/CapricornDark.svg'
import GeminiLight from './assets/zodiacs/GeminiLight.svg'
import GeminiDark from './assets/zodiacs/GeminiDark.svg'
import LeoLight from './assets/zodiacs/LeoLight.svg'
import LeoDark from './assets/zodiacs/LeoDark.svg'
import LibraLight from './assets/zodiacs/LibraLight.svg'
import LibraDark from './assets/zodiacs/LibraDark.svg'
import PiscesLight from './assets/zodiacs/PiscesLight.svg'
import PiscesDark from './assets/zodiacs/PiscesDark.svg'
import SagittariusLight from './assets/zodiacs/SagittariusLight.svg'
import SagittariusDark from './assets/zodiacs/SagittariusDark.svg'
import ScorpioLight from './assets/zodiacs/ScorpioLight.svg'
import ScorpioDark from './assets/zodiacs/ScorpioDark.svg'
import TaurusLight from './assets/zodiacs/TaurusLight.svg'
import TaurusDark from './assets/zodiacs/TaurusDark.svg'
import VirgoLight from './assets/zodiacs/VirgoLight.svg'
import VirgoDark from './assets/zodiacs/VirgoDark.svg'
import RocketShipLight from './assets/RocketShipLight.svg'
import RocketShipDark from './assets/RocketShipDark.svg'
import MagnifyingGlassLight from './assets/MagnifyingGlassLight.svg'
import MagnifyingGlassDark from './assets/MagnifyingGlassDark.svg'

const Illustration: React.FC<IllustrationProps> = ({
  variant = 'light',
  name,
}) => {
  const lightModeComponentMapping: Record<IllustrationName, ReactNode> = {
    Avocado: <AvocadoLight />,
    BabyBalloons: <BabyBalloonsLight />,
    BirthdayCupcake: <BirthdayCupcakeLight />,
    BunInTheOven: <BunInTheOvenLight />,
    Crayons: <CrayonsLight />,
    FlowerSmile: <FlowerSmileLight />,
    FlowerSunglasses: <FlowerSunglassesLight />,
    GameController: <GameControllerLight />,
    GreenSmile: <GreenSmileLight />,
    GrinningSun: <GrinningSunLight />,
    HeartPuzzle: <HeartPuzzleLight />,
    MeltingSmile: <MeltingSmileLight />,
    ShootingStar: <ShootingStarLight />,
    ThumbsDown: <ThumbsDownLight />,
    ThumbsUp: <ThumbsUpLight />,
    UpsideDownSmile: <UpsideDownSmileLight />,
    BabyPhone: <BabyPhoneLight />,
    DirectionPost: <DirectionPostLight />,
    BabyBox: <BabyBoxLight />,
    Aquarius: <AquariusLight />,
    Aries: <AriesLight />,
    Cancer: <CancerLight />,
    Capricorn: <CapricornLight />,
    Gemini: <GeminiLight />,
    Leo: <LeoLight />,
    Libra: <LibraLight />,
    Pisces: <PiscesLight />,
    Sagittarius: <SagittariusLight />,
    Scorpio: <ScorpioLight />,
    Taurus: <TaurusLight />,
    Virgo: <VirgoLight />,
    RocketShip: <RocketShipLight />,
    MagnifyingGlass: <MagnifyingGlassLight />,
  }

  const darkModeComponentMapping: Record<IllustrationName, ReactNode> = {
    Avocado: <AvocadoDark />,
    BabyBalloons: <BabyBalloonsDark />,
    BirthdayCupcake: <BirthdayCupcakeDark />,
    BunInTheOven: <BunInTheOvenDark />,
    Crayons: <CrayonsDark />,
    FlowerSmile: <FlowerSmileDark />,
    FlowerSunglasses: <FlowerSunglassesDark />,
    GameController: <GameControllerDark />,
    GreenSmile: <GreenSmileDark />,
    GrinningSun: <GrinningSunDark />,
    HeartPuzzle: <HeartPuzzleDark />,
    MeltingSmile: <MeltingSmileDark />,
    ShootingStar: <ShootingStarDark />,
    ThumbsDown: <ThumbsDownDark />,
    ThumbsUp: <ThumbsUpDark />,
    UpsideDownSmile: <UpsideDownSmileDark />,
    BabyPhone: <BabyPhoneDark />,
    DirectionPost: <DirectionPostDark />,
    BabyBox: <BabyBoxDark />,
    Aquarius: <AquariusDark />,
    Aries: <AriesDark />,
    Cancer: <CancerDark />,
    Capricorn: <CapricornDark />,
    Gemini: <GeminiDark />,
    Leo: <LeoDark />,
    Libra: <LibraDark />,
    Pisces: <PiscesDark />,
    Sagittarius: <SagittariusDark />,
    Scorpio: <ScorpioDark />,
    Taurus: <TaurusDark />,
    Virgo: <VirgoDark />,
    RocketShip: <RocketShipDark />,
    MagnifyingGlass: <MagnifyingGlassDark />,
  }

  return (
    <div className={styles.Illustration} data-testid="Illustration">
      {variant === 'light'
        ? lightModeComponentMapping[name]
        : darkModeComponentMapping[name]}
    </div>
  )
}

export default Illustration
