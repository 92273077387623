import React from 'react'

interface EtsyStoreProps {
  className?: string
  rounded?: boolean
}

const EtsyStore: React.FC<EtsyStoreProps> = ({
  className,
  rounded = true,
}: EtsyStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {rounded ? (
      <rect fill="#F1641E" height="24" rx="12" width="24" />
    ) : (
      <rect fill="#F1641E" height="24" width="24" />
    )}
    <path
      d="M10.2057 11.625V7.125C10.2057 6.975 10.2057 6.9 10.5917 6.9H14.5291C15.2239 6.9 15.6099 7.425 15.8415 8.55L16.0731 9.375H16.768C16.8452 6.975 16.9224 6 16.9224 6C16.9224 6 15.2239 6.15 14.2203 6.15H9.20208L6.57719 6.075V6.75L7.42642 6.9C8.04404 7.05 8.27565 7.125 8.27565 7.725C8.27565 7.725 8.35285 9.225 8.35285 12C8.35285 14.775 8.27565 16.425 8.27565 16.425C8.27565 16.95 8.04404 17.1 7.34922 17.175L6.49998 17.325V18L9.20208 17.925H13.6798C14.6835 17.925 17.0768 18 17.0768 18C17.154 17.4 17.4628 14.7 17.54 14.4H16.9224L16.2275 15.9C15.9187 16.725 14.9923 17.25 14.0659 17.1H11.441C10.5917 17.1 10.2057 16.8 10.2057 16.05V12.3C10.2057 12.3 12.1358 12.3 12.7534 12.375C13.2166 12.375 13.6026 12.75 13.6798 13.2L13.8343 14.025H14.6063L14.5291 11.85L14.6835 9.75H13.9115L13.6798 10.725C13.6798 10.8 13.6798 10.8 13.6026 10.875C13.5254 11.325 13.1394 11.625 12.6762 11.55C11.827 11.625 10.2057 11.625 10.2057 11.625Z"
      fill="white"
    />
  </svg>
)

export default EtsyStore
