import { Cart } from 'src/types/cart'
// @ts-ignore
// eslint-disable-next-line import/no-named-as-default
import RegistryDiscountDisplay from 'cart/components/store-order-summary/components/registry-discount-display'
// eslint-disable-next-line import/no-extraneous-dependencies
import { PromoMessage } from 'baby-design'
// @ts-ignore
import pluralize from 'pluralize'
import css from './FullSummary.styles.scss'
import { DiscountItem } from '../DiscountItem/DiscountItem'

interface FullSummaryProps {
  applyRegistryDiscount?: boolean
  checkoutTotals: Cart['checkoutTotals']
  isEligibleForRegistryDiscount: Cart['isEligibleForRegistryDiscount']
  registryDiscountUsageMessage: Cart['registryDiscountUsageMessage']
  totalQuantity?: number
}

// eslint-disable-next-line complexity
export const FullSummary = ({
  applyRegistryDiscount,
  checkoutTotals,
  isEligibleForRegistryDiscount,
  registryDiscountUsageMessage,
  totalQuantity,
}: FullSummaryProps) => {
  const estimatedShippingValue =
    parseFloat(checkoutTotals.shipping) === 0 ? (
      <strong>FREE</strong>
    ) : (
      `${checkoutTotals.shipping}`
    )

  const hasShopSaleDiscount = parseFloat(checkoutTotals.saleTotalAmountOff) > 0

  const hasDiscounts =
    hasShopSaleDiscount ||
    parseFloat(checkoutTotals.registryDiscount) > 0 ||
    parseFloat(checkoutTotals.userCredit) > 0 ||
    isEligibleForRegistryDiscount

  return (
    <>
      <div className={css.FullSummary__title}>Order summary</div>
      <div className={css.FullSummary__item}>
        <span>
          Subtotal{' '}
          {totalQuantity &&
            `(${totalQuantity} ${pluralize('item', totalQuantity)})`}
        </span>
        <span data-testid="subtotal">
          $
          {hasShopSaleDiscount
            ? checkoutTotals.subtotalBeforeSales
            : checkoutTotals.subtotal}
        </span>
      </div>
      <div className={css.FullSummary__item}>
        <span>Estimated tax</span>
        <span>${checkoutTotals.tax}</span>
      </div>
      <div className={css.FullSummary__item}>
        <span>Estimated shipping*</span>
        <span>{estimatedShippingValue}</span>
      </div>
      {hasDiscounts && (
        <>
          <div>
            {parseFloat(checkoutTotals.userCredit) > 0 && (
              <DiscountItem
                amount={checkoutTotals.userCredit}
                label="Babylist Shop credit"
              />
            )}
            {parseFloat(checkoutTotals.saleTotalAmountOff) > 0 && (
              <DiscountItem
                amount={checkoutTotals.saleTotalAmountOff}
                label="Babylist Shop sale discount"
              />
            )}
            {isEligibleForRegistryDiscount && (
              <RegistryDiscountDisplay
                amount={parseFloat(checkoutTotals.registryDiscount)}
                className="man pan pvs"
                isDiscountActive={applyRegistryDiscount}
                registryDiscountUsageMessage={registryDiscountUsageMessage}
              />
            )}
            <PromoMessage
              className="pvs text-italic"
              text="Apply promo codes in checkout"
              variant="secondary"
            />
          </div>
        </>
      )}
      <div
        className={`${css.FullSummary__item} ${css['FullSummary__item--total']}`}
      >
        <span>Estimated total</span>
        <span>${checkoutTotals.total}</span>
      </div>
    </>
  )
}
