import React, { cloneElement } from 'react'
import classNames from 'classnames'
import TitleAndCopy from '../TitleAndCopy/TitleAndCopy'
import styles from './CopyAndIcon.styles.scss'
import { CopyAndIconProps } from './CopyAndIcon.types'

const CopyAndIcon: React.FC<CopyAndIconProps> = ({
  copy,
  icon,
  size,
  title,
}) => {
  if (!copy && !title) return null

  const iconClasses = classNames(
    icon.props.className,
    styles.icon,
    styles[`icon__${size}`]
  )
  const clonedIcon = cloneElement(icon, {
    className: iconClasses,
    dangerouslyOverrideClassName: iconClasses,
  })

  return (
    <div className={styles.CopyAndIcon} data-testid="CopyAndIcon">
      {clonedIcon}
      <TitleAndCopy copy={copy} size={size} title={title} />
    </div>
  )
}

export default CopyAndIcon
