import AcquisitionViewSiteNavSection from './AcquisitionViewSiteNavSection'
import HomePageSiteNavSection from './HomePageSiteNavSection'
import MinimalSiteNavSection from './MinimalSiteNavSection'
import LoggedInSiteNavSection from './LoggedInSiteNavSection'

interface SiteNavSectionProps {
  activeNavItem?: 'Home' | 'Registry' | 'Store' | 'Guides'
  className?: string
  handleToggleNav: () => void
  showMinimalView: boolean
  showAcquisitionView: boolean
  isLoggedIn: boolean
  trackClick: (eventCta: string) => void
  productCategories?: any[]
  guideTopics?: any[]
  glowupEnabled: boolean
}

const SiteNavSection = ({
  activeNavItem,
  className,
  handleToggleNav,
  isLoggedIn,
  showAcquisitionView,
  showMinimalView,
  trackClick,
  productCategories,
  guideTopics,
  glowupEnabled,
}: SiteNavSectionProps) => {
  if (showAcquisitionView) {
    return <AcquisitionViewSiteNavSection className={className} />
  }

  if (showMinimalView) {
    return (
      <MinimalSiteNavSection
        className={className}
        glowupEnabled={glowupEnabled}
        handleToggleNav={handleToggleNav}
        trackClick={trackClick}
      />
    )
  }

  if (isLoggedIn) {
    return (
      <LoggedInSiteNavSection
        activeNavItem={activeNavItem}
        className={className}
        handleToggleNav={handleToggleNav}
        trackClick={trackClick}
      />
    )
  }

  return (
    <HomePageSiteNavSection
      activeNavItem={activeNavItem}
      className={className}
      handleToggleNav={handleToggleNav}
      trackClick={trackClick}
      productCategories={productCategories}
      guideTopics={guideTopics}
    />
  )
}

export default SiteNavSection
