const AvocadoDark = () => (
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.9774 19.8277C20.9774 18.3036 19.5528 15.4991 18.0264 15.4991C16.5001 15.4991 15.412 18.3036 15.412 19.8277C15.412 21.3517 16.6566 22.6086 18.183 22.6086C19.7094 22.6086 20.9774 21.3674 20.9774 19.8277ZM24.6094 19.6705C24.6094 23.7006 21.2983 26.945 17.2358 26.8429H17.0167C13.3455 26.6386 10.3711 23.6142 10.2302 19.9298C10.1676 18.39 10.6137 16.921 11.4121 15.7191C12.1323 14.6193 12.555 13.3388 12.5002 12.0111V11.6341C12.5002 8.31105 14.6293 5.6008 17.2593 5.6008C19.8894 5.6008 22.0185 8.3032 22.0185 11.6341V11.7519C21.995 13.0717 22.4412 14.36 23.2239 15.4363C24.1084 16.6382 24.6094 18.0837 24.6094 19.6627V19.6705Z"
      fill="url(#paint0_linear_253_20563)"
    />
    <path
      d="M20.9774 19.8277C20.9774 21.3517 19.7328 22.6086 18.183 22.6086C16.6331 22.6086 15.412 21.3674 15.412 19.8277C15.412 18.2879 16.4766 15.4991 18.0264 15.4991C19.5763 15.4991 20.9774 18.3036 20.9774 19.8277Z"
      fill="url(#paint1_linear_253_20563)"
    />
    <path
      d="M17.2586 26.8429V26.9214C11.5993 27.542 7.13762 24.274 7.39593 19.6705C7.59945 16.2061 9.92423 14.4386 10.1043 12.4825C10.2686 10.5421 10.026 8.75882 12.2334 6.73203C14.4094 4.72094 17.196 5.56151 17.2821 5.58508C14.6521 5.58508 12.523 8.28747 12.523 11.6183V11.9954C12.5856 13.3152 12.1629 14.6035 11.4349 15.7033C10.6522 16.9053 10.2138 18.3508 10.253 19.9141C10.3704 23.5827 13.3684 26.6229 17.0395 26.8272C17.1021 26.8507 17.1804 26.8507 17.2586 26.8507V26.8429Z"
      fill="url(#paint2_linear_253_20563)"
    />
    <path
      d="M23.5441 15.0278C22.7926 14.0144 22.4013 12.8438 22.4169 11.6419V11.5241C22.4169 8.06752 20.1469 5.24729 17.3368 5.20015C16.9533 5.09017 14.1745 4.40672 11.9593 6.4178C9.96327 8.23249 9.86151 9.89792 9.76758 11.5162C9.75192 11.7833 9.73627 12.0504 9.71278 12.3254C9.65016 13.0402 9.21965 13.7787 8.71869 14.6271C8.01421 15.8212 7.12969 17.3217 7.01228 19.3877C6.89486 21.3281 7.62283 23.1585 9.05527 24.5333C10.7225 26.128 13.1569 27 15.8731 27C16.3349 27 16.8124 26.9764 17.2899 26.9214C17.3603 26.9214 17.4151 26.8822 17.4699 26.8507C19.4737 26.8429 21.3524 26.073 22.777 24.6747C24.2094 23.2685 25 21.3988 25 19.4113C25 17.8166 24.499 16.3004 23.5441 15.0278ZM9.59537 23.9677C8.31165 22.7422 7.68545 21.171 7.78721 19.4427C7.89679 17.5652 8.6952 16.214 9.39186 15.0199C9.93978 14.0851 10.4094 13.2838 10.4877 12.3882C10.5112 12.1054 10.5269 11.8304 10.5425 11.5555C10.6364 9.96862 10.7147 8.59386 12.4759 6.99913C13.2821 6.26854 14.1901 5.96217 14.9964 5.85218C13.29 6.88129 12.1158 9.03378 12.1158 11.5241C12.1158 11.6498 12.1158 11.7755 12.1158 11.909C12.1706 13.056 11.8184 14.2343 11.0983 15.3184C10.2294 16.6146 9.79889 18.1229 9.85368 19.6784C9.94761 22.4593 11.6462 24.8789 14.0571 26.0652C12.2881 25.7745 10.7304 25.0596 9.58754 23.9677H9.59537ZM22.229 24.1091C20.9062 25.4131 19.1372 26.0966 17.2507 26.0573C17.1803 26.0573 17.1098 26.0573 17.0472 26.0573C13.5718 25.8688 10.7617 23.0564 10.6443 19.647C10.5895 18.2565 10.973 16.9132 11.7558 15.7505C12.5777 14.525 12.9769 13.1816 12.9064 11.8697C12.9064 11.7519 12.9064 11.6341 12.9064 11.5162C12.9064 8.46031 14.8633 5.97002 17.2742 5.97002C19.6851 5.97002 21.642 8.46031 21.642 11.5162V11.6262C21.6185 13.001 22.0647 14.3364 22.9179 15.4913C23.7711 16.6303 24.2172 17.9894 24.2172 19.4034C24.2172 21.1789 23.5128 22.8443 22.229 24.1012V24.1091Z"
      fill="#121112"
    />
    <path
      d="M18.1904 22.695C16.4449 22.695 15.0281 21.2967 15.0281 19.5763C15.0281 17.8558 16.1631 14.9256 18.0182 14.9256C19.8733 14.9256 21.3606 18.0208 21.3606 19.5763C21.3606 21.2967 19.936 22.695 18.1904 22.695ZM18.026 15.7112C16.8519 15.7112 15.8187 18.1151 15.8187 19.5763C15.8187 20.8646 16.8832 21.9094 18.1982 21.9094C19.5133 21.9094 20.5856 20.8646 20.5856 19.5763C20.5856 18.2251 19.2706 15.7112 18.026 15.7112Z"
      fill="#121112"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_253_20563"
        x1="15.9987"
        x2="15.9987"
        y1="26.9976"
        y2="5.00813"
      >
        <stop offset="0.13" stopColor="#56BB6C" />
        <stop offset="0.69" stopColor="#E1EB3B" />
        <stop offset="1" stopColor="#FFD43E" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_253_20563"
        x1="15.9873"
        x2="15.9873"
        y1="27.0243"
        y2="3.07956"
      >
        <stop offset="0.24" stopColor="#FFD43E" />
        <stop offset="1" stopColor="#FF6D4E" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint2_linear_253_20563"
        x1="16.0026"
        x2="16.0026"
        y1="27.003"
        y2="5.00563"
      >
        <stop stopColor="#A0DCFF" />
        <stop offset="0.74" stopColor="#56BB6C" />
      </linearGradient>
    </defs>
  </svg>
)

export default AvocadoDark
