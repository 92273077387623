import React from 'react'

interface WayfairStoreProps {
  className?: string
  rounded?: boolean
}

const WayfairStore: React.FC<WayfairStoreProps> = ({
  className,
  rounded = true,
}: WayfairStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {rounded ? (
      <rect fill="#7B189F" height="24" rx="12" width="24" />
    ) : (
      <rect fill="#7B189F" height="24" width="24" />
    )}
    <path
      d="M11.5791 17.5738L6.43965 12.4292L4.66734 14.2033C4.4903 14.3805 4.46803 14.5139 4.53447 14.7353L5.37626 18.0175C5.46498 18.3723 5.64201 18.5495 5.99648 18.6383L9.27534 19.4809C9.49693 19.5474 9.6298 19.5251 9.80684 19.3479L11.5791 17.5738ZM12.4209 17.5738L14.1933 19.3479C14.3703 19.5251 14.5035 19.5474 14.7248 19.4809L18.0036 18.6383C18.3581 18.5495 18.5351 18.3723 18.6238 18.0175L19.4656 14.7353C19.5321 14.5135 19.5098 14.3805 19.3327 14.2033L17.5604 12.4292L12.4209 17.5738ZM12.4209 6.44154L17.5604 11.5862L19.3327 9.8121C19.5098 9.63489 19.5321 9.50149 19.4656 9.28007L18.6238 5.99793C18.5351 5.64311 18.3581 5.46589 18.0036 5.37709L14.7248 4.53446C14.5032 4.46795 14.3703 4.49025 14.1933 4.66746L12.4209 6.44154ZM11.5791 6.44154L9.80684 4.66746C9.6298 4.49025 9.49654 4.46795 9.27534 4.53446L5.99648 5.37709C5.64201 5.46589 5.46498 5.64311 5.37626 5.99793L4.53447 9.28007C4.46803 9.50188 4.4903 9.63489 4.66734 9.8121L6.43965 11.5862L11.5791 6.44154Z"
      fill="white"
    />
  </svg>
)

export default WayfairStore
