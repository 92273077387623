import { track, useTracking } from 'lib/analytics'
import { useState } from 'react'
import { setGiftGiverCookie, useGiftGiverCookie } from 'lib/cookies'
import { GiftGiverInfoFormState } from 'registry/components/CrowdfundReserveFormFlow/components/GiftGiverInfoForm/GiftGiverInfoForm.types'
import { RegItem } from 'src/types/regItem'
import { Reservation } from 'registry/components/CrowdfundReserveFormFlow/CrowdfundReserveFormFlow.types'
import { NEW_RESERVATION_ID } from '../../cart/constants'

interface CrowdfundReserveHookProps {
  inProgressReservation?: Reservation
  initialStep: number
  regItem: RegItem
  onCreateReservation: (reservation: any) => Promise<any>
  onUpdateReservation: (reservation: any) => Promise<any>
  onRemoveReservation: (reservation: any) => Promise<any>
}

const useCrowdfundReservation = ({
  inProgressReservation,
  initialStep,
  regItem,
  onCreateReservation,
  onUpdateReservation,
  onRemoveReservation,
}: CrowdfundReserveHookProps) => {
  const tracker = useTracking()
  const [activeStep, setActiveStep] = useState(initialStep)
  const [paymentOption, setPaymentOption] = useState<string>()
  const [isSaving, setIsSaving] = useState(false)
  const [currentReservation, setCurrentReservation] = useState<
    Reservation | undefined
  >(inProgressReservation)

  const { name, email } = useGiftGiverCookie()

  const saveReservation = ({
    name: ggName,
    email: ggEmail,
    giftAmount,
    paymentMethod = null,
    subscribeToMarketingEmail,
    isActive = true,
  }: GiftGiverInfoFormState) => {
    setGiftGiverCookie({
      name,
      email,
      subscribeToMarketingEmail,
    })

    const data = {
      id: currentReservation?.id || NEW_RESERVATION_ID,
      regItemId: regItem.id,
      regItem,
      registryId: regItem.registryId,
      name: ggName || currentReservation?.name,
      email: ggEmail || currentReservation?.email,
      giftAmount: giftAmount || currentReservation?.giftAmount,
      quantity: 1,
      isActive,
      isPurchased: true,
      subscribeToMarketingEmail:
        subscribeToMarketingEmail ||
        currentReservation?.subscribeToMarketingEmail,
    } as Record<string, any>

    if (paymentMethod) {
      data.cashFundContributionAttributes = { paymentMethod }
    }

    if (data.id !== NEW_RESERVATION_ID) {
      return updateReservation(data)
    } else {
      return createReservation(data)
    }
  }

  const updateReservation = (data: any) => {
    setIsSaving(true)
    const newReservation = { ...(currentReservation as any), ...data }
    return onUpdateReservation(newReservation)
      .then(() => {
        setCurrentReservation(newReservation)
      })
      .finally(() => setIsSaving(false))
  }

  const createReservation = (data: any) => {
    setIsSaving(true)
    return onCreateReservation(data)
      .then((res) => {
        setCurrentReservation(res)
        tracker.trackEvent({
          event: track.emailCaptured,
          eventLocation: track.EventLocation.GIFT_GIVER_MINUS_EMAIL_COLLECTION,
          eventUrl: window.location.href,
          email: res.email,
          userId: res.userId,
        })
      })
      .finally(() => setIsSaving(false))
  }

  const cancelReservation = () => onRemoveReservation(currentReservation)

  return {
    activeStep,
    currentReservation,
    isSaving,
    paymentOption,
    cancelReservation,
    saveReservation,
    setActiveStep,
    setCurrentReservation,
    setPaymentOption,
    updateReservation,
  }
}

export default useCrowdfundReservation
