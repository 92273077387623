import { RegItem } from 'src/types/regItem'
import RegItemAddedToast from 'shared/RegItemAddedToast/RegItemAddedToast'
import { trackToastClicked } from 'src/lib/tracking/shared/functions/toastClicked'
import { track } from 'lib/analytics'
import { trackRegistryItemDetailsViewed } from 'src/lib/tracking/registry/functions/registryItemDetailsViewed'
import { showToast } from 'shared/toast/showToast'
import { EventLocationType } from 'src/lib/tracking/shared/types'
import { CurrentUser } from '../../global'

interface ShowRegItemAddedToastProps {
  currentUser: CurrentUser | undefined
  eventLocation: EventLocationType
  regItem: RegItem
  tracker: any
}

export const showRegItemAddedToast = ({
  currentUser,
  eventLocation,
  regItem,
  tracker,
}: ShowRegItemAddedToastProps) =>
  showToast({
    render: (isOpen, handleDismiss) => (
      <RegItemAddedToast
        isOpen={isOpen}
        regItem={regItem}
        onDismiss={handleDismiss}
        onEdit={() => {
          trackToastClicked({
            currentUser,
            eventCta: 'Edit',
            eventLocation,
            eventType: track.EventType.REG_ADD_CONFIRMATION_TOAST,
            regItemIds: [regItem.id],
            tracker,
          })

          trackRegistryItemDetailsViewed({
            currentUser,
            eventLocation,
            eventType: track.EventType.REG_ADD_CONFIRMATION_TOAST,
            regItem,
            tracker,
          })
        }}
      />
    ),
    timeout: 5000,
  })
