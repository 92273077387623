const RocketShipDark = () => (
  <svg
    fill="none"
    height="64"
    viewBox="0 0 65 64"
    width="65"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4622_25896)">
      <path
        d="M52.392 32.2734C52.392 32.2734 57.3642 40.9398 50.755 54.0619C50.755 54.0619 50.5099 46.0083 38.8672 38.6375L52.392 32.2734Z"
        fill="url(#paint0_linear_4622_25896)"
      />
      <path
        d="M50.7549 54.937C51.0787 54.937 51.3851 54.7531 51.5339 54.4555C58.2832 41.0708 53.3635 32.2118 53.1534 31.8354C52.9258 31.4415 52.4356 31.2839 52.0242 31.4765L38.4994 37.8406C38.2105 37.9807 38.0179 38.2608 38.0004 38.5759C37.9829 38.8911 38.1317 39.1975 38.4031 39.3725C49.5205 46.4107 49.8795 54.0091 49.8795 54.0878C49.8882 54.4905 50.1771 54.8319 50.5623 54.9195C50.6235 54.937 50.6936 54.937 50.7549 54.937ZM51.9716 33.4374C52.847 35.5558 54.8604 42.1388 51.0612 51.2954C50.0983 48.354 47.5334 43.4781 40.6528 38.7598L51.9716 33.4374Z"
        fill="#121112"
      />
      <path
        d="M33.0025 16.4379C33.0025 16.4379 23.5133 13.2952 11.9844 22.3905C11.9844 22.3905 19.9242 21.0249 29.4747 30.9606L33.0025 16.4379Z"
        fill="url(#paint1_linear_4622_25896)"
      />
      <path
        d="M29.474 31.8362C29.5528 31.8362 29.6229 31.8274 29.7016 31.8099C30.008 31.7224 30.2444 31.4772 30.3232 31.1709L33.851 16.6481C33.956 16.2017 33.7109 15.7552 33.2732 15.6064C32.8706 15.4664 23.2062 12.42 11.4323 21.7079C11.1171 21.953 11.0121 22.3819 11.1784 22.7496C11.3447 23.1173 11.7299 23.3274 12.1238 23.2573C12.1939 23.2486 19.766 22.1368 28.835 31.5648C29.0013 31.7399 29.2289 31.8362 29.4653 31.8362H29.474ZM31.9426 17.0858L28.9838 29.2362C22.9786 23.4412 17.6913 21.8917 14.6187 21.5416C22.8298 15.9916 29.6841 16.6481 31.9339 17.0858H31.9426Z"
        fill="#121112"
      />
      <path
        d="M36.1098 41.6051C36.1098 41.6051 37.633 46.7174 32.8884 49.5362C29.5006 51.5496 27.7498 56.2767 28.8703 57.9399C28.8703 57.9399 24.896 53.3441 27.6798 47.7679C30.5861 41.9378 23.5304 38.4625 21.5433 47.3739C19.7838 55.2437 6.92432 56.2942 6.92432 56.2942C6.92432 56.2942 15.3106 52.2061 15.9408 45.2555C16.8249 35.3373 33.1247 28.0979 36.1185 41.6051H36.1098Z"
        fill="url(#paint2_linear_4622_25896)"
      />
      <path
        d="M28.8787 58.8158C29.0626 58.8158 29.2552 58.7545 29.4127 58.632C29.7804 58.3519 29.8679 57.8266 29.6053 57.4502C29.4302 57.1876 29.3164 56.5661 29.5353 55.5944C29.9292 53.8436 31.2511 51.5238 33.3432 50.2808C37.5364 47.7859 37.5539 43.3827 36.9674 41.378C35.7593 35.9594 32.3278 33.0356 27.5306 33.3332C22.1032 33.6834 15.6516 38.6293 15.0651 45.1772C14.4961 51.5588 6.61759 55.4631 6.53881 55.4981C6.16239 55.6819 5.96981 56.1109 6.07485 56.5135C6.18865 56.9162 6.57382 57.1876 6.99401 57.1526C7.5455 57.1088 20.5188 55.9533 22.4009 47.5495C23.2763 43.6365 24.957 42.9625 25.456 42.8487C26.0425 42.7174 26.6115 42.9275 27.0054 43.4264C27.5569 44.1268 27.8195 45.5361 26.9091 47.3657C23.9065 53.3884 28.0471 58.2906 28.2222 58.5007C28.3973 58.702 28.6424 58.8071 28.8875 58.8071L28.8787 58.8158ZM25.8061 41.0716C25.561 41.0716 25.3159 41.0979 25.062 41.1592C23.0311 41.6231 21.4379 43.8116 20.6851 47.1819C19.7047 51.5676 14.7587 53.6685 11.0558 54.6402C13.5507 52.6531 16.4307 49.4929 16.7984 45.3348C17.2886 39.8373 22.9261 35.3904 27.6269 35.084C31.5312 34.8389 34.2361 37.2112 35.2516 41.7895C35.2516 41.807 35.2603 41.8245 35.2691 41.8507C35.3216 42.0346 36.5209 46.359 32.4416 48.7838C30.1743 50.1319 28.6424 52.5218 28.0034 54.5264C27.4519 52.8719 27.233 50.6222 28.4586 48.1536C29.544 45.9738 29.509 43.8116 28.3623 42.3497C27.7057 41.5181 26.7866 41.0629 25.7974 41.0629L25.8061 41.0716Z"
        fill="#121112"
      />
      <path
        d="M22.9175 33.6832C28.6425 36.4757 33.6322 40.5551 37.519 45.6061L39.5937 39.5309L28.4587 30.4355L22.9175 33.6832Z"
        fill="url(#paint3_linear_4622_25896)"
      />
      <path
        d="M37.5099 46.481C37.5536 46.481 37.6062 46.481 37.6499 46.4723C37.9651 46.4198 38.2365 46.2009 38.3327 45.8945L40.4074 39.8193C40.5212 39.4692 40.4074 39.0927 40.1361 38.8564L29.0011 29.7611C28.721 29.5335 28.3183 29.4985 28.0031 29.6823L22.4619 32.93C22.1818 33.0963 22.0155 33.3939 22.033 33.7178C22.0417 34.0417 22.2343 34.3306 22.5232 34.4707C28.0907 37.1931 33.0366 41.2287 36.8096 46.1396C36.9759 46.3585 37.2385 46.481 37.5011 46.481H37.5099ZM38.5603 39.8193L37.1947 43.8111C33.7457 39.6617 29.4826 36.1864 24.7292 33.6303L28.3708 31.4944L38.5603 39.8193Z"
        fill="#121112"
      />
      <path
        clipRule="evenodd"
        d="M27.0845 31.144C27.601 25.9704 29.6056 20.8844 33.1422 16.5512C39.2086 9.12792 48.339 5.69639 57.2154 6.58054C59.8503 15.0981 58.3097 24.7361 52.2432 32.1594C48.7154 36.4839 44.1283 39.4514 39.1649 41.0009C37.8693 38.7599 36.1535 36.7115 34.0263 34.9782C31.8991 33.2449 29.5443 31.9669 27.0845 31.144ZM39.585 22.644C39.585 25.1338 41.6035 27.1523 44.0933 27.1523C46.5831 27.1523 48.6016 25.1338 48.6016 22.644C48.6016 20.1542 46.5831 18.1357 44.0933 18.1357C41.6035 18.1357 39.585 20.1542 39.585 22.644Z"
        fill="url(#paint4_linear_4622_25896)"
        fillRule="evenodd"
      />
      <path
        d="M39.1648 41.8766C39.2523 41.8766 39.3398 41.8678 39.4274 41.8415C44.7322 40.1871 49.4068 37.0269 52.9259 32.7112C58.9661 25.3229 60.8744 15.4573 58.0469 6.3182C57.9419 5.98555 57.6443 5.74044 57.2941 5.70543C47.7874 4.76001 38.5082 8.61172 32.468 16C28.9314 20.3332 26.7692 25.5418 26.209 31.0567C26.1652 31.4594 26.4191 31.8446 26.8043 31.9759C29.2203 32.7813 31.4613 34.0156 33.466 35.6613C35.4619 37.2895 37.1251 39.2329 38.4032 41.4476C38.5607 41.719 38.8584 41.8853 39.156 41.8853L39.1648 41.8766ZM56.5413 7.39493C58.9486 15.8162 57.1103 24.824 51.569 31.5995C48.4089 35.4687 44.2683 38.3487 39.5587 39.9507C38.2193 37.806 36.5473 35.9152 34.5777 34.3044C32.5906 32.685 30.3934 31.4244 28.0298 30.549C28.6513 25.6293 30.6472 20.9897 33.8249 17.103C39.3661 10.3187 47.8311 6.71213 56.5413 7.39493Z"
        fill="#121112"
      />
      <path
        d="M58.3099 17.5667C58.6863 13.8638 58.3099 10.1259 57.2156 6.58929C53.5215 6.21287 49.7748 6.59804 46.2207 7.70979C47.525 9.95079 49.2408 11.9992 51.3593 13.7325C53.4777 15.4657 55.8325 16.7438 58.3011 17.5667H58.3099Z"
        fill="url(#paint5_linear_4622_25896)"
      />
      <path
        d="M58.3093 18.4424C58.4756 18.4424 58.6419 18.3987 58.782 18.3024C59.0008 18.1623 59.1496 17.9172 59.1759 17.6546C59.5786 13.8204 59.1934 10.0124 58.0554 6.32705C57.9504 5.9944 57.6527 5.74929 57.3113 5.71427C53.5296 5.3291 49.6166 5.73178 45.9663 6.86979C45.7124 6.94857 45.5111 7.14116 45.4148 7.38627C45.3185 7.63138 45.3447 7.9115 45.476 8.14786C46.8679 10.5377 48.6625 12.6474 50.8247 14.4069C52.9956 16.1752 55.4205 17.5233 58.0467 18.3899C58.1342 18.4249 58.2305 18.4337 58.3268 18.4337L58.3093 18.4424ZM56.5497 7.40378C57.3901 10.3276 57.714 13.3302 57.5302 16.3503C55.5168 15.5537 53.6347 14.4507 51.9189 13.05C50.2207 11.6582 48.7588 10.0475 47.5682 8.22664C50.4833 7.44755 53.5559 7.16742 56.541 7.40378H56.5497Z"
        fill="#121112"
      />
      <path
        clipRule="evenodd"
        d="M36.1099 22.6447C36.1099 27.0539 39.6843 30.6283 44.0935 30.6283C48.5027 30.6283 52.077 27.0539 52.077 22.6447C52.077 18.2355 48.5027 14.6611 44.0935 14.6611C39.6843 14.6611 36.1099 18.2355 36.1099 22.6447ZM39.585 22.644C39.585 25.1338 41.6035 27.1523 44.0933 27.1523C46.5831 27.1523 48.6016 25.1338 48.6016 22.644C48.6016 20.1542 46.5831 18.1357 44.0933 18.1357C41.6035 18.1357 39.585 20.1542 39.585 22.644Z"
        fill="url(#paint6_linear_4622_25896)"
        fillRule="evenodd"
      />
      <path
        d="M44.0845 31.4945C46.6582 31.4945 49.2056 30.3828 50.9476 28.2468C52.4445 26.4172 53.1361 24.1062 52.8997 21.7514C52.6633 19.3966 51.5253 17.2782 49.687 15.7812C45.9053 12.6911 40.3116 13.2601 37.2302 17.0418C34.1488 20.8235 34.7091 26.4172 38.4908 29.4986C40.1365 30.838 42.1149 31.4945 44.0845 31.4945ZM44.102 15.5449C45.6865 15.5449 47.2709 16.0701 48.5928 17.1469C50.0634 18.3461 50.9738 20.0444 51.1664 21.9352C51.359 23.8261 50.7988 25.6732 49.5995 27.1438C47.1221 30.1814 42.6401 30.6279 39.6025 28.1505C36.5649 25.6732 36.1185 21.1912 38.5958 18.1536C39.9964 16.4378 42.0449 15.5449 44.102 15.5449Z"
        fill="#121112"
      />
      <path
        d="M44.0844 28.0281C44.2683 28.0281 44.4521 28.0281 44.6359 28.0018C46.0628 27.853 47.3584 27.1615 48.2688 26.0497C49.1792 24.938 49.5994 23.5373 49.4593 22.1017C49.3193 20.6661 48.619 19.3792 47.5072 18.4688C45.2049 16.5955 41.8084 16.9369 39.9351 19.2304C38.0618 21.5327 38.4032 24.9292 40.6967 26.8026C41.6684 27.5992 42.8589 28.0193 44.0932 28.0193L44.0844 28.0281ZM44.102 19.0116C44.9073 19.0116 45.7214 19.283 46.3955 19.8257C47.1483 20.4385 47.6123 21.3139 47.7086 22.2768C47.8049 23.2397 47.5247 24.1851 46.9032 24.938C46.2904 25.6908 45.4238 26.1548 44.4521 26.2511C43.4892 26.3561 42.5438 26.0672 41.7909 25.4457C41.0381 24.8329 40.5741 23.9663 40.4778 22.9946C40.3815 22.0317 40.6617 21.0863 41.2744 20.3334C41.9923 19.458 43.034 19.0028 44.0932 19.0028L44.102 19.0116Z"
        fill="#121112"
      />
    </g>
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_4622_25896"
        x1="46.5268"
        x2="46.5268"
        y1="54.0618"
        y2="32.2731"
      >
        <stop offset="0.13" stopColor="#56BB6C" />
        <stop offset="0.69417" stopColor="#E1EB3B" />
        <stop offset="1" stopColor="#FFD43E" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_4622_25896"
        x1="22.4935"
        x2="22.4935"
        y1="30.9605"
        y2="15.9128"
      >
        <stop offset="0.13" stopColor="#56BB6C" />
        <stop offset="0.69417" stopColor="#E1EB3B" />
        <stop offset="1" stopColor="#FFD43E" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint2_linear_4622_25896"
        x1="21.6334"
        x2="33.6194"
        y1="57.9398"
        y2="36.642"
      >
        <stop offset="0.401477" stopColor="#FFD43E" />
        <stop offset="1" stopColor="#FF6D4E" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint3_linear_4622_25896"
        x1="31.2556"
        x2="31.2556"
        y1="45.6062"
        y2="30.4355"
      >
        <stop stopColor="#A0DCFF" />
        <stop offset="0.7359" stopColor="#56BB6C" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint4_linear_4622_25896"
        x1="29.8989"
        x2="51.193"
        y1="41.1274"
        y2="6.82794"
      >
        <stop offset="0.218662" stopColor="#FF6D4E" />
        <stop offset="0.876058" stopColor="#FED7FF" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint5_linear_4622_25896"
        x1="52.3362"
        x2="52.3362"
        y1="17.5668"
        y2="6.44922"
      >
        <stop stopColor="#A0DCFF" />
        <stop offset="0.7359" stopColor="#56BB6C" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint6_linear_4622_25896"
        x1="44.0935"
        x2="44.0935"
        y1="30.6284"
        y2="14.6611"
      >
        <stop stopColor="#A0DCFF" />
        <stop offset="0.7359" stopColor="#56BB6C" />
      </linearGradient>
      <clipPath id="clip0_4622_25896">
        <rect
          fill="white"
          height="54"
          transform="translate(5.5 5)"
          width="54"
        />
      </clipPath>
    </defs>
  </svg>
)

export default RocketShipDark
