import React from 'react'

interface RHBabyAndChildStoreProps {
  className?: string
  rounded?: boolean
}

const RHBabyAndChildStore: React.FC<RHBabyAndChildStoreProps> = ({
  className,
  rounded = true,
}: RHBabyAndChildStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3733_39)">
      {rounded ? (
        <rect fill="#FAFAFA" height="24" rx="12" width="24" />
      ) : (
        <rect fill="#FAFAFA" height="24" width="24" />
      )}
      <path
        d="M4.47559 11.9946V6.16788H7.72083C9.83536 6.16788 11.2015 7.16814 11.2015 9.11737C11.1852 11.0829 9.81904 11.9969 7.72083 11.9969H4.47559V11.9946ZM19.5221 6V11.9946H13.0713V6H12.598V17.7117C12.1714 17.6884 11.6258 17.3597 10.635 15.2822L10.318 14.6317C9.63487 13.1977 8.81889 12.4353 7.66954 12.1695H7.72083C10.1851 12.1695 11.6678 11.0993 11.6678 9.11737C11.6678 7.1355 10.1687 6 7.70218 6H4V17.8959H4.47559V12.1788H6.70203C8.23373 12.1788 8.98442 13.0158 9.7188 14.5151L10.0172 15.1493C11.1479 17.4739 12.0432 17.8749 12.598 17.8982C12.6167 17.8982 12.633 17.8982 12.6516 17.8982H13.0736V12.1811H19.5244V17.8982H20V6H19.5244H19.5221Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_3733_39">
        <rect fill="white" height="24" width="24" />
      </clipPath>
    </defs>
  </svg>
)

export default RHBabyAndChildStore
