export const BABYLIST_URL = '//www.babylist.com'

/* Order flow breadcrumbs */
export const CUSTOMER_INFORMATION_STEP = 'dme-customer-information'
export const SEARCH_PHYSICIAN_STEP = 'search-physician'
export const PHYSICIAN_RESULT_STEP = 'results'
export const PATIENT_TO_CHECKOUT_STEP = 'checkout'

/* Other */
export const CUSTOMER_INFORMATION_PATH = `/health/${CUSTOMER_INFORMATION_STEP}`
export const SEARCH_PHYSICIAN_PATH = `${CUSTOMER_INFORMATION_PATH}/${SEARCH_PHYSICIAN_STEP}`
export const PHYSICIAN_RESULT_PATH = `${SEARCH_PHYSICIAN_PATH}/${PHYSICIAN_RESULT_STEP}`
export const PATIENT_TO_CHECKOUT_PATH = `${PHYSICIAN_RESULT_PATH}/${PATIENT_TO_CHECKOUT_STEP}`
export const DME_ZENDESK_URL = '//healthbabylist.zendesk.com/hc/en-us'
export const DME_PRESCRIPTION_INVALID_URL =
  'https://healthbabylist.zendesk.com/hc/en-us/articles/18744742932507-Why-is-my-prescription-invalid'
export const DME_RETURN_POLICY_URL =
  'https://healthbabylist.zendesk.com/hc/en-us/articles/12903014268443'
export const DME_REPLACEMENT_PARTS_TERMS_URL =
  'https://healthbabylist.zendesk.com/hc/en-us/articles/22819765227291-Can-I-receive-replacement-parts'
export const DME_PARTS_AND_BAGS_TERMS_URL =
  'https://healthbabylist.zendesk.com/hc/en-us/articles/22819765227291-Am-I-eligible-for-replacement-parts-and-milk-storage-bags'
export const PRESCRIPTION_GUIDE =
  'https://healthbabylist.zendesk.com/hc/en-us/articles/27615273553819-What-is-considered-a-valid-prescription'
export const GUIDES_PATH = '/hello-baby'
export const HOME_PATH = '/health'
export const PARTS_HOME_PATH = '/health/parts'
export const HEALTH_CONTACT_EMAIL = 'health@babylist.com'
export const HEALTH_RX_EMAIL = 'rx@babylist.com'
export const STORE_PATH = '/health/dme-store'
export const PRIVACY_URL = 'https://www.babylist.com/privacy'
export const TERMS_AND_CONDITIONS_PATH = '/terms'
export const STORE_NAME = 'Babylist Health'
// The storkpump link is currently not working. Keeping it here for now, but may need to remove if we don't use it
export const STORK_PUMP_REFERRAL_URL =
  'https://pcs.adapthealth.com/breast-pump-program-babylist-health'
export const ORDER_PATH = '/health/orders/:id'
export const PUMP_INSURANCE_PICKER_FORM_ACTION = '/health'

// Manually prefix the URLs with '/health'
export const PDP_PATH = '/health/gp/:urlSlug/:id/:productId'
export const SUBSCRIPTIONS_PATH = '/health/subscriptions/:id'
export const EXISTING_CUSTOMER_PATH = `${PARTS_HOME_PATH}/existing-customer`
export const INELIGIBLE_PARTS_PATH = `${PARTS_HOME_PATH}/subscriptions/order-eligibility/:id`

export const PUMP_PARTS_STORE_PAGE_URL =
  'https://www.babylist.com/store/pumping-parts-and-accessories'

export const REPLACEMENT_PARTS_CUSTOMER_INFORMATION_PATH =
  '/health/parts/dme-customer-information'

export const SUPPORT_PHONE_NUMBER = '14104310144'
export const FORMATTED_SUPPORT_PHONE_NUMBER = '(410) 431-0144'
