import React from 'react'

interface MagicBeansStoreProps {
  className?: string
  rounded?: boolean
}

const MagicBeansStore: React.FC<MagicBeansStoreProps> = ({
  className,
  rounded = true,
}: MagicBeansStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {rounded ? (
      <g clipPath="url(#clip0_3135_602)">
        <rect fill="#FAFAFA" height="24" rx="12" width="24" />
        <path
          d="M6.65766 7.76697C6.0073 7.83425 5.24553 8.18195 4.69614 8.73135C4.054 9.37349 3.33206 10.9685 4.04457 12.6128C4.79821 14.352 6.23401 15.5644 8.79845 16.1139C11.3384 16.6582 13.2624 16.477 14.8728 16.1133C16.4544 15.7562 18.927 15.0458 19.9213 12.466C20.4152 11.1845 20.3046 10.1708 19.9057 9.41889C19.5117 8.67621 18.8526 8.21947 18.2969 8.01106C17.7374 7.80124 17.1071 7.77705 16.5357 7.85056C15.9643 7.92408 15.4294 8.09815 15.0533 8.3071C14.9389 8.37066 14.8264 8.43833 14.7114 8.50753C14.1158 8.86584 13.4522 9.2651 12.0996 9.34707C11.2921 9.39601 10.7096 9.27971 10.2377 9.09864C9.83175 8.94291 9.50418 8.73911 9.16665 8.52912C9.10543 8.49103 9.04388 8.45273 8.98147 8.41448C8.16367 7.91325 7.47079 7.68286 6.65766 7.76697Z"
          fill="url(#paint0_linear_3135_602)"
        />
        <path
          d="M6.65766 7.76697C6.0073 7.83425 5.24553 8.18195 4.69614 8.73135C4.054 9.37349 3.33206 10.9685 4.04457 12.6128C4.79821 14.352 6.23401 15.5644 8.79845 16.1139C11.3384 16.6582 13.2624 16.477 14.8728 16.1133C16.4544 15.7562 18.927 15.0458 19.9213 12.466C20.4152 11.1845 20.3046 10.1708 19.9057 9.41889C19.5117 8.67621 18.8526 8.21947 18.2969 8.01106C17.7374 7.80124 17.1071 7.77705 16.5357 7.85056C15.9643 7.92408 15.4294 8.09815 15.0533 8.3071C14.9389 8.37066 14.8264 8.43833 14.7114 8.50753C14.1158 8.86584 13.4522 9.2651 12.0996 9.34707C11.2921 9.39601 10.7096 9.27971 10.2377 9.09864C9.83175 8.94291 9.50418 8.73911 9.16665 8.52912C9.10543 8.49103 9.04388 8.45273 8.98147 8.41448C8.16367 7.91325 7.47079 7.68286 6.65766 7.76697Z"
          fill="url(#paint1_radial_3135_602)"
          fillOpacity="0.2"
        />
        <path
          d="M6.65766 7.76697C6.0073 7.83425 5.24553 8.18195 4.69614 8.73135C4.054 9.37349 3.33206 10.9685 4.04457 12.6128C4.79821 14.352 6.23401 15.5644 8.79845 16.1139C11.3384 16.6582 13.2624 16.477 14.8728 16.1133C16.4544 15.7562 18.927 15.0458 19.9213 12.466C20.4152 11.1845 20.3046 10.1708 19.9057 9.41889C19.5117 8.67621 18.8526 8.21947 18.2969 8.01106C17.7374 7.80124 17.1071 7.77705 16.5357 7.85056C15.9643 7.92408 15.4294 8.09815 15.0533 8.3071C14.9389 8.37066 14.8264 8.43833 14.7114 8.50753C14.1158 8.86584 13.4522 9.2651 12.0996 9.34707C11.2921 9.39601 10.7096 9.27971 10.2377 9.09864C9.83175 8.94291 9.50418 8.73911 9.16665 8.52912C9.10543 8.49103 9.04388 8.45273 8.98147 8.41448C8.16367 7.91325 7.47079 7.68286 6.65766 7.76697Z"
          stroke="url(#paint2_linear_3135_602)"
          strokeWidth="0.5"
        />
        <ellipse
          cx="11.9748"
          cy="18.4601"
          fill="#4F6217"
          rx="5.30388"
          ry="0.382491"
        />
      </g>
    ) : (
      <g clipPath="url(#clip0_3733_43)">
        <rect fill="#FAFAFA" height="24" width="24" />
        <path
          d="M6.65766 7.76697C6.0073 7.83425 5.24553 8.18195 4.69614 8.73135C4.054 9.37349 3.33206 10.9685 4.04457 12.6128C4.79821 14.352 6.23401 15.5644 8.79845 16.1139C11.3384 16.6582 13.2624 16.477 14.8728 16.1133C16.4544 15.7562 18.927 15.0458 19.9213 12.466C20.4152 11.1845 20.3046 10.1708 19.9057 9.41889C19.5117 8.67621 18.8526 8.21947 18.2969 8.01106C17.7374 7.80124 17.1071 7.77705 16.5357 7.85056C15.9643 7.92408 15.4294 8.09815 15.0533 8.3071C14.9389 8.37066 14.8264 8.43833 14.7114 8.50753C14.1158 8.86584 13.4522 9.2651 12.0996 9.34707C11.2921 9.39601 10.7096 9.27971 10.2377 9.09864C9.83175 8.94291 9.50418 8.73911 9.16665 8.52912C9.10543 8.49103 9.04388 8.45273 8.98147 8.41448C8.16367 7.91325 7.47079 7.68286 6.65766 7.76697Z"
          fill="url(#paint0_linear_3733_43)"
        />
        <path
          d="M6.65766 7.76697C6.0073 7.83425 5.24553 8.18195 4.69614 8.73135C4.054 9.37349 3.33206 10.9685 4.04457 12.6128C4.79821 14.352 6.23401 15.5644 8.79845 16.1139C11.3384 16.6582 13.2624 16.477 14.8728 16.1133C16.4544 15.7562 18.927 15.0458 19.9213 12.466C20.4152 11.1845 20.3046 10.1708 19.9057 9.41889C19.5117 8.67621 18.8526 8.21947 18.2969 8.01106C17.7374 7.80124 17.1071 7.77705 16.5357 7.85056C15.9643 7.92408 15.4294 8.09815 15.0533 8.3071C14.9389 8.37066 14.8264 8.43833 14.7114 8.50753C14.1158 8.86584 13.4522 9.2651 12.0996 9.34707C11.2921 9.39601 10.7096 9.27971 10.2377 9.09864C9.83175 8.94291 9.50418 8.73911 9.16665 8.52912C9.10543 8.49103 9.04388 8.45273 8.98147 8.41448C8.16367 7.91325 7.47079 7.68286 6.65766 7.76697Z"
          fill="url(#paint1_radial_3733_43)"
          fillOpacity="0.2"
        />
        <path
          d="M6.65766 7.76697C6.0073 7.83425 5.24553 8.18195 4.69614 8.73135C4.054 9.37349 3.33206 10.9685 4.04457 12.6128C4.79821 14.352 6.23401 15.5644 8.79845 16.1139C11.3384 16.6582 13.2624 16.477 14.8728 16.1133C16.4544 15.7562 18.927 15.0458 19.9213 12.466C20.4152 11.1845 20.3046 10.1708 19.9057 9.41889C19.5117 8.67621 18.8526 8.21947 18.2969 8.01106C17.7374 7.80124 17.1071 7.77705 16.5357 7.85056C15.9643 7.92408 15.4294 8.09815 15.0533 8.3071C14.9389 8.37066 14.8264 8.43833 14.7114 8.50753C14.1158 8.86584 13.4522 9.2651 12.0996 9.34707C11.2921 9.39601 10.7096 9.27971 10.2377 9.09864C9.83175 8.94291 9.50418 8.73911 9.16665 8.52912C9.10543 8.49103 9.04388 8.45273 8.98147 8.41448C8.16367 7.91325 7.47079 7.68286 6.65766 7.76697Z"
          stroke="url(#paint2_linear_3733_43)"
          strokeWidth="0.5"
        />
        <ellipse
          cx="11.9748"
          cy="18.4601"
          fill="#4F6217"
          rx="5.30388"
          ry="0.382491"
        />
      </g>
    )}
    {rounded ? (
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3135_602"
          x1="9.5"
          x2="12"
          y1="8.5"
          y2="15.5"
        >
          <stop stopColor="#D1D875" />
          <stop offset="1" stopColor="#929206" />
        </linearGradient>
        <radialGradient
          cx="0"
          cy="0"
          gradientTransform="translate(7.5 11.5) rotate(37.875) scale(5.70088 11.1136)"
          gradientUnits="userSpaceOnUse"
          id="paint1_radial_3135_602"
          r="1"
        >
          <stop stopColor="#E5EC8E" />
          <stop offset="1" stopColor="#B1BB00" stopOpacity="0.3" />
        </radialGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_3135_602"
          x1="13"
          x2="9.5"
          y1="19"
          y2="7.5"
        >
          <stop stopColor="#4F6217" />
          <stop offset="1" stopColor="#D0D770" />
        </linearGradient>
        <clipPath id="clip0_3135_602">
          <rect fill="white" height="24" rx="12" width="24" />
        </clipPath>
      </defs>
    ) : (
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3733_43"
          x1="9.5"
          x2="12"
          y1="8.5"
          y2="15.5"
        >
          <stop stopColor="#D1D875" />
          <stop offset="1" stopColor="#929206" />
        </linearGradient>
        <radialGradient
          cx="0"
          cy="0"
          gradientTransform="translate(7.5 11.5) rotate(37.875) scale(5.70088 11.1136)"
          gradientUnits="userSpaceOnUse"
          id="paint1_radial_3733_43"
          r="1"
        >
          <stop stopColor="#E5EC8E" />
          <stop offset="1" stopColor="#B1BB00" stopOpacity="0.3" />
        </radialGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_3733_43"
          x1="13"
          x2="9.5"
          y1="19"
          y2="7.5"
        >
          <stop stopColor="#4F6217" />
          <stop offset="1" stopColor="#D0D770" />
        </linearGradient>
        <clipPath id="clip0_3733_43">
          <rect fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    )}
  </svg>
)

export default MagicBeansStore
