import React, { useContext, useEffect, useState } from 'react'
import { MultiStepWizard } from 'components/presentation'
import {
  CrowdfundReserveFormFlowProps,
  Reservation,
} from 'registry/components/CrowdfundReserveFormFlow/CrowdfundReserveFormFlow.types'
import GiftGiverInfoForm from 'registry/components/CrowdfundReserveFormFlow/components/GiftGiverInfoForm/GiftGiverInfoForm'
import useCrowdfundReservation from 'registry/hooks/useCrowdfundReservation'
import { GiftGiverInfoFormState } from 'registry/components/CrowdfundReserveFormFlow/components/GiftGiverInfoForm/GiftGiverInfoForm.types'
import PaymentOptions from 'registry/components/CrowdfundReserveFormFlow/components/PaymentOptions/PaymentOptions'
import {
  selectedPaymentOptions,
  selectedPaymentPreferences,
} from 'registry/components/CrowdfundReserveFormFlow/utils/helpers'
import ContributionDetails from 'registry/components/CrowdfundReserveFormFlow/components/ContributionDetails/ContributionDetails'
import { CashFundPaymentPreference } from 'src/types/registry'
import RecordContribution from 'registry/components/CrowdfundReserveFormFlow/components/RecordContribution/RecordContribution'
import ThankYouAddressModal from 'registry/components/modals/ThankYouAddressModal'
import ContributionSuccess from 'registry/components/CrowdfundReserveFormFlow/components/ContributionSuccess/ContributionSuccess'
import CancelReservation from 'registry/components/CrowdfundReserveFormFlow/components/CancelReservation/CancelReservation'
import CrowdfundReserveModalTitle from 'registry/components/CrowdfundReserveFormFlow/components/CrowdfundReserveModalTitle/CrowdfundReserveModalTitle'
import InProgressReservationContext from 'registry/contexts/inProgressReservation'
import useCashFundContributionActions from 'registry/hooks/useCashFundContributionActions'
import {
  CANCEL_STEP,
  CASH,
  CONFIRM_DETAILS_STEP,
  GIFT_GIVER_INFO_STEP,
  PAYMENT_INFO_STEP,
  PAYMENT_OPTION_STEP,
  SUCCESS_STEP,
  THANK_YOU_ADDRESS_STEP,
} from './constants'

const CrowdfundReserveFormFlow: React.FC<CrowdfundReserveFormFlowProps> = ({
  preferences,
  regItem,
  onCreateReservation,
  onHide,
  onUpdateReservation,
  onRemoveReservation,
  registryFirstNames,
}) => {
  const { reservation: inProgressReservation, setInProgressReservation } =
    useContext(InProgressReservationContext)

  const [selectedPreference, setSelectedPreference] =
    useState<CashFundPaymentPreference>()
  const [showPreferenceRequired, setShowPreferenceRequired] =
    useState<boolean>(false)
  const [previousStep, setPreviousStep] = useState<number>()

  const crowdfundReservationState = useCrowdfundReservation({
    initialStep: GIFT_GIVER_INFO_STEP,
    inProgressReservation,
    onCreateReservation,
    onUpdateReservation,
    onRemoveReservation,
    regItem,
  })

  const {
    activeStep,
    currentReservation,
    isSaving,
    paymentOption,
    saveReservation,
    setActiveStep,
    setCurrentReservation,
    setPaymentOption,
  } = crowdfundReservationState

  const {
    cancelCashFundContribution,
    confirmCashFundContribution,
    isCanceling,
    isConfirming,
  } = useCashFundContributionActions({
    onConfirm: (res) => {
      setCurrentReservation({
        ...(currentReservation || {}),
        ...res,
      })
      setActiveStep(THANK_YOU_ADDRESS_STEP)
    },
    onCancel: () => onHide(),
  })

  const handleUnpurchasedReservation = (formState: GiftGiverInfoFormState) => {
    setCurrentReservation({ ...formState, isActive: false } as Reservation)
    setActiveStep(PAYMENT_OPTION_STEP)
  }

  const paymentPreferenceWithKey = (key: string) =>
    preferences?.paymentPreferences?.find(
      (preference: CashFundPaymentPreference) => preference.key === key
    )

  const handleOptionSelected = (option: string) => {
    setShowPreferenceRequired(false)
    setPaymentOption(option)
    setSelectedPreference(paymentPreferenceWithKey(option))
  }

  useEffect(() => {
    if (inProgressReservation) {
      handleOptionSelected(inProgressReservation.paymentMethodKey)
      setActiveStep(CONFIRM_DETAILS_STEP)
      setInProgressReservation(undefined)
    }
  }, [inProgressReservation])

  const handleUpdatePaymentMethod = () => {
    if (isSaving) return

    const possiblePaymentMethods = selectedPaymentOptions(preferences)
    if (!selectedPreference && possiblePaymentMethods.length > 0) {
      setShowPreferenceRequired(true)
      return
    }

    // if the gift giver was not able to select a payment method
    // we allow them to proceed assuming cash as the selected
    // payment method
    if (!selectedPreference && possiblePaymentMethods.length === 0) {
      handleOptionSelected(CASH)
    }

    const preference = (selectedPreference ||
      paymentPreferenceWithKey(CASH)) as CashFundPaymentPreference

    saveReservation({ isActive: false, paymentMethod: paymentOption }).then(
      () => {
        if (preference.key === CASH) {
          setActiveStep(CONFIRM_DETAILS_STEP)
          return
        }

        setActiveStep(PAYMENT_INFO_STEP)
      }
    )
  }

  const handleConfirmContribution = (newReservation: Reservation) => {
    if (isConfirming) return

    confirmCashFundContribution({
      ...newReservation,
      isActive: true,
      cashFundContributionAttributes: {
        paymentMethod: paymentOption,
      },
    })
  }

  const handleVerifyCancel = () => {
    setPreviousStep(activeStep)
    setActiveStep(CANCEL_STEP)
  }

  const handleBack = () => {
    setActiveStep(previousStep as number)
    setPreviousStep(undefined)
  }

  const handleCancelReservation = () => {
    if (isCanceling) return

    const reservation = currentReservation as Reservation
    cancelCashFundContribution(reservation.token as string)
  }

  return (
    <MultiStepWizard activeStep={activeStep} animateInitialStep={false}>
      {/* Step 1: Gift giver info */}
      <div>
        <CrowdfundReserveModalTitle>
          Contribution details
        </CrowdfundReserveModalTitle>
        <GiftGiverInfoForm
          regItem={regItem}
          reservation={currentReservation}
          onFormSubmitted={handleUnpurchasedReservation}
        />
      </div>

      {/* Step 2: Payment options */}
      <div>
        <CrowdfundReserveModalTitle>
          Contribution details
        </CrowdfundReserveModalTitle>
        <PaymentOptions
          showNextButton
          isLoading={isSaving}
          paymentOptions={selectedPaymentOptions(preferences)}
          regItem={regItem}
          showPreferenceRequired={showPreferenceRequired}
          onAdvance={handleUpdatePaymentMethod}
          onPaymentOptionSelected={handleOptionSelected}
        />
      </div>

      {/* Step 3: Contribution details */}
      <ContributionDetails
        giftAmount={currentReservation?.giftAmount}
        preference={selectedPreference as CashFundPaymentPreference}
        onMakePayment={() => setActiveStep(CONFIRM_DETAILS_STEP)}
      />

      {/* Step 4: Record contribution */}
      <RecordContribution
        isLoading={isConfirming}
        paymentPreferences={selectedPaymentPreferences(preferences)}
        regItem={regItem}
        registryFirstNames={registryFirstNames}
        reservation={currentReservation as Reservation}
        selectedPreference={selectedPreference as CashFundPaymentPreference}
        onCancel={handleCancelReservation}
        onPaymentPreferenceUpdate={handleOptionSelected}
        onReservationUpdate={handleConfirmContribution}
      />

      {/* Step 5: Thank you address */}
      {/* @ts-ignore */}
      <ThankYouAddressModal
        afterSubmit={() => setActiveStep(SUCCESS_STEP)}
        giftGiverInfo={{
          name: currentReservation?.name?.toString() || null,
          email: currentReservation?.email?.toString() || null,
          productId: null,
        }}
        onHide={onHide}
      />

      {/* Step 6: Success step */}
      <ContributionSuccess
        address={preferences.address}
        names={registryFirstNames}
        paymentPreference={selectedPreference as CashFundPaymentPreference}
        reservation={currentReservation as Reservation}
        onCancel={handleVerifyCancel}
        onDone={onHide}
      />

      {/* Step 7: Cancel step */}
      <CancelReservation
        isLoading={isCanceling}
        paymentMethod={selectedPreference?.name as string}
        registryFirstNames={registryFirstNames}
        onBack={handleBack}
        onConfirm={handleCancelReservation}
      />
    </MultiStepWizard>
  )
}

export default CrowdfundReserveFormFlow
