import { FlagsProvider } from 'flagged'

import MicroNavBar from 'src/components/MicroNavBar'
import GlobalNav from 'components/global-nav'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { OutOfAreaBanner, InputAddressBanner } from 'components/banners'
import { setHost } from 'lib/urls'

const RenderBanner = (props) => {
  const { activeNavItem, currentUserCountry } = props
  const [currentUser] = useCurrentUser()

  // This banner takes priority over the Targeted Placement Global Banner
  // If these banners are to be shown the Targeted Placement Global Banner will not be shown
  // https://github.com/babylist/web/blob/main/packs/placements/app/controllers/concerns/placements/global_banner_concern.rb
  const shouldShowOutOfAreaBanner =
    currentUserCountry !== null && currentUserCountry !== 'United States'
  const shouldShowInputAddressBanner =
    currentUser && currentUserCountry === null

  if (activeNavItem === 'Store') {
    if (shouldShowOutOfAreaBanner) {
      return (
        <OutOfAreaBanner
          country={currentUserCountry}
          showBabyListButton={currentUser && !currentUser.addedBabyListButton}
        />
      )
    }

    if (shouldShowInputAddressBanner) {
      return <InputAddressBanner />
    }
  }

  return null
}

const Header = (props) => {
  const { featureFlags, isRegistryGuestView, inRegistryGlowupExperiment } =
    props
  setHost(props._canonicalHost) // Sets the host for SSR absolute URLs
  return (
    <>
      {RenderBanner(props)}
      <FlagsProvider features={featureFlags}>
        {isRegistryGuestView && inRegistryGlowupExperiment && <MicroNavBar />}
        <GlobalNav {...props} />
      </FlagsProvider>
    </>
  )
}

export default Header
