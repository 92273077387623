import NiceModal, { useModal } from '@ebay/nice-modal-react'
// eslint-disable-next-line import/no-extraneous-dependencies
import PurchaserFormGroup from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/RegItemPurchaseModal/components/PurchaserFormGroup/PurchaserFormGroup'
import PurchasedQuantity from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/RegItemPurchaseModal/components/PurchasedQuantity/PurchasedQuantity'
// eslint-disable-next-line import/no-extraneous-dependencies
import TextInput from 'baby-design/components/input/TextInput/TextInput'
import useMarkPurchaseFormField from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/hooks/useMarkPurchaseFormField'
import useMarkPurchaseModal from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/hooks/useMarkPurchaseModal'
import { RegItemPurchaseModalProps } from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/RegItemPurchaseModal/RegItemPurchaseModal.types'
import MarkPurchasedModal from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/MarkPurchasedModal/MarkPurchasedModal'
import { Reservation } from 'src/types/reservation'
import { track, useTracking } from 'lib/analytics'
import css from './RegItemPurchaseModal.styles.scss'

const MODAL_TITLE = 'Mark as purchased'
const MODAL_SUBTITLE =
  "Let us know if this item has been purchased so you don't receive multiples."
const PURCHASER_NAME_MISSING_ERROR = 'Enter a name'
const SELF_PURCHASE_CTA = 'I purchased this'
const OTHER_PURCHASE_CTA = 'Someone else purchased this'

const RegItemPurchaseModal = NiceModal.create(
  ({ regItem }: RegItemPurchaseModalProps) => {
    const modal = useModal()
    const tracker = useTracking()

    const nameField = useMarkPurchaseFormField<string>({
      name: 'name',
      errorMessage: (val) => (!val ? PURCHASER_NAME_MISSING_ERROR : undefined),
    })
    const quantityField = useMarkPurchaseFormField<number>({
      name: 'quantity',
      defaultValue: 1,
    })

    const fields = (isSelfPurchase: boolean | undefined) => {
      if (isSelfPurchase === undefined) return []
      return isSelfPurchase ? [quantityField] : [nameField, quantityField]
    }

    const handleTrackPurchaseConfirmed = (reservation: Reservation) => {
      tracker.trackEvent({
        event: track.purchaseConfirmed,
        eventCta: isSelfPurchase ? SELF_PURCHASE_CTA : OTHER_PURCHASE_CTA,
        eventLocation: track.EventLocation.REG_ITEM_EDIT_SCREEN,
        eventType: track.EventType.MARK_AS_PURCHASED,
        regItemIds: [regItem.id],
        regItemQuantity: reservation.quantity,
        registry: regItem.registry,
      })
    }

    const {
      closeModal,
      handleMarkAsPurchased,
      handleSubmit,
      isCreatingReservation,
      isSelfPurchase,
      isSelfPurchaseError,
      setIsSelfPurchase,
    } = useMarkPurchaseModal({
      fields,
      modal,
      onMarkedAsPurchased: handleTrackPurchaseConfirmed,
      regItem,
    })

    const { value: purchasedQuantity, updateValue: setPurchasedQuantity } =
      quantityField
    const {
      error: purchaserNameError,
      value: purchaserName,
      updateValue: setPurchaserName,
    } = nameField

    return (
      <MarkPurchasedModal
        errorCallout={isSelfPurchaseError}
        isLoading={isCreatingReservation}
        isOpen={modal.visible}
        subtitle={MODAL_SUBTITLE}
        title={MODAL_TITLE}
        onCloseModal={closeModal}
        onSubmit={() => handleSubmit(handleMarkAsPurchased)}
      >
        <div className={css.RegItemPurchasedModal__RadioGroup}>
          <PurchaserFormGroup
            id="self_purchase"
            label={SELF_PURCHASE_CTA}
            selected={isSelfPurchase === true}
            onSelected={() => setIsSelfPurchase(true)}
          >
            <PurchasedQuantity
              quantity={purchasedQuantity as number}
              onQuantityChanged={setPurchasedQuantity}
            />
          </PurchaserFormGroup>
          <PurchaserFormGroup
            id="other_purchase"
            label={OTHER_PURCHASE_CTA}
            selected={isSelfPurchase === false}
            onSelected={() => setIsSelfPurchase(false)}
          >
            <div />
            <TextInput
              inputProps={{
                value: purchaserName,
                onChange: (e) => setPurchaserName(e.target.value),
              }}
              label="Purchaser's name"
              size="md"
              status={
                purchaserNameError
                  ? { type: 'error', message: purchaserNameError }
                  : undefined
              }
            />
            <PurchasedQuantity
              quantity={purchasedQuantity as number}
              onQuantityChanged={setPurchasedQuantity}
            />
          </PurchaserFormGroup>
        </div>
      </MarkPurchasedModal>
    )
  }
)

export default RegItemPurchaseModal
