import React from 'react'

interface NordstromStoreProps {
  className?: string
  rounded?: boolean
}

const NordstromStore: React.FC<NordstromStoreProps> = ({
  className,
  rounded = true,
}: NordstromStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {rounded ? (
      <rect fill="black" height="24" rx="12" width="24" />
    ) : (
      <rect fill="black" height="24" width="24" />
    )}
    <path
      d="M16.5 5.75777V18.5L9.38655 10.4608L9.32039 10.3884V18.1847H7.5V5.5L14.6742 13.6709V5.75777H16.5Z"
      fill="white"
    />
  </svg>
)

export default NordstromStore
