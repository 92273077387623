import { RegItem } from 'src/types/regItem'
import { track, useTracking } from 'lib/analytics'
import { Offer } from 'src/types/offer'

const LAST_PRODUCT_OFFER_CLICKED_KEY = 'lastProductOfferClickedByRegItem'

const useProductOfferClickTracking = (regItem: RegItem) => {
  const tracker = useTracking()

  const getLastOfferClicksByRegItem = () => {
    const offerClicksFromLocalStorage = localStorage.getItem(
      LAST_PRODUCT_OFFER_CLICKED_KEY
    )
    return offerClicksFromLocalStorage
      ? JSON.parse(offerClicksFromLocalStorage)
      : {}
  }

  const getLastOfferClickStore = () =>
    getLastOfferClicksByRegItem()?.[regItem.id]

  const recordLastProductOfferClicked = (offer: Offer) => {
    const offerClicks = getLastOfferClicksByRegItem()
    offerClicks[regItem.id] = offer.storeName

    localStorage.setItem(
      LAST_PRODUCT_OFFER_CLICKED_KEY,
      JSON.stringify(offerClicks)
    )
  }

  const trackPurchaseConfirmed = (props: Record<string, any>) => {
    const lastOfferClickedStore = getLastOfferClickStore()
    tracker.trackEvent({
      regItemIds: [regItem.id],
      event: track.purchaseConfirmed,
      eventType: track.EventType.MARK_AS_PURCHASED,
      lastOfferClickedStore,
      ...props,
    })
  }

  return {
    recordLastProductOfferClicked,
    trackPurchaseConfirmed,
  }
}

export default useProductOfferClickTracking
