import React from 'react'

interface BestBuyStoreProps {
  className?: string
  rounded?: boolean
}

const BestBuyStore: React.FC<BestBuyStoreProps> = ({
  className,
  rounded = true,
}: BestBuyStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {rounded ? (
      <rect fill="#0046BE" height="24" rx="12" width="24" />
    ) : (
      <rect fill="#0046BE" height="24" width="24" />
    )}
    <path
      d="M6.98509 7L4 9.98509V14.0149L6.98503 17H19.6119V7H6.98509Z"
      fill="#FFED31"
    />
    <path
      d="M6.4948 11.9983C6.4948 12.3692 6.19409 12.6699 5.82315 12.6699C5.4522 12.6699 5.15155 12.3692 5.15155 11.9983C5.15155 11.6273 5.45227 11.3267 5.82315 11.3267C6.19402 11.3267 6.4948 11.6273 6.4948 11.9983Z"
      fill="#3B4095"
    />
  </svg>
)

export default BestBuyStore
