import React from 'react'

interface BlueAirStoreProps {
  className?: string
  rounded?: boolean
}

const BlueAirStore: React.FC<BlueAirStoreProps> = ({
  className,
  rounded = true,
}: BlueAirStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {rounded ? (
      <rect fill="#FAFAFA" height="24" rx="12" width="24" />
    ) : (
      <rect fill="#FAFAFA" height="24" width="24" />
    )}
    <circle cx="12" cy="12" fill="#0C2F4F" r="9" />
  </svg>
)

export default BlueAirStore
