/* eslint-disable react/prop-types */
import classNames from 'classnames'
import { numberToCurrency } from 'lib/money'
import { track, useTracking } from 'lib/analytics'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button } from 'baby-design'
// eslint-disable-next-line import/no-extraneous-dependencies
import { LinkOut } from 'baby-design/icons'
import useProductOfferClickTracking from 'shared/hooks/useProductOfferClickTracking/useProductOfferClickTracking'

export const Offer = ({ offer, openRedirectModal, registry, regItem }) => {
  const { price, storeDisplayName, storeIconName, url } = offer
  const tracker = useTracking()
  const { recordLastProductOfferClicked } =
    useProductOfferClickTracking(regItem)

  const handleOfferClick = () => {
    openRedirectModal({
      url,
      storeDisplayName,
      regItem,
      isShopRegistryView: false,
      onRedirectSuccess: () => {
        recordLastProductOfferClicked(offer)
      },
    })
    tracker.trackEvent({
      event: track.registryProductOfferClicked,
      minEstimate: regItem.deliveryEstimate?.min,
      maxEstimate: regItem.deliveryEstimate?.max,
      offerIds: regItem.offers.map(({ id }) => id),
      regItemId: regItem.id,
      showerDate: registry.showerInfo?.showers[0]?.date,
      willArriveBeforeArrivalDate:
        registry.deliveryEstimate?.arrivesBeforeArrivalDate,
      willArriveBeforeShower: regItem.deliveryEstimate?.arrivesBeforeShower,
      store: storeDisplayName,
    })
  }
  return (
    <div
      className="flex ptm align-center small"
      role="button"
      style={{ cursor: 'pointer' }}
      tabIndex={0}
      onClick={handleOfferClick}
      onKeyPress={handleOfferClick}
    >
      <span
        className={classNames(
          'icon-offer',
          `icon-offer-${storeIconName}`,
          'mrs'
        )}
      />
      <span className="flex-grow text-overflow-ellipsis prs">
        {storeDisplayName}
      </span>
      {price ? <span className="small">{numberToCurrency(price)}</span> : null}
      <div className="plm">
        <Button
          className="phl"
          icon={{ side: 'right', icon: <LinkOut />, size: 'sm' }}
          size="sm"
          variant="tertiary"
        >
          Buy
        </Button>
      </div>
    </div>
  )
}
