import { useState, useEffect, useMemo, useCallback } from 'react'
import classnames from 'classnames'
import useOnCurrentUserLoaded from 'shared/hooks/useOnCurrentUserLoaded/useOnCurrentUserLoaded'
import {
  setExpiringLocalStorage,
  getExpiringLocalStorage,
} from 'baby-ui/utils/expireLocalStorage'
import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'
import { track, useTracking } from 'lib/analytics'
import { CurrentUser } from '../../../../../global'
import DismissibleBanner from '../../../../../components/DismissibleBanner'
import FadeOut from '../../../../../components/transitions/FadeOut'
import { InfoButton } from '../../../../../components/buttons'
import { newRegistryPath } from '../../../../../lib/urls'

import SignUpBannerCss from './SignUpBanner.styles.scss'

export interface SignUpBannerProps {
  // Don't wait for a scroll to trigger the banner.
  // Used for tests to avoid mocking scroll behavior
  showImmediately?: boolean
}

const SignUpBanner = ({ showImmediately }: SignUpBannerProps) => {
  const tracker = useTracking()
  const { flagValue: bannerTreatment } = useFeatureFlag(
    '24f_ex_signup_banner',
    'Control'
  )

  const [showSignUpBanner, setShowSignUpBanner] = useState(
    showImmediately || false
  )

  const dismissalKey = 'dismissibleBanner-guidesSignupBar'

  // Don't show the banner again for an hour
  const persistDismissal = useCallback(() => {
    setExpiringLocalStorage(dismissalKey, 'dismissed', 3600)
  }, [])

  // Has the banner been dismissed already?
  // Memoized on mount so the banner does not suddenly re-appear after TTL
  // if this component happens to be re-rendered without a page navigation
  const dismissedWithinTTL = useMemo(
    () => dismissalKey && getExpiringLocalStorage(dismissalKey) === 'dismissed',
    []
  )

  // Extend dismissal period after render if previously dismissed and within TTL
  useEffect(() => {
    if (dismissedWithinTTL) {
      persistDismissal()
    }
  }, [dismissedWithinTTL, persistDismissal])

  // Show on scroll so async loading doesn't affect LCP
  const showUnlessDismissed = () => {
    if (!dismissedWithinTTL) {
      setShowSignUpBanner(true)
      tracker.trackEvent({
        event: track.signupBannerDisplayed,
        bannerTreatment,
      })
    }

    window.removeEventListener('scroll', showUnlessDismissed)
  }
  useOnCurrentUserLoaded((currentUser?: CurrentUser) => {
    if (!currentUser) window.addEventListener('scroll', showUnlessDismissed)
  })

  const dismissBanner = () => {
    persistDismissal()
    setShowSignUpBanner(false)

    tracker.trackEvent({
      event: track.signupBannerDismissed,
      bannerTreatment,
    })
  }

  const trackClick = useCallback(
    (cta) => {
      tracker.trackEvent({
        event: track.signupBannerClicked,
        eventCta: cta,
        bannerTreatment,
      })
    },
    [bannerTreatment, tracker]
  )

  return (
    <FadeOut
      animateOnEnter
      className={classnames(SignUpBannerCss.root, SignUpBannerCss.sticky)}
      show={showSignUpBanner && !dismissedWithinTTL}
    >
      <DismissibleBanner
        className={SignUpBannerCss.signUpBannerBg}
        role="banner"
        onClose={dismissBanner}
      >
        <InfoButton
          pill
          className={SignUpBannerCss.startNewRegButton}
          href={`${newRegistryPath}?loc=Banner+Button`}
          onClick={() => trackClick('Start Your Registry')}
        >
          Start Your Registry
        </InfoButton>
      </DismissibleBanner>
    </FadeOut>
  )
}

export default SignUpBanner
