import React from 'react'

interface TinyLandStoreProps {
  className?: string
  rounded?: boolean
}

const TinyLandStore: React.FC<TinyLandStoreProps> = ({
  className,
  rounded = true,
}: TinyLandStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3733_37)">
      {rounded ? (
        <rect fill="#FAFAFA" height="24" rx="12" width="24" />
      ) : (
        <rect fill="#FAFAFA" height="24" width="24" />
      )}
      <path
        d="M8.71224 3.75802C9.00283 3.34109 9.57828 3.24237 9.99121 3.5386L19.1459 10.1061C19.3258 10.2351 19.2345 10.5191 19.013 10.5191H4.43654C4.25242 10.5191 4.14433 10.312 4.24961 10.1609L8.71224 3.75802Z"
        fill="#FF8B40"
      />
      <path
        d="M4.22786 11.5444C4.22786 11.2927 4.43188 11.0887 4.68356 11.0887H6.50634C7.00969 11.0887 7.41773 11.4967 7.41773 12.0001V20.5444C7.41773 20.796 7.21371 21.0001 6.96204 21.0001H5.13925C4.63591 21.0001 4.22786 20.592 4.22786 20.0887V11.5444Z"
        fill="#87AE50"
      />
      <path
        d="M19.2658 11.5444C19.2658 11.2927 19.0618 11.0887 18.8101 11.0887H16.9874C16.484 11.0887 16.076 11.4967 16.076 12.0001V20.5444C16.076 20.796 16.28 21.0001 16.5317 21.0001H18.3544C18.8578 21.0001 19.2658 20.592 19.2658 20.0887V11.5444Z"
        fill="#FAD05C"
      />
      <path
        clipRule="evenodd"
        d="M8.79867 21.0001C8.28994 21.0001 7.86899 20.5775 7.9966 20.0851C8.42877 18.4174 9.94399 17.1858 11.7468 17.1858C13.5497 17.1858 15.0649 18.4174 15.4971 20.0851C15.6247 20.5775 15.2037 21.0001 14.695 21.0001L8.79867 21.0001Z"
        fill="#F47722"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_3733_37">
        <rect fill="white" height="24" width="24" />
      </clipPath>
    </defs>
  </svg>
)

export default TinyLandStore
