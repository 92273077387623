import {
  CashFundPaymentPreference,
  CashFundPreferences,
} from 'src/types/registry'
import {
  PaymentPreference,
  SaveCashFundPreferencesRequest,
} from 'src/routes/(registry)/api/types'

const convertPreferenceToRequestBody = (
  preference: CashFundPaymentPreference
): PaymentPreference => ({
  name: preference.key,
  selected: !!preference.selected,
  attributes:
    preference?.fields?.map((field) => ({
      key: field.name,
      value: field.value,
    })) || [],
})

export const paymentPreferencesSaveRequestBody = (
  cashFundPreferences: CashFundPreferences
): SaveCashFundPreferencesRequest => ({
  paymentPreferences:
    cashFundPreferences.paymentPreferences?.map(
      convertPreferenceToRequestBody
    ) || [],
})
