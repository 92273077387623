import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'

import { SaveButton } from 'components/buttons'
import { ModalFooter } from 'components/modal'
import OrderFields from './order-fields'
import useSecondhandItemsEnabled from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/hooks/useSecondhandItemsEnabled'

export const BasePurchaseInfoForm = ({
  fields: {
    isPurchasedPreloved,
    orderNumber,
    storeName,
    orderNumberNotAvailable,
    quantity,
  },
  handleSubmit,
  submitting,
  submitBtnText,
  registry,
  autofillStore,
  hideStoreInput,
  reservation,
}) => {
  const showSecondhandCheckbox = useSecondhandItemsEnabled()

  const { quantityNeeded, purchaseQuantityLimit, showPurchaseQuantityLimit } =
    reservation?.regItem || {}

  const handleSecondhandChanged = (e) => {
    isPurchasedPreloved.onChange(e)

    // don't automatically uncheck no order number field
    if (e.target.checked) {
      orderNumber.onChange('N/A')
      storeName.onChange('Secondhand')
      orderNumberNotAvailable.onChange(e)
    } else {
      orderNumber.onChange('')
      storeName.onChange('')
    }
  }

  return (
    <form onSubmit={handleSubmit} className="animated fadeIn animate-250ms">
      <OrderFields
        autofillStore={autofillStore}
        hideStoreInput={hideStoreInput}
        isPurchasedPreloved={isPurchasedPreloved}
        orderNumber={orderNumber}
        orderNumberNotAvailable={orderNumberNotAvailable}
        purchaseQuantityLimit={purchaseQuantityLimit}
        quantity={quantity}
        quantityNeeded={quantityNeeded}
        registry={registry}
        showPurchaseQuantityLimit={showPurchaseQuantityLimit}
        showSecondhandCheckbox={showSecondhandCheckbox}
        storeName={storeName}
        onSecondhandChanged={handleSecondhandChanged}
      />
      <ModalFooter>
        <SaveButton block pill submitting={submitting} type="submit">
          {submitBtnText}
        </SaveButton>
      </ModalFooter>
    </form>
  )
}

BasePurchaseInfoForm.propTypes = {
  autofillStore: PropTypes.bool,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hideStoreInput: PropTypes.bool,
  registry: PropTypes.object,
  submitting: PropTypes.bool.isRequired,
  submitBtnText: PropTypes.string,
}

BasePurchaseInfoForm.defaultProps = {
  autofillStore: false,
  hideStoreInput: false,
  submitBtnText: 'Done',
  registry: null,
}

const looksLikeUrl = (orderNumber) => {
  return (
    orderNumber.includes('www.') ||
    orderNumber.includes('.com') ||
    orderNumber.includes('http')
  )
}

const validate = (values) => {
  const errors = {}

  if (!values.orderNumber && !values.orderNumberNotAvailable) {
    errors.orderNumber =
      'Enter an order number or choose "I don\'t have an order number"'
  }

  if (values.orderNumber && looksLikeUrl(values.orderNumber)) {
    errors.orderNumber =
      'This looks like a website address, order numbers are short, like "#111-5564732-9109025" or "BLS-1234"'
  }

  return errors
}

export const PurchaseInfoForm = reduxForm(
  {
    form: 'purchaseInfo',
    fields: [
      'isPurchased',
      'isPurchasedPreloved',
      'orderNumber',
      'storeName',
      'orderNumberNotAvailable',
      'quantity',
    ],
    validate,
  },
  (state, props) => {
    return {
      initialValues: {
        isPurchased: true,
        isPurchasedPreloved: props?.reservation?.isPurchasedPreloved,
        orderNumber: props?.reservation?.orderNumber || null,
        quantity: props?.reservation?.quantity,
        storeName: props.autofillStore ? props.storeName : null,
        orderNumberNotAvailable: false,
      },
    }
  }
)(BasePurchaseInfoForm)

PurchaseInfoForm.propTypes = {
  autofillStore: PropTypes.bool,
  reservation: PropTypes.object,
}

PurchaseInfoForm.defaultProps = {
  autofillStore: false,
  reservation: null,
}

export default PurchaseInfoForm
