import { Media, Row } from 'react-bootstrap'
import Image from 'components/image'

import css from './Author.styles.scss'

interface AuthorProps {
  author: any
  className?: string
}

const Author = ({ author, className }: AuthorProps) => {
  let name = `${author.firstName} ${author.lastName}`
  if (!author.firstName && !author.lastName) {
    name = author.name
  }
  let { jobTitle } = author
  if (author.postnominals) {
    jobTitle += `, ${author.postnominals}`
  }
  const altText = `{name} {jobTitle}`

  return (
    <Row className={className}>
      <hr className="mbl" />
      <Media className="col-sm-12">
        <Media.Left>
          {author.photo ? (
            <Image
              alt={altText}
              className="thumbnail-profile"
              src={author.photo.url}
            />
          ) : null}
        </Media.Left>
        <Media.Body>
          <h3 className={css.name}>{name}</h3>
          <h4 className={css.job_title}>{jobTitle}</h4>
          <div
            className={css.bio}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: author.bio }}
          />
        </Media.Body>
      </Media>
    </Row>
  )
}

export default Author
