import React from 'react'

interface ChaptersIndigoStoreProps {
  className?: string
  rounded?: boolean
}

const ChaptersIndigoStore: React.FC<ChaptersIndigoStoreProps> = ({
  className,
  rounded = true,
}: ChaptersIndigoStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {rounded ? (
      <rect fill="#46439B" height="24" rx="12" width="24" />
    ) : (
      <rect fill="#46439B" height="24" width="24" />
    )}
    <path
      d="M13.5 15.8847H11V4H13.5M13.5 19.3293H11V16.8336H13.5V19.3293Z"
      fill="white"
    />
  </svg>
)

export default ChaptersIndigoStore
