/* eslint-disable complexity */
import {
  get,
  isEmpty,
  isNaN,
  isNull,
  isNumber,
  isString,
  isUndefined,
  transform,
  set,
} from 'lodash'
import {
  ELECTRONIC_GIFT_CARD_MAX,
  ELECTRONIC_GIFT_CARD_MIN,
} from 'shared/constants'
import splitName from 'lib/split-name'
import emojiRegex from 'emoji-regex'

const isBlank = (value) => (isEmpty(value) && !isNumber(value)) || isNaN(value)

const isNumeric = (value) => Number(parseFloat(value)) == value

// Helper to wrap one or more rules into a single function, which returns the first error
const join = (rules) => (value, data) =>
  rules
    .map((rule) => {
      if (typeof rule === 'function') {
        return rule(value, data)
      }
      return rule.rule(value, data, rule.msg)
    })
    .filter((error) => !!error)[0 /* first error */]

export const EMOJI_REGEX = emojiRegex()
export const EMAIL_REGEX =
  /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/

// This regex is mirrored on the shopify-theme repo, keep them in sync:
// https://github.com/babylist/shopify-theme/blob/main/assets/checkout_scripts.js#L641
export const PO_BOX_REGEX = /((p\.?\s*o\.?)|(post\s*(office)?))\s*box/i

// https://gist.github.com/dperini/729294
// https://mathiasbynens.be/demo/url-regex
// NOTE: Edited to not require protocol!!!
export const URL_REGEX =
  /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i

export const MSG_EMAIL_REQUIRED = 'Oops...email is required'

export function between(min, max, prefix = '') {
  return (value) => {
    if (isPresent(value) && (value < min || value > max)) {
      return `Must be between ${prefix}${min} and ${prefix}${max}`
    }
  }
}

export const dateString = (value) => {
  if (isPresent(value) && !/^\d{4}-\d{2}-\d{2}$/.test(value)) {
    return 'Invalid date'
  }
}

export const currency = (value) => {
  // optional $, commas, and cents
  if (isPresent(value) && !/^\$?\d{1,3}(,?\d{3})*(\.\d{1,2})?$/.test(value)) {
    return 'Please enter a valid dollar amount'
  }
}

export const electronicGiftCardAmount = (value) =>
  required(value) ||
  numeric(value) ||
  between(ELECTRONIC_GIFT_CARD_MIN, ELECTRONIC_GIFT_CARD_MAX, '$')(value)

export function email(value) {
  const defaultTopLevelDomains = [
    'app',
    'art',
    'at',
    'be',
    'biz',
    'blog',
    'ca',
    'ch',
    'cloud',
    'co.il',
    'co.jp',
    'co.nz',
    'co.uk',
    'com.au',
    'com.tw',
    'com',
    'cz',
    'de',
    'dev',
    'digital',
    'dk',
    'edu',
    'email',
    'es',
    'eu',
    'fr',
    'fun',
    'global',
    'gov',
    'gr',
    'guru',
    'hk',
    'hu',
    'ie',
    'in',
    'info',
    'io',
    'it',
    'jp',
    'kr',
    'life',
    'live',
    'love',
    'media',
    'mil',
    'net.au',
    'net',
    'news',
    'nl',
    'no',
    'online',
    'org',
    'pro',
    'ru',
    'se',
    'sg',
    'shop',
    'site',
    'social',
    'space',
    'store',
    'tech',
    'today',
    'uk',
    'us',
    'video',
    'vip',
    'world',
    'xyz',
  ]

  const countryTopLevelDomains = new Set([
    'ac', // Ascension Island
    'ad', // Andorra
    'ae', // United Arab Emirates
    'af', // Afghanistan
    'ag', // Antigua and Barbuda
    'ai', // Anguilla
    'al', // Albania
    'am', // Armenia
    'an', // Netherlands Antilles (No longer in use)
    'ao', // Angola
    'aq', // Antarctica
    'ar', // Argentina
    'as', // American Samoa
    'at', // Austria
    'au', // Australia
    'aw', // Aruba
    'ax', // Åland Islands
    'az', // Azerbaijan
    'ba', // Bosnia and Herzegovina
    'bb', // Barbados
    'bd', // Bangladesh
    'be', // Belgium
    'bf', // Burkina Faso
    'bg', // Bulgaria
    'bh', // Bahrain
    'bi', // Burundi
    'bj', // Benin
    'bm', // Bermuda
    'bn', // Brunei
    'bo', // Bolivia
    'br', // Brazil
    'bs', // The Bahamas
    'bt', // Bhutan
    'bv', // Bouvet Island
    'bw', // Botswana
    'by', // Belarus
    'bz', // Belize
    'ca', // Canada
    'cc', // Cocos (Keeling) Islands
    'cd', // Democratic Republic of the Congo
    'cf', // Central African Republic
    'cg', // Republic of the Congo
    'ch', // Switzerland
    'ci', // Côte d'Ivoire (Ivory Coast)
    'ck', // Cook Islands
    'cl', // Chile
    'cm', // Cameroon
    'cn', // China
    'co', // Colombia
    'cr', // Costa Rica
    'cu', // Cuba
    'cv', // Cape Verde
    'cw', // Curaçao
    'cx', // Christmas Island
    'cy', // Cyprus
    'cz', // Czech Republic
    'de', // Germany
    'dj', // Djibouti
    'dk', // Denmark
    'dm', // Dominica
    'do', // Dominican Republic
    'dz', // Algeria
    'ec', // Ecuador
    'ee', // Estonia
    'eg', // Egypt
    'eh', // Western Sahara
    'er', // Eritrea
    'es', // Spain
    'et', // Ethiopia
    'eu', // European Union
    'fi', // Finland
    'fj', // Fiji
    'fk', // Falkland Islands (Malvinas)
    'fm', // Federated States of Micronesia
    'fo', // Faroe Islands
    'fr', // France
    'ga', // Gabon
    'gb', // United Kingdom
    'gd', // Grenada
    'ge', // Georgia
    'gf', // French Guiana
    'gg', // Guernsey
    'gh', // Ghana
    'gi', // Gibraltar
    'gl', // Greenland
    'gm', // Gambia
    'gn', // Guinea
    'gp', // Guadeloupe
    'gq', // Equatorial Guinea
    'gr', // Greece
    'gs', // South Georgia and the South Sandwich Islands
    'gt', // Guatemala
    'gu', // Guam
    'gw', // Guinea-Bissau
    'gy', // Guyana
    'hk', // Hong Kong
    'hm', // Heard Island and McDonald Islands
    'hn', // Honduras
    'hr', // Croatia
    'ht', // Haiti
    'hu', // Hungary
    'id', // Indonesia
    'ie', // Ireland
    'il', // Israel
    'im', // Isle of Man
    'in', // India
    'io', // British Indian Ocean Territory
    'iq', // Iraq
    'ir', // Iran
    'is', // Iceland
    'it', // Italy
    'je', // Jersey
    'jm', // Jamaica
    'jo', // Jordan
    'jp', // Japan
    'ke', // Kenya
    'kg', // Kyrgyzstan
    'kh', // Cambodia
    'ki', // Kiribati
    'km', // Comoros
    'kn', // Saint Kitts and Nevis
    'kp', // North Korea
    'kr', // South Korea
    'kw', // Kuwait
    'ky', // Cayman Islands
    'kz', // Kazakhstan
    'la', // Laos
    'lb', // Lebanon
    'lc', // Saint Lucia
    'li', // Liechtenstein
    'lk', // Sri Lanka
    'lr', // Liberia
    'ls', // Lesotho
    'lt', // Lithuania
    'lu', // Luxembourg
    'lv', // Latvia
    'ly', // Libya
    'ma', // Morocco
    'mc', // Monaco
    'md', // Moldova
    'me', // Montenegro
    'mf', // Saint Martin (French part)
    'mg', // Madagascar
    'mh', // Marshall Islands
    'mk', // North Macedonia
    'ml', // Mali
    'mm', // Myanmar (Burma)
    'mn', // Mongolia
    'mo', // Macau
    'mp', // Northern Mariana Islands
    'mq', // Martinique
    'mr', // Mauritania
    'ms', // Montserrat
    'mt', // Malta
    'mu', // Mauritius
    'mv', // Maldives
    'mw', // Malawi
    'mx', // Mexico
    'my', // Malaysia
    'mz', // Mozambique
    'na', // Namibia
    'nc', // New Caledonia
    'ne', // Niger
    'nf', // Norfolk Island
    'ng', // Nigeria
    'ni', // Nicaragua
    'nl', // Netherlands
    'no', // Norway
    'np', // Nepal
    'nr', // Nauru
    'nu', // Niue
    'nz', // New Zealand
    'om', // Oman
    'pa', // Panama
    'pe', // Peru
    'pf', // French Polynesia
    'pg', // Papua New Guinea
    'ph', // Philippines
    'pk', // Pakistan
    'pl', // Poland
    'pm', // Saint Pierre and Miquelon
    'pn', // Pitcairn Islands
    'pr', // Puerto Rico
    'ps', // State of Palestine
    'pt', // Portugal
    'pw', // Palau
    'py', // Paraguay
    'qa', // Qatar
    're', // Réunion
    'ro', // Romania
    'rs', // Serbia
    'ru', // Russia
    'rw', // Rwanda
    'sa', // Saudi Arabia
    'sb', // Solomon Islands
    'sc', // Seychelles
    'sd', // Sudan
    'se', // Sweden
    'sg', // Singapore
    'sh', // Saint Helena, Ascension and Tristan da Cunha
    'si', // Slovenia
    'sj', // Svalbard and Jan Mayen
    'sk', // Slovakia
    'sl', // Sierra Leone
    'sm', // San Marino
    'sn', // Senegal
    'so', // Somalia
    'sr', // Suriname
    'ss', // South Sudan
    'st', // São Tomé and Príncipe
    'sv', // El Salvador
    'sx', // Sint Maarten (Dutch part)
    'sy', // Syria
    'sz', // Eswatini
    'tc', // Turks and Caicos Islands
    'td', // Chad
    'tf', // French Southern and Antarctic Lands
    'tg', // Togo
    'th', // Thailand
    'tj', // Tajikistan
    'tk', // Tokelau
    'tl', // Timor-Leste
    'tm', // Turkmenistan
    'tn', // Tunisia
    'to', // Tonga
    'tr', // Turkey
    'tt', // Trinidad and Tobago
    'tv', // Tuvalu
    'tw', // Taiwan
    'tz', // Tanzania
    'ua', // Ukraine
    'ug', // Uganda
    'uk', // United Kingdom
    'um', // United States Minor Outlying Islands
    'us', // United States
    'uy', // Uruguay
    'uz', // Uzbekistan
    'va', // Vatican City
    'vc', // Saint Vincent and the Grenadines
    've', // Venezuela
    'vg', // British Virgin Islands
    'vi', // United States Virgin Islands
    'vn', // Vietnam
    'vu', // Vanuatu
    'wf', // Wallis and Futuna
    'ws', // Samoa
    'ye', // Yemen
    'yt', // Mayotte
    'za', // South Africa
    'zm', // Zambia
    'zw', // Zimbabwe
  ])

  const allTLDs = new Set([
    ...countryTopLevelDomains,
    ...defaultTopLevelDomains,
  ])

  if (!value) {
    return 'Enter your email'
  }

  const parts = value.split('@')
  if (parts.length !== 2) {
    return 'Please enter a valid email (e.g. name@company.com)'
  }

  const [localPart, domainPart] = parts

  if (localPart.length > 64 || domainPart.length > 255) {
    return 'Please use a valid email address'
  }

  const domainParts = domainPart.split('.')
  const localParts = localPart.split('.')
  if (
    localPart.length === 0 ||
    domainParts.length < 2 ||
    domainParts.some((part) => part.length === 0) ||
    domainParts[domainParts.length - 1].length === 0 ||
    localParts.some((part) => part.length === 0)
  ) {
    return 'Please enter a valid email (e.g. name@company.com)'
  }

  if (
    !EMAIL_REGEX.test(value) ||
    !allTLDs.has(domainParts[domainParts.length - 1])
  ) {
    return 'Email is invalid'
  }
}

export const fullName = (name) => {
  if (!splitName(name).lastName) {
    return 'Please add your last name'
  }
}

export const isPresent = (value) =>
  !(
    isUndefined(value) ||
    isNull(value) ||
    isNaN(value) ||
    (isString(value) && isEmpty(value))
  )

export const confirmEmail = (value, email) => {
  if (isPresent(value) && value !== email) {
    return 'Email address does not match'
  }
}

export function notEmail(value) {
  // Has only one @, at least one character before the @, before the period and after it
  if (EMAIL_REGEX.test(value)) {
    return "Can't be an email address"
  }
}

export const notPoBox = (value) => {
  if (PO_BOX_REGEX.test(value)) {
    return "Can't be a P.O. Box address"
  }
  return false
}

export function integer(value) {
  if (!Number.isInteger(Number(value))) {
    return 'Must be an integer'
  }
}

export function match(field) {
  return (value, data) => {
    if (data) {
      if (value !== data[field]) {
        return 'Do not match'
      }
    }
  }
}

export function maxLength(max) {
  return (value) => {
    if (isPresent(value) && value.length > max) {
      return `Must be no more than ${max} characters`
    }
  }
}

export function maxValue(max, prefix = '') {
  return (value) => {
    if (isPresent(value) && value > max) {
      return `Must not exceed ${prefix}${max}`
    }
  }
}

export function minLength(min) {
  return (value) => {
    if (isPresent(value) && value.length < min) {
      return `Must be at least ${min} characters`
    }
  }
}

export function minValue(min, prefix = '') {
  return (value) => {
    if (isPresent(value) && value < min) {
      return `Must be at least ${prefix}${min}`
    }
  }
}

export function numeric(value) {
  if (isPresent(value) && !isNumeric(value)) {
    return 'Must be a number'
  }
}

export function present(value) {
  if (!isPresent(value)) {
    return 'Must not be left blank'
  }
}

export function notEquals(string1, string2) {
  if (string1 && string2 && string1.toLowerCase() === string2.toLowerCase()) {
    return 'Must not be identical'
  }
}

export function oneOf(enumeration) {
  return (value) => {
    if (enumeration.indexOf(value) !== -1) {
      return `Must be one of: ${enumeration.join(', ')}`
    }
  }
}

export function required(value, data, msg = 'Required') {
  if (isBlank(value)) {
    return msg
  }
}

export function url(value) {
  // NOTE: Does not require protocol!
  if (!isPresent(value) || !URL_REGEX.test(value)) {
    return 'Invalid URL'
  }
}

// https://gist.github.com/symm/7145799
export const bankAccountNumber = (str) => {
  if (!/^\d{1,17}$/.test(str)) return 'Invalid bank account number'
}

// https://gist.github.com/symm/7145799
// ¯\_(ツ)_/¯
export const usRoutingNumber = (str) => {
  let t
  let n
  let r
  let i
  let s
  let o
  let u
  if (!/^\d{9}$/.test(str)) return 'Routing number must have 9 digits'
  s = 0
  // eslint-disable-next-line no-multi-assign
  for (t = o = 0, u = str.length - 1; o <= u; t = o += 3) {
    n = parseInt(str.charAt(t)) * 3
    r = parseInt(str.charAt(t + 1)) * 7
    i = parseInt(str.charAt(t + 2))
    s += n + r + i
  }
  if (s === 0 || s % 10 !== 0) return 'Invalid routing number'
}

export const usZip = (value) => {
  const fiveDigitZip = value.match(/^(\d{5})-?(\d{4})?$/)
  if (!fiveDigitZip) {
    return 'Must be a valid US zip'
  }
}

export const notContainEmoji = (value) => {
  if (isPresent(value) && EMOJI_REGEX.test(value)) {
    return "Can't contain any emoji"
  }
}

export function createValidator(rules) {
  return (data = {}) => {
    let errors = {}
    Object.keys(rules).forEach((key) => {
      const rule = join([].concat(rules[key])) // concat enables both functions and arrays of functions
      const error = rule(get(data, key), data)
      if (error) {
        errors[key] = error
      }
    })
    /*
      errors can potentially have nested obj keys in dot notation (e.g. {'foo.bar.baz': 'required'})
      transform these into POJOs
      {
        foo: {
          bar: {
            baz: 'required'
          }
        }
      }
    */
    errors = transform(errors, (obj, val, key) => set(obj, key, val))
    return errors
  }
}
