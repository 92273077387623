const UpsideDownSmileLight = () => (
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0117 25.8854C21.3052 25.8854 25.6229 21.4748 25.6229 15.9996C25.6229 10.5245 21.3348 6.11392 16.0117 6.11392C10.6887 6.11392 6.40063 10.5245 6.40063 15.9996C6.40063 21.4748 10.7182 25.8854 16.0117 25.8854Z"
      fill="url(#paint0_linear_2003_1158)"
    />
    <path
      d="M16.0001 26.506C10.3665 26.506 5.77783 21.7908 5.77783 15.9917C5.77783 10.1926 10.3665 5.48535 16.0001 5.48535C21.6336 5.48535 26.2223 10.2006 26.2223 15.9996C26.2223 21.7987 21.6336 26.5139 16.0001 26.5139V26.506ZM16.0001 6.27649C10.7829 6.27649 6.53503 10.6357 6.53503 15.9996C6.53503 21.3636 10.7829 25.7228 16.0001 25.7228C21.2172 25.7228 25.4651 21.3636 25.4651 15.9996C25.4651 10.6357 21.2172 6.27649 16.0001 6.27649Z"
      fill="#6E3264"
    />
    <path
      d="M21.7625 16.2253C21.5505 16.2253 21.3839 16.054 21.3839 15.8359C21.3839 14.3873 20.8235 13.0243 19.8089 11.9962C18.7942 10.9682 17.4464 10.3996 16.0077 10.3996C14.5691 10.3996 13.2212 10.9682 12.2066 11.9962C11.1919 13.0243 10.6316 14.3873 10.6316 15.8359C10.6316 16.054 10.465 16.2253 10.253 16.2253C10.041 16.2253 9.87441 16.054 9.87441 15.8359C9.87441 14.177 10.5105 12.6193 11.6765 11.4433C12.8426 10.2672 14.3798 9.6208 16.0153 9.6208C17.6509 9.6208 19.1956 10.2672 20.3465 11.4433C21.505 12.6271 22.1486 14.1848 22.1486 15.8359C22.1486 16.054 21.9821 16.2253 21.77 16.2253H21.7625Z"
      fill="#6E3264"
    />
    <path
      d="M18.983 17.8846C18.4832 17.8846 18.0743 18.2973 18.0743 18.8036C18.0743 19.3098 18.4832 19.7226 18.983 19.7226C19.4827 19.7226 19.8916 19.3098 19.8916 18.8036C19.8916 18.2973 19.4827 17.8846 18.983 17.8846ZM13.0162 17.8846C12.5089 17.8846 12.1076 18.2973 12.1076 18.8036C12.1076 19.3098 12.5165 19.7226 13.0162 19.7226C13.516 19.7226 13.9248 19.3098 13.9248 18.8036C13.9248 18.2973 13.516 17.8846 13.0162 17.8846Z"
      fill="#6E3264"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_2003_1158"
        x1="13.9399"
        x2="18.2831"
        y1="27.994"
        y2="4.04712"
      >
        <stop offset="0.08" stopColor="#FF6D4E" />
        <stop offset="0.77" stopColor="#FED7FF" />
      </linearGradient>
    </defs>
  </svg>
)

export default UpsideDownSmileLight
