import React from 'react'
import classNames from 'classnames'
import styles from './TitleAndCopy.styles.scss'
import { TitleAndCopyProps } from './TitleAndCopy.types'

const TitleAndCopy: React.FC<TitleAndCopyProps> = ({ copy, size, title }) => {
  const wrapperClasses = classNames(
    styles.TitleAndCopy,
    styles[`TitleAndCopy__${size}`]
  )
  const copyClasses = classNames(styles.copy, styles[`copy__${size}`])
  const titleClasses = classNames(styles.title, styles[`title__${size}`])

  return (
    <div className={wrapperClasses} data-testid="TitleAndCopy">
      {title && <span className={titleClasses}>{title}</span>}
      {copy && <span className={copyClasses}>{copy}</span>}
    </div>
  )
}

export default TitleAndCopy
