import React from 'react'

interface IkeaStoreProps {
  className?: string
  rounded?: boolean
}

const IkeaStore: React.FC<IkeaStoreProps> = ({
  className,
  rounded = true,
}: IkeaStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {rounded ? (
      <rect fill="#F6D33C" height="24" rx="12" width="24" />
    ) : (
      <rect fill="#F6D33C" height="24" width="24" />
    )}
    <path
      clipRule="evenodd"
      d="M3 8.82715H21V15.1729H3V8.82715ZM3.18309 12.0153C3.18309 10.4062 7.14414 9.10174 12.0305 9.10174C16.9169 9.10174 20.8779 10.4062 20.8779 12.0153C20.8779 13.6245 16.9169 14.9289 12.0305 14.9289C7.14423 14.9289 3.18309 13.6245 3.18309 12.0153Z"
      fill="#2360A5"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M3.18309 12.0151C3.18309 10.406 7.14414 9.10156 12.0305 9.10156C16.9169 9.10156 20.8779 10.406 20.8779 12.0151C20.8779 13.6243 16.9169 14.9287 12.0305 14.9287C7.14423 14.9287 3.18309 13.6243 3.18309 12.0151Z"
      fill="#F6D33C"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M5.59849 10.814H7.17117C7.17117 10.814 7.12922 10.88 7.12922 10.9625V12.987C7.12922 13.097 7.17637 13.1905 7.17637 13.1905H5.5933C5.5933 13.1905 5.64044 13.0969 5.64044 13.0034V10.9789C5.64044 10.9019 5.59849 10.814 5.59849 10.814ZM7.95414 10.8194H9.44957C9.44957 10.8194 9.40762 10.8898 9.40762 10.955V11.6814L9.93755 11.0037C9.93755 11.0037 9.96911 10.955 9.96911 10.9062V10.8303H11.4907L10.8453 11.7301C10.8453 11.7301 10.8139 11.768 10.8139 11.7952C10.8139 11.8223 10.8401 11.8602 10.8401 11.8602L11.6692 13.1774H9.83778C9.83778 13.1774 9.86934 13.1288 9.86934 13.0799C9.86934 13.0312 9.83778 12.9715 9.83778 12.9715L9.40223 12.2613V13.0312C9.40223 13.0962 9.44947 13.1828 9.44947 13.1828H7.95414C7.95414 13.1828 7.99609 13.1233 7.99609 13.0582V10.9549C7.99609 10.8899 7.95414 10.8194 7.95414 10.8194ZM12.1145 10.8358H14.7895V11.5544C14.7895 11.5544 14.7318 11.5011 14.6586 11.5011H13.5622V11.698H14.5169V12.3314C14.5169 12.3314 14.4539 12.2782 14.3857 12.2782H13.5518V12.4964H14.6742C14.7371 12.4964 14.7739 12.3952 14.7739 12.3952V13.1828H12.1145C12.1145 13.1828 12.1619 13.1137 12.1619 13.0657V11.0115C12.162 10.9263 12.1145 10.8358 12.1145 10.8358ZM15.973 10.8101H18.1118C18.1118 10.8101 18.0699 10.9091 18.0699 10.9641C18.0699 11.0191 18.1065 11.1181 18.1065 11.1181L18.961 13.1867H17.3569C17.3569 13.1867 17.3936 13.1262 17.3725 13.0767C17.3284 12.9669 17.2847 12.8568 17.2416 12.7465H16.5811C16.5811 12.7465 16.4499 13.0381 16.4499 13.0933C16.4499 13.1481 16.4921 13.1923 16.4921 13.1923H15.1709L15.9992 11.0301C15.9992 11.0301 16.0308 10.986 16.0308 10.9311C16.0308 10.8762 15.973 10.8101 15.973 10.8101ZM16.8799 11.8664L16.7226 12.29H17.0686L16.8799 11.8664Z"
      fill="#2360A5"
      fillRule="evenodd"
    />
  </svg>
)

export default IkeaStore
