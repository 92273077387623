import { Offer } from 'src/types/offer'
import { GroupGiftFieldsProps } from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/fields/RegItemFields.types'
import Image from 'components/image'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Textarea } from 'baby-design'
import { RegItem } from 'src/types/regItem'
import { numberToCurrency } from 'lib/money'
import NiceModal from '@ebay/nice-modal-react'
import EditItemDetailsPrivateItemSetting from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/PrivateItem/PrivateItem'
import useSecondhandItemsEnabled from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/hooks/useSecondhandItemsEnabled'
import EditItemDetailsSecondhandItemSetting from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/SecondhandItem/SecondhandItem'
import { EditItemDetailsQuantitySetting } from '../components/QuantitySetting'
import { EditItemDetailsFavoriteSetting } from '../components/Favorite'
import { EditItemDetailsGroupGiftSetting } from '../components/GroupGift'
import { EditItemDetailsBuyingOptions } from '../../BuyingOptionsWithAddButton'

import css from './fields.style.scss'
import { CategoryDropdown } from '../components/CategoryDropdown'
import EditGroupGiftModal from '../../EditGroupGiftModal/EditGroupGiftModal'
import GroupGiftRedemptionModal from '../../GroupGiftRedemptionModal/GroupGiftRedemptionModal'
import EditRegItemDrawer from '../EditRegItemDrawer'
import AddSecondaryLinkDrawer from '../../AddSecondaryLinkDrawer/AddSecondaryLinkDrawer'
import EditSecondaryLinkDrawer from '../../EditSecondaryLinkDrawer/EditSecondaryLinkDrawer'
import RegItemPurchasesSection from './RegItemFields/components/RegItemPurchasesSection'

export function GroupGiftFields({
  regItem,
  reservations,
  categories,
  regItemFields,
  updateRegItemFields,
  handleDelete,
  onRegItemUpdated,
  buyingOptionsProps = {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    unredeemedFundsCallback: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onAddToCartSuccess: () => {},
    toastImageOverride: null,
  },
}: GroupGiftFieldsProps) {
  const isSecondhandItemsEnabled = useSecondhandItemsEnabled()

  const openEditGroupGiftModal = async () => {
    const response: RegItem | undefined = await NiceModal.show(
      EditGroupGiftModal,
      { regItem }
    )
    if (response) {
      NiceModal.show(EditRegItemDrawer, { regItem: response })
    }
  }

  const handleGroupGiftChange = async (isChecked: boolean) => {
    const { fundedAmount, isGroupGift } = regItemFields

    if (fundedAmount > 0 && isGroupGift) {
      const response: RegItem | undefined = await NiceModal.show(
        GroupGiftRedemptionModal,
        {
          regItem,
          action: 'disable-group-gift',
        }
      )
      if (response) {
        NiceModal.show(EditRegItemDrawer, { regItem: response })
      }
    } else {
      updateRegItemFields({ isGroupGift: isChecked, private: false })
    }
  }

  const openAddSecondaryLinkDrawer = async () => {
    const response: RegItem | undefined = await NiceModal.show(
      AddSecondaryLinkDrawer,
      { regItem }
    )
    if (response) {
      NiceModal.show(EditRegItemDrawer, { regItem: response })
    }
  }

  const openEditSecondaryLinkDrawer = async (offer: Offer) => {
    const response: RegItem | undefined = await NiceModal.show(
      EditSecondaryLinkDrawer,
      {
        secondaryOffer: offer,
        regItem,
      }
    )
    if (response) {
      NiceModal.show(EditRegItemDrawer, { regItem: response })
    }
  }

  return (
    <div className={css.fields}>
      <div className={css.fields__item_intro}>
        <Image
          alt={regItemFields.title}
          className={css.fields__item_intro_img}
          src={regItemFields.imgUrl}
        />
        <div className={css.fields__item_intro_info}>
          <div className={css.fields__item_title}>{regItemFields.title}</div>
          {regItemFields.price !== null && (
            <div className={css.fields__item_price}>
              {numberToCurrency(regItemFields.price)}
            </div>
          )}
        </div>
      </div>
      <div className={css.fields__item_details}>
        <div className={css.fields__item_input}>
          <CategoryDropdown
            categories={categories}
            regItemFields={regItemFields}
            updateRegItemFields={updateRegItemFields}
          />
          <Textarea
            label="Notes to friends and family "
            placeholder="Let your gift givers know what size or color you would like"
            size="md"
            value={regItemFields.description || undefined}
            onChange={(e) =>
              updateRegItemFields({ description: e.target.value })
            }
          />
        </div>
        <EditItemDetailsQuantitySetting
          isGroupGift
          handleDelete={handleDelete}
          regItemFields={regItemFields}
          updateRegItemFields={updateRegItemFields}
        />
        <RegItemPurchasesSection
          regItem={regItemFields}
          reservations={reservations}
          onRegItemUpdated={onRegItemUpdated}
        />
        <EditItemDetailsFavoriteSetting
          regItemFields={regItemFields}
          updateRegItemFields={updateRegItemFields}
        />
        {!regItemFields.isGroupGift && (
          <EditItemDetailsPrivateItemSetting
            categories={categories}
            regItemFields={regItemFields}
            updateRegItemFields={updateRegItemFields}
          />
        )}
        {isSecondhandItemsEnabled && (
          <EditItemDetailsSecondhandItemSetting
            regItemFields={regItemFields}
            updateRegItemFields={updateRegItemFields}
          />
        )}
        {regItemFields.showGroupGiftToggle && (
          <EditItemDetailsGroupGiftSetting
            handleGroupGiftChange={handleGroupGiftChange}
            openEditGroupGiftModal={openEditGroupGiftModal}
            regItemFields={regItemFields}
          />
        )}
        <EditItemDetailsBuyingOptions
          {...buyingOptionsProps}
          openAddSecondaryLinkDrawer={openAddSecondaryLinkDrawer}
          openEditSecondaryLinkDrawer={openEditSecondaryLinkDrawer}
          regItem={regItemFields}
          onCartItemUpdateSuccess={onRegItemUpdated}
        />
      </div>
      <div className={css.fields__divider} />
      <div className={css.fields__item_delete}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <a className={css.fields__delete_link} onClick={handleDelete}>
          Delete item
        </a>
      </div>
    </div>
  )
}
