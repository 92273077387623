import { useEffect, useRef, useState } from 'react'
import { includes } from 'lodash'
import Guid from 'guid'
import EmailCallout, { EmailTypes } from 'components/email-callout'
// eslint-disable-next-line import/no-extraneous-dependencies
import { LoadingHeart } from 'baby-design'
import ShareButtons from 'shared/share-buttons'
import {
  babylistUrl,
  helloBabyPostPath,
  healthAdvisoryBoardPath,
  landingPagePath,
} from 'lib/urls'
import { EMAIL_USER_PREGNANCY, EMAIL_USER_NEWSLETTER } from 'shared/constants'
import Link from 'components/link'
import {
  getRedditClickIdCookie,
  getRedditUUIDCookie,
  track,
  useTracking,
} from 'lib/analytics'
import {
  getSearchId,
  GUIDE_SEARCH_INDEX_KEY,
} from 'shared/hooks/useSearchId/useSearchId'
import { getSearchPreviewIds } from 'components/global-nav/components/SearchBar/SearchBar.utils'
import classnames from 'classnames'
import PlacementsInterstitialModal from 'components/modal/PlacementsInterstitialModal'
import { PLACEMENT_INTERSTITIAL_UNIT } from 'lib/placements/units'
import Author from '../Author/Author'
import PostCardRow from '../post-card-row'
import PostLink from '../PostLink'
import css from './PostView.styles.scss'
import PostNavBar from './components/PostNavBar'
import SignUpBanner from './components/SignUpBanner'
import HelloBabyMeta from '../HelloBabyMeta'
import RichTextBody from '../RichTextBody'
import { TableOfContents } from '../TableOfContents/TableOfContents'
import { CollapsibleTableOfContents } from '../TableOfContents/CollapsibleTableOfContents'
import { AboveTheFold } from './components/AboveTheFold/AboveTheFold'

interface PostViewProps {
  minimalView: boolean
  post: Post
  relatedPosts: Post[]
  nextPost: Post | undefined
  previousPost: Post | undefined
  emailUrls: Record<string, string>
}

// eslint-disable-next-line complexity
const PostView: React.FC<PostViewProps> = ({
  minimalView,
  post,
  relatedPosts,
  nextPost,
  previousPost,
  emailUrls,
}: PostViewProps) => {
  const postBody = useRef<HTMLDivElement>(null)
  const tracker = useTracking()

  const [viewTracked, setViewTracked] = useState(false)
  const [selectedHeading, setSelectedHeading] = useState<string | null>(null)

  const postContainer = useRef(null)

  const { locales = [] } = post

  const socialShareDescription = post.meta?.socialDescription
  const socialShareMedia =
    post.meta?.pinterestImageUrl || post.featuredImage?.url
  const { socialShareUrl } = post

  const postSlugsToMarkNoIndex = ['coronavirus-news-and-resources']

  const topicNames = post.meta.topics.map((topic) => topic.name)
  const postId = post.id ? post.id.split('#')[0] : undefined

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const searchId = getSearchId(GUIDE_SEARCH_INDEX_KEY)
    const searchPreviewIds = getSearchPreviewIds()
    if (post && !viewTracked) {
      tracker.trackEvent({
        event: track.guideViewed,
        guideTopics:
          post.meta && post.meta.topics && post.meta.topics.length > 0
            ? topicNames
            : null,
        gtmEventId: Guid.raw(),
        post,
        searchId,
        searchPreviewIds,
        redditClickId: getRedditClickIdCookie(),
        redditUuid: getRedditUUIDCookie(),
      })

      setViewTracked(true)
    }

    if (postBody?.current) {
      const trackGuideClicked = () => {
        tracker.trackEvent({
          event: track.guideClicked,
          eventLocation: track.EventLocation.GUIDE,
          postId,
          postType: post.type,
          postRevision: post.revision,
          postSlug: post.slug,
          storeName: 'Babylist',
        })
      }

      const links = Array.from(postBody.current?.getElementsByTagName('a'))
      links.forEach((element: HTMLElement) => {
        element.addEventListener('click', trackGuideClicked)
      })

      return () => {
        links.forEach((element: HTMLElement) =>
          element.removeEventListener('click', trackGuideClicked)
        )
      }
    }
  }, [post, postId, topicNames, tracker, viewTracked])

  const renderShareButtons = () => {
    if (minimalView || !socialShareUrl) return null
    return (
      <aside>
        <ShareButtons
          className="mbxl"
          description={socialShareDescription}
          media={socialShareMedia}
          shareText="Share"
          url={socialShareUrl}
        />
      </aside>
    )
  }

  const renderPlacementInterstitial = () => {
    if (minimalView) return null
    return <PlacementsInterstitialModal unitId={PLACEMENT_INTERSTITIAL_UNIT} />
  }

  const renderAuthors = () => {
    if (!post.author) return null
    return post.author.map((author) => (
      <aside>
        <Author author={author} key={author.id} />
      </aside>
    ))
  }

  const renderPostLinks = () => {
    if (!previousPost || !nextPost || minimalView) return null
    return (
      <div className="mbxl">
        <div className={css.postLink}>
          <PostLink direction="left" post={previousPost} />
        </div>
        <div className={classnames(css.postLink)}>
          <PostLink direction="right" post={nextPost} />
        </div>
      </div>
    )
  }

  const renderAcquisitionComponents = () => {
    if (minimalView) return null

    const emailType = includes(
      post.meta.topics.map((topic) => topic.slug),
      'pregnancy'
    )
      ? EmailTypes.Pregnancy
      : EmailTypes.Newsletter

    return (
      <>
        <section>
          <h2 className={css.visuallyHidden}>Stay Up-to-Date with Babylist</h2>
          <EmailCallout
            emailType={emailType}
            emailUrlsByWeek={
              emailType === EMAIL_USER_PREGNANCY ? emailUrls : {}
            }
            nextPost={
              emailType === EMAIL_USER_NEWSLETTER ? nextPost : undefined
            }
          />
        </section>

        <aside className="related-posts-container-wrapper">
          <div className="container">
            <h3 className="section-heading text-bold mtn">Related Articles</h3>
            <PostCardRow
              showTopics
              className="mtl"
              columns={4}
              posts={relatedPosts}
            />
          </div>
        </aside>

        <SignUpBanner />
      </>
    )
  }

  if (!post.body) {
    return (
      <div
        style={{ minHeight: '100vh', position: 'relative', marginTop: '11vh' }}
      >
        <LoadingHeart className="center-xy" />
      </div>
    )
  }

  const canonicalUrl = (): string => {
    const urlPattern = /^https?:\/\// // Regex to check if it's a full URL

    if (!post.canonicalUrl) {
      // If canonicalUrl doesn't exist, return the URL generated from the slug
      return babylistUrl(helloBabyPostPath(post.slug))
    }

    if (urlPattern.test(post.canonicalUrl)) {
      // If it's a full URL, return it as is
      return post.canonicalUrl
    }

    // If it's a slug, pass it to helloBabyPostPath and return the result
    return babylistUrl(helloBabyPostPath(post.canonicalUrl))
  }

  return (
    <PostNavBar
      minimalView={minimalView}
      nextPost={nextPost}
      previousPost={previousPost}
      title={post.title}
    >
      <HelloBabyMeta
        articleTags={topicNames}
        canonical={canonicalUrl()}
        keywords={topicNames.join(', ')}
        locales={locales}
        noindex={postSlugsToMarkNoIndex.includes(post.slug) || post.noindex}
        seoDescription={post.meta?.seoDescription || ''}
        seoTitle={post.meta?.seoTitle}
        socialDescription={post.meta?.socialDescription}
        socialImage={post.meta?.socialImageUrl || post.featuredImage?.url}
        socialTitle={post.meta?.socialTitle}
        socialUrl={post.meta?.socialUrl}
        title={post.title}
        type="article"
      />
      <article>
        <AboveTheFold post={post} />
        <div
          className={classnames({
            [css.postTocWrapper]: post.tableOfContents,
          })}
        >
          {post.tableOfContents && post.tableOfContents.headings.length > 1 && (
            <div className={css.toc}>
              <TableOfContents
                headings={post.tableOfContents.headings}
                selectedHeading={selectedHeading}
                setSelectedHeading={setSelectedHeading}
                tocInStickyHeader={false}
              />
            </div>
          )}
          <div className={css.post}>
            <div ref={postContainer}>
              <div className={css.wrapper}>
                {post.tableOfContents &&
                  post.tableOfContents.headings.length > 1 && (
                    <div className={css.collapsibleToc}>
                      <CollapsibleTableOfContents
                        headings={post.tableOfContents.headings}
                        selectedHeading={selectedHeading}
                        setSelectedHeading={setSelectedHeading}
                      />
                    </div>
                  )}
                <div className={classnames(css.body)}>
                  {post.richTextBody ? (
                    <div
                      className={css.legacy_markdown_widget_styles}
                      ref={postBody}
                    >
                      <RichTextBody
                        richTextBody={post.richTextBody}
                        serverData={post.serverData}
                      />
                    </div>
                  ) : (
                    <div
                      className={classnames(
                        css.guides_markdown_rebrand,
                        css.legacy_markdown_widget_styles
                      )}
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: post.body }}
                      ref={postBody}
                    />
                  )}

                  {renderShareButtons()}
                  {renderAuthors()}

                  <aside>
                    <h3 className={css.visuallyHidden}>Disclaimers</h3>
                    <small className={css.disclaimer__rebrand}>
                      This information is provided for educational and
                      entertainment purposes only. We do not accept any
                      responsibility for any liability, loss or risk, personal
                      or otherwise, incurred as a consequence, directly or
                      indirectly, from any information or advice contained here.
                      Babylist may earn compensation from affiliate links in
                      this content. Learn more about{` `}
                      <Link
                        url={landingPagePath('editorial-policies-guidelines')}
                      >
                        how we write Babylist content
                      </Link>{' '}
                      and{` `}
                      <Link url={landingPagePath('product-review-processes')}>
                        review products
                      </Link>
                      , as well as the{' '}
                      <Link url={healthAdvisoryBoardPath}>
                        Babylist Health Advisory Board
                      </Link>
                      .
                    </small>
                  </aside>
                </div>
              </div>

              {renderPostLinks()}
            </div>
          </div>
        </div>
      </article>

      {renderAcquisitionComponents()}
      {renderPlacementInterstitial()}
    </PostNavBar>
  )
}

export default PostView
