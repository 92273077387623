// eslint-disable-next-line import/no-extraneous-dependencies
import { Button } from 'baby-design'
import ScrollLockModal from 'shared/ScrollLockModal'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteRegItem } from 'src/api/queries'
import { errorMessage } from 'lib/flash-message'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { showItemDeletedToast } from 'src/routes/(registry)/list/[slug]/components/ConfirmRegItemDeleteModal/components/ItemDeletedToast/showItemDeletedToast'
import { RegItem } from '../../../../../../types/regItem'
import css from './ConfirmRegItemDeleteModal.styles.scss'
import {
  invalidateRegItemsQuery,
  optimisticallyDeleteRegItem,
} from '../../utils/registryHelpers'

interface ConfirmRegItemDeleteModalProps {
  regItem: RegItem
}

export default NiceModal.create(
  ({ regItem }: ConfirmRegItemDeleteModalProps) => {
    const modal = useModal()

    const handleClose = () => {
      if (!deleteRequestIsPending) {
        modal.resolve(false)
        modal.remove()
      }
    }

    const queryClient = useQueryClient()
    const { mutate: deleteRegItemMutation, isLoading: deleteRequestIsPending } =
      useMutation({
        mutationFn: (itemId: number) => {
          optimisticallyDeleteRegItem({
            queryClient,
            deletedRegItem: regItem,
          })

          return deleteRegItem(regItem.registry.id, itemId)
        },
        onSuccess: () => {
          invalidateRegItemsQuery({
            queryClient,
            registryId: regItem.registry.id,
          })

          showItemDeletedToast()

          modal.resolve(true)
          modal.remove()
        },
        onError: (error: any) => {
          console.error(error)
          errorMessage(
            error?.error?.message ||
              'Failed to delete. Try refreshing the page. If the problem persists, contact support.'
          )
        },
      })

    const handleConfirmClick = () => {
      deleteRegItemMutation(regItem.id)
    }

    const primaryButton = (
      <Button
        loading={deleteRequestIsPending}
        size="md"
        variant="destructive"
        onClick={handleConfirmClick}
      >
        Delete Item
      </Button>
    )

    const cancelButton = (
      <Button size="md" variant="secondary" onClick={handleClose}>
        Cancel
      </Button>
    )

    return (
      <ScrollLockModal
        handleClose={handleClose}
        isOpen={modal.visible}
        primaryButton={primaryButton}
        secondaryButton={cancelButton}
      >
        <div className={css.ConfirmRegItemDeleteModal__contentContainer}>
          <h2 className={css.ConfirmRegItemDeleteModal__heading}>
            Are you sure?
          </h2>
          <p className={css.ConfirmRegItemDeleteModal__confirmationMessage}>
            Are you sure you want to delete <b>{regItem.title}</b> from your
            registry?
          </p>
        </div>
      </ScrollLockModal>
    )
  }
)
