import React from 'react'

interface SpearmintLoveStoreProps {
  className?: string
  rounded?: boolean
}

const SpearmintLoveStore: React.FC<SpearmintLoveStoreProps> = ({
  className,
  rounded = true,
}: SpearmintLoveStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3733_38)">
      {rounded ? (
        <rect fill="#FAFAFA" height="24" rx="12" width="24" />
      ) : (
        <rect fill="#FAFAFA" height="24" width="24" />
      )}
      <g clipPath="url(#clip1_3733_38)">
        <path
          d="M12.044 8.35639C12.1866 8.06297 12.3127 7.78875 12.4525 7.52001C13.341 5.81709 14.7697 4.92586 16.6646 5.00539C18.4937 5.07943 19.8813 6.00081 20.6079 7.72568C21.3593 9.50264 21.0165 11.1699 19.7249 12.5547C17.235 15.2257 14.6738 17.8335 12.0659 20.5483C11.8191 20.3564 11.5641 20.2056 11.3666 19.9999C9.12074 17.658 6.90227 15.2942 4.64268 12.9661C3.18107 11.4551 2.52293 9.75767 3.38673 7.7339C4.13811 5.97339 5.56132 4.9999 7.45895 5.00539C9.58417 5.01361 11.0759 6.08857 11.7999 8.1425C11.8218 8.20831 11.9178 8.24944 12.0467 8.35639H12.044Z"
          fill="black"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3733_38">
        <rect fill="white" height="24" width="24" />
      </clipPath>
      <clipPath id="clip1_3733_38">
        <rect
          fill="white"
          height="15.5457"
          transform="translate(3 5)"
          width="18"
        />
      </clipPath>
    </defs>
  </svg>
)

export default SpearmintLoveStore
