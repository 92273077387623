import React from 'react'

interface LaloStoreProps {
  className?: string
  rounded?: boolean
}

const LaloStore: React.FC<LaloStoreProps> = ({
  className,
  rounded = true,
}: LaloStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {rounded ? (
      <rect fill="#FAFAFA" height="24" rx="12" width="24" />
    ) : (
      <rect fill="#FAFAFA" height="24" width="24" />
    )}
    <path
      clipRule="evenodd"
      d="M9.77807 13.8156C9.53594 14.0577 9.19411 14.1717 8.73834 14.1717C7.42799 14.1717 6.35977 13.1747 6.35977 11.8786C6.35977 10.5825 7.42799 9.58546 8.73834 9.58546C9.19411 9.58546 9.53594 9.71364 9.77807 9.94153V9.7991C9.77807 9.62819 9.90625 9.5 10.0772 9.5H10.5187C10.6896 9.5 10.8178 9.62819 10.8178 9.7991V13.958C10.8178 14.1289 10.6896 14.2571 10.5187 14.2571H10.0772C9.90625 14.2571 9.77807 14.1289 9.77807 13.958V13.8156ZM16.7428 11.8786C16.7428 10.5682 17.811 9.5 19.1214 9.5C20.4317 9.5 21.5 10.5682 21.5 11.8786C21.5 13.1889 20.4317 14.2571 19.1214 14.2571C17.811 14.2571 16.7428 13.1889 16.7428 11.8786ZM17.7826 11.8786C17.7826 12.6192 18.3808 13.2174 19.1214 13.2174C19.862 13.2174 20.4602 12.6192 20.4602 11.8786C20.4602 11.1379 19.862 10.5397 19.1214 10.5397C18.3808 10.5397 17.7826 11.1379 17.7826 11.8786ZM7.3995 11.8786C7.3995 12.6192 7.99771 13.2174 8.73834 13.2174C9.47896 13.2174 10.0772 12.6192 10.0772 11.8786C10.0772 11.1379 9.47896 10.5397 8.73834 10.5397C7.99771 10.5397 7.3995 11.1379 7.3995 11.8786ZM3.53968 13.2174H4.57941C4.75033 13.2174 4.87852 13.3456 4.87852 13.5165V13.958C4.87852 14.1289 4.75033 14.2571 4.57941 14.2571H2.79906C2.62814 14.2571 2.49995 14.1289 2.49995 13.958V9.7991C2.49995 9.62819 2.62814 9.5 2.79906 9.5H3.24058C3.4115 9.5 3.53968 9.62819 3.53968 9.7991V13.2174ZM13.9227 13.2174H14.9625C15.1334 13.2174 15.2616 13.3456 15.2616 13.5165V13.958C15.2616 14.1289 15.1334 14.2571 14.9625 14.2571H13.1821C13.0112 14.2571 12.883 14.1289 12.883 13.958V9.7991C12.883 9.62819 13.0112 9.5 13.1821 9.5H13.6236C13.7946 9.5 13.9227 9.62819 13.9227 9.7991V13.2174Z"
      fill="#15244B"
      fillRule="evenodd"
    />
  </svg>
)

export default LaloStore
