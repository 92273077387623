import classnames from 'classnames'
import { CloseIcon } from 'components/icons'
import css from './styles.scss'

interface CloseButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

function CloseButton({ onClick }: CloseButtonProps) {
  return (
    <div className={css.closeButtonSpacing}>
      <button
        aria-label="Close"
        className={classnames(
          'btn-unstyled flex justify-center align-center',
          css.closeButton,
          css.closeButtonRect
        )}
        type="button"
        onClick={onClick}
      >
        <CloseIcon height="16" />
      </button>
    </div>
  )
}

interface Composable {
  /**
   * Allows you to use the given component as the root renderer.
   * PS: Must be a valid react element (jsx).
   *
   * @default undefined
   */
  Component?: React.ReactElement
}

export interface DismissibleBannerProps
  extends Composable,
    React.HTMLAttributes<HTMLDivElement> {
  /**
   * If provided, it will render the close button and fire this callback.
   *
   * @default undefined
   */
  onClose?: () => void
}

export default function DismissibleBanner({
  Component,
  children,
  className,
  onClose,
  ...rest
}: DismissibleBannerProps) {
  const closeButtonPlaceHolder = (
    <div className={classnames(css.closeButtonSpacing, css.closeButtonRect)} />
  )

  return (
    <div
      // NOTE: This assumes some styling that is separate from the dismissable
      // functionality, may want to separate.
      className={classnames(css.contentWrapper, css.miamiSunsetBg, className)}
      {...rest}
    >
      <div className="flex space-between">
        {onClose && closeButtonPlaceHolder}
        {children}
        {onClose && <CloseButton onClick={onClose} />}
      </div>
    </div>
  )
}
