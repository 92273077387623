import React from 'react'

interface GoogleLogoProps {
  dangerouslyOverrideClassName?: string
}

const GoogleLogo: React.FC<GoogleLogoProps> = ({
  dangerouslyOverrideClassName,
}: GoogleLogoProps) => (
  <svg
    className={dangerouslyOverrideClassName}
    fill="none"
    height="18"
    viewBox="0 0 17 18"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4350_18583)">
      <path
        clipRule="evenodd"
        d="M16.8332 9.18521C16.8332 8.50001 16.7764 8.00001 16.6537 7.48151H8.66992V10.5741H13.3562C13.2618 11.3426 12.7516 12.5 11.6177 13.2778L11.6019 13.3813L14.1261 15.2978L14.301 15.3149C15.9072 13.8612 16.8332 11.7223 16.8332 9.18521Z"
        fill="#4285F4"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M8.67064 17.3334C10.9665 17.3334 12.8939 16.5926 14.3018 15.3148L11.6184 13.2777C10.9004 13.7685 9.93666 14.1111 8.67064 14.1111C6.42197 14.1111 4.51345 12.6574 3.83309 10.6482L3.7334 10.6565L1.10856 12.6472L1.07422 12.7407C2.47253 15.4629 5.34483 17.3334 8.67064 17.3334Z"
        fill="#34A853"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M3.83281 10.6482C3.65332 10.1297 3.54939 9.57409 3.54939 9.00004C3.54939 8.42594 3.65326 7.87039 3.82337 7.35189L3.81862 7.24144L1.16092 5.21869L1.07398 5.25924C0.497655 6.38889 0.166992 7.65739 0.166992 8.99999C0.166992 10.3426 0.497706 11.611 1.07398 12.7407L3.83286 10.6481"
        fill="#FBBC04"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M8.67064 3.8889C10.2674 3.8889 11.3444 4.56485 11.9586 5.1297L14.3584 2.8334C12.8846 1.4908 10.9665 0.666748 8.67064 0.666748C5.34483 0.666748 2.47258 2.5371 1.07422 5.2593L3.82366 7.35195C4.51345 5.3427 6.42197 3.88895 8.67064 3.88895"
        fill="#EA4335"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_4350_18583">
        <rect
          fill="white"
          height="16.6667"
          transform="translate(0.166992 0.666687)"
          width="16.6667"
        />
      </clipPath>
    </defs>
  </svg>
)

export default GoogleLogo
