import { useState } from 'react'
import { Cart } from 'src/types/cart'
// eslint-disable-next-line import/no-extraneous-dependencies
import { TextLink } from 'baby-design'
import { DiscountItem } from '../DiscountItem/DiscountItem'
import css from './CompactSummary.styles.scss'

interface CompactCartSummaryProps {
  checkoutTotals: Cart['checkoutTotals']
}

export const CompactCartSummary = ({
  checkoutTotals,
}: CompactCartSummaryProps) => {
  const [showEstimatedTotalHint, setShowEstimatedTotalHint] = useState(false)
  return (
    <div>
      {parseFloat(checkoutTotals.saleTotalAmountOff) > 0 && (
        <DiscountItem
          isCompact
          amount={checkoutTotals.saleTotalAmountOff}
          label="Babylist Shop sale discount"
        />
      )}
      {parseFloat(checkoutTotals.registryDiscount) > 0 && (
        <DiscountItem
          isCompact
          amount={checkoutTotals.registryDiscount}
          label="15% discount"
        />
      )}
      <div className={css.CompactCartSummary__item}>
        <TextLink
          className={css.CompactCartSummary__estimatedTotalHint}
          role="button"
          size="lg"
          weight="regular"
          onClick={() => setShowEstimatedTotalHint(!showEstimatedTotalHint)}
        >
          Estimated total
        </TextLink>
        <span>${checkoutTotals.total}</span>
      </div>
      {showEstimatedTotalHint && (
        <div className={css.CompactCartSummary__hintCopy}>
          <p>
            Prices vary by store, and do not include applicable tax or shipping.
          </p>
        </div>
      )}
    </div>
  )
}
