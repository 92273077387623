import React from 'react'
import classNames from 'classnames'

import css from './PricingTreatment.styles.scss'
import { PricingTreatmentProps } from './PricingTreatment.types'
import {
  displayPrice,
  displayStrikePrice,
  formatPrice,
  shouldDisplayPriceRange,
  shouldDisplayStrikePrice,
} from './PricingTreatment.utils'

const PricingTreatment: React.FC<PricingTreatmentProps> = (props) => {
  const {
    price,
    priceRange,
    decimalPlaces = 2,
    strikePrice,
    size = 'md',
    weightVariant = 'default',
    saleVariant = 'default',
  } = props

  const PricingTreatmentClasses = classNames(css.PricingTreatment, {
    [css.PricingTreatment__sm]: size === 'sm',
    [css.PricingTreatment__md]: size === 'md',
    [css.PricingTreatment__lg]: size === 'lg',
    [css.PricingTreatment__bold]: weightVariant === 'bold',
    [css.PricingTreatment__sale]: saleVariant === 'red',
  })

  const isPriceRangeVisible = shouldDisplayPriceRange(priceRange)
  const isStrikePriceVisible = shouldDisplayStrikePrice(
    price,
    strikePrice,
    priceRange
  )

  const formattedMinPrice = formatPrice(priceRange?.min || 0, decimalPlaces)
  const formattedMaxPrice = formatPrice(priceRange?.max || 0, decimalPlaces)
  const formattedDisplayPrice = displayPrice(price || 0, decimalPlaces)
  const formattedStrikePrice = displayStrikePrice(
    strikePrice || 0,
    decimalPlaces
  )

  return (
    <div className={PricingTreatmentClasses} data-testid="PricingTreatment">
      {isPriceRangeVisible && (
        <>
          <span className={css.PricingTreatment__VisuallyHidden}>
            {`From ${formattedMinPrice} to ${formattedMaxPrice}`}
          </span>
          <span aria-hidden="true" className={css.PricingTreatment__PriceRange}>
            {formattedMinPrice}
            <span className={css.PricingTreatment__Dash}>-</span>
            {formattedMaxPrice}
          </span>
        </>
      )}
      {!isPriceRangeVisible && (
        <>
          <span
            aria-hidden={isStrikePriceVisible ? 'true' : 'false'}
            className={css.PricingTreatment__Price}
          >
            {formattedDisplayPrice}
          </span>
          {isStrikePriceVisible && (
            <>
              {' '}
              <span
                aria-hidden="true"
                className={css.PricingTreatment__StrikePrice}
              >
                {formattedStrikePrice}
              </span>
              <span className={css.PricingTreatment__VisuallyHidden}>
                {`${formattedDisplayPrice} Was ${formattedStrikePrice}`}
              </span>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default PricingTreatment
