/* eslint-disable complexity */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

import { RangeSliderProps } from './RangeSlider.types'
import TextInput from '../input/TextInput/TextInput'
import { Minus, ThumbKnob } from '../../icons'

import css from './RangeSlider.styles.scss'
import {
  clampValue,
  cleanString,
  compileInputValue,
  enforceStep,
} from './RangeSlider.utils'

const RangeSlider: React.FC<RangeSliderProps> = ({
  className,
  lowerBound,
  openEndedMax = false,
  step = 1,
  type = 'number',
  upperBound,
  values = { lesser: undefined, greater: undefined },
  onChange,
}) => {
  const [lesserKnobIsDragging, setLesserKnobIsDragging] = useState(false)
  const [greaterKnobIsDragging, setGreaterKnobIsDragging] = useState(false)
  const [justStoppedDragging, setJustStoppedDragging] = useState(false)
  const [shouldReportValues, setShouldReportValues] = useState(false)
  const [lesserInputIsFocused, setLesserInputIsFocused] = useState(false)
  const [greaterInputIsFocused, setGreaterInputIsFocused] = useState(false)
  const [hasCompletedInitialUpdate, setHasCompletedInitialUpdate] =
    useState(false)
  const [internalLesserValue, setInternalLesserValue] = useState<number | null>(
    values.lesser === undefined ? lowerBound : values.lesser
  )
  const [internalGreaterValue, setInternalGreaterValue] = useState<
    number | null
  >(values.greater === undefined ? upperBound : values.greater)
  const [knobsAndFillRequireUpdate, setKnobsAndFillRequireUpdate] =
    useState(false)
  const track = useRef<HTMLDivElement>(null)
  const lesserKnob = useRef<HTMLDivElement>(null)
  const greaterKnob = useRef<HTMLDivElement>(null)
  const trackFill = useRef<HTMLDivElement>(null)

  // Update the position of the knobs and fill based on the values
  const updateKnobsAndFill = useCallback(() => {
    if (
      lesserKnob.current &&
      greaterKnob.current &&
      trackFill.current &&
      track.current
    ) {
      const range = upperBound - lowerBound
      const lesserValuePercentage =
        ((internalLesserValue === null ? lowerBound : internalLesserValue) -
          lowerBound) /
        range
      const greaterValuePercentage =
        ((internalGreaterValue === null ? upperBound : internalGreaterValue) -
          lowerBound) /
        range
      const trackWidth = track.current.offsetWidth
      const lesserKnobPosition = trackWidth * lesserValuePercentage
      const greaterKnobPosition = trackWidth * greaterValuePercentage
      // Push the knobs to line up with the edge of the control
      const halfKnobWidth = lesserKnob.current.offsetWidth / 2
      lesserKnob.current.style.left = `${lesserKnobPosition - halfKnobWidth}px`
      greaterKnob.current.style.right = `${trackWidth - greaterKnobPosition - halfKnobWidth}px`
      trackFill.current.style.left = `${lesserKnobPosition}px`
      trackFill.current.style.right = `${trackWidth - greaterKnobPosition}px`

      // For some mysterious reason, doing this prevents the trackFill from transitioning
      // its width when updating for the first time with initial values ¯\_(ツ)_/¯
      trackFill.current.getBoundingClientRect()
    }
  }, [internalGreaterValue, internalLesserValue, upperBound, lowerBound])

  // Determine how far apart the mouse is from the left side of the track
  // Use that distance and the width of the track to determine the value
  const getValueFromOffsetFromTrack = useCallback(
    (currentPosition: number) => {
      const trackWidth = (track.current as HTMLDivElement).offsetWidth
      const trackLeft = (
        track.current as HTMLDivElement
      ).getBoundingClientRect().left
      const positionRelativeToTrack = currentPosition - trackLeft
      const valuePercentage = positionRelativeToTrack / trackWidth
      const range = upperBound - lowerBound
      return lowerBound + range * valuePercentage
    },
    [upperBound, lowerBound]
  )

  // Clamp internalLesserValue below internalGreaterValue and above lowerBound
  const clampLesserValue = useCallback(
    (value: number) =>
      clampValue(value, lowerBound, (internalGreaterValue as number) - step),
    [lowerBound, internalGreaterValue, step]
  )

  // Clamp internalGreaterValue above internalLesserValue and below upperBound
  const clampGreaterValue = useCallback(
    (value: number) =>
      clampValue(value, (internalLesserValue as number) + step, upperBound),
    [internalLesserValue, upperBound, step]
  )

  // Initial positioning of knobs and fill
  useEffect(() => {
    setKnobsAndFillRequireUpdate(true)
  }, [])

  // Update lesser and greater values when values prop changes
  useEffect(() => {
    // This will likely be attempted every time we report values
    if (values.lesser !== undefined && internalLesserValue !== values.lesser) {
      setInternalLesserValue(clampLesserValue(values.lesser))
      setKnobsAndFillRequireUpdate(true)
    }
    if (
      values.greater !== undefined &&
      internalGreaterValue !== values.greater
    ) {
      setInternalGreaterValue(clampGreaterValue(values.greater))
      setKnobsAndFillRequireUpdate(true)
    }
    // We only want this to happen on values change, nothing else
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.lesser, values.greater])

  // Update knobs and fill _after_ value changes are applied to state
  useEffect(() => {
    if (knobsAndFillRequireUpdate) {
      updateKnobsAndFill()
      setKnobsAndFillRequireUpdate(false)
      if (!hasCompletedInitialUpdate) {
        setHasCompletedInitialUpdate(true)
      }
    }
  }, [knobsAndFillRequireUpdate, updateKnobsAndFill, hasCompletedInitialUpdate])

  // Call onChange with values
  useEffect(() => {
    if (shouldReportValues) {
      // Values are always numbers when not focused
      onChange(internalLesserValue as number, internalGreaterValue as number)
      setShouldReportValues(false)
    }
  }, [shouldReportValues, internalGreaterValue, internalLesserValue, onChange])

  // START DRAG HANDLERS
  // Handle drag stop here to prevent repeatedly replacing the event listeners
  useEffect(() => {
    if (justStoppedDragging) {
      setInternalLesserValue(enforceStep(internalLesserValue as number, step))
      setInternalGreaterValue(enforceStep(internalGreaterValue as number, step))
      setKnobsAndFillRequireUpdate(true)
      setShouldReportValues(true)
      setJustStoppedDragging(false)
    }
  }, [justStoppedDragging, internalLesserValue, internalGreaterValue, step])

  // Handle lesser knob dragging
  useEffect(() => {
    // Only sets these event listeners once for each drag
    if (lesserKnobIsDragging) {
      const handleUserMove = (e: MouseEvent | TouchEvent) => {
        const newLesserValue = getValueFromOffsetFromTrack(
          'clientX' in e ? e.clientX : e.touches[0].clientX
        )
        const clampedValue = clampLesserValue(newLesserValue)

        setInternalLesserValue(clampedValue)
        setKnobsAndFillRequireUpdate(true)
      }

      const dragEnd = () => {
        setLesserKnobIsDragging(false)
        setJustStoppedDragging(true)
      }

      document.addEventListener('mousemove', handleUserMove)
      document.addEventListener('touchmove', handleUserMove)
      document.addEventListener('mouseup', dragEnd)
      document.addEventListener('touchend', dragEnd)

      return () => {
        document.removeEventListener('mousemove', handleUserMove)
        document.removeEventListener('touchmove', handleUserMove)
        document.removeEventListener('mouseup', dragEnd)
        document.removeEventListener('touchend', dragEnd)
      }
    }
    return () => null
  }, [lesserKnobIsDragging, getValueFromOffsetFromTrack, clampLesserValue])

  // Handle greaterKnob dragging
  useEffect(() => {
    // Only sets these event listeners once for each drag
    if (greaterKnobIsDragging) {
      const handleUserMove = (e: MouseEvent | TouchEvent) => {
        const newGreaterValue = getValueFromOffsetFromTrack(
          'clientX' in e ? e.clientX : e.touches[0].clientX
        )
        const clampedValue = clampGreaterValue(newGreaterValue)

        setInternalGreaterValue(clampedValue)
        setKnobsAndFillRequireUpdate(true)
      }

      const dragEnd = () => {
        setGreaterKnobIsDragging(false)
        setJustStoppedDragging(true)
      }

      document.addEventListener('mousemove', handleUserMove)
      document.addEventListener('touchmove', handleUserMove)
      document.addEventListener('mouseup', dragEnd)
      document.addEventListener('touchend', dragEnd)

      return () => {
        document.removeEventListener('mousemove', handleUserMove)
        document.removeEventListener('touchmove', handleUserMove)
        document.removeEventListener('mouseup', dragEnd)
        document.removeEventListener('touchend', dragEnd)
      }
    }

    return () => null
  }, [greaterKnobIsDragging, getValueFromOffsetFromTrack, clampGreaterValue])
  // END DRAG HANDLERS

  // START INPUT CONTROLS
  const handleInputBlur = () => {
    if (lesserInputIsFocused) {
      const newValue =
        internalLesserValue === null ? lowerBound : internalLesserValue
      setInternalLesserValue(enforceStep(clampLesserValue(newValue), step))
      setLesserInputIsFocused(false)
    }
    if (greaterInputIsFocused) {
      const newValue =
        internalGreaterValue === null ? upperBound : internalGreaterValue
      setInternalGreaterValue(enforceStep(clampGreaterValue(newValue), step))
      setGreaterInputIsFocused(false)
    }
    setKnobsAndFillRequireUpdate(true)
    setShouldReportValues(true)
  }

  const handleInputChange = (
    value: string,
    callback: (parsedValue: number | null) => void
  ) => {
    const cleanedString = cleanString(value)
    if (cleanedString.length === 0) {
      callback(null)
      return
    }
    const parsedValue = parseInt(cleanedString)
    if (Number.isNaN(parsedValue)) return

    callback(parsedValue)
  }

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur()
    }
  }
  // END INPUT CONTROLS

  // START RENDER CALCULATIONS
  const showDollarSign = type === 'currency'
  const showPercentageSign = type === 'percentage'

  const lesserInputValue = compileInputValue({
    value: internalLesserValue,
    showDollarSign,
    roundToStep: lesserKnobIsDragging,
    step,
    showPercentageSign: showPercentageSign && !lesserInputIsFocused,
    showPlus: false,
  })

  const greaterInputValue = compileInputValue({
    value: internalGreaterValue,
    showDollarSign,
    roundToStep: greaterKnobIsDragging,
    step,
    showPercentageSign: showPercentageSign && !greaterInputIsFocused,
    showPlus:
      openEndedMax &&
      internalGreaterValue === upperBound &&
      !greaterInputIsFocused,
  })

  const RangeSliderClasses = classNames(css.RangeSlider, className)
  // END RENDER CALCULATIONS

  return (
    <div className={RangeSliderClasses}>
      <div className={css.RangeSlider__trackContainer}>
        <div className={css.RangeSlider__trackEmpty} />
        <div className={css.RangeSlider__track} data-testid="track" ref={track}>
          <div
            className={classNames(css.RangeSlider__trackFill, {
              [css['RangeSlider__trackFill--noTransition']]:
                !hasCompletedInitialUpdate ||
                lesserKnobIsDragging ||
                greaterKnobIsDragging,
            })}
            data-testid="trackFill"
            ref={trackFill}
          />
          <div
            aria-hidden="true"
            className={classNames(css.RangeSlider__lesserKnobContainer, {
              [css['RangeSlider__lesserKnobContainer--noTransition']]:
                !hasCompletedInitialUpdate || lesserKnobIsDragging,
            })}
            data-testid="lesserKnob"
            ref={lesserKnob}
            onMouseDown={() => setLesserKnobIsDragging(true)}
            onTouchStart={() => setLesserKnobIsDragging(true)}
          >
            <ThumbKnob className={css.RangeSlider__lesserKnob} />
          </div>
          <div
            aria-hidden="true"
            className={classNames(css.RangeSlider__greaterKnobContainer, {
              [css['RangeSlider__greaterKnobContainer--noTransition']]:
                !hasCompletedInitialUpdate || greaterKnobIsDragging,
            })}
            data-testid="greaterKnob"
            ref={greaterKnob}
            onMouseDown={() => setGreaterKnobIsDragging(true)}
            onTouchStart={() => setGreaterKnobIsDragging(true)}
          >
            <ThumbKnob className={css.RangeSlider__greaterKnob} />
          </div>
        </div>
      </div>
      <div className={css.RangeSlider__inputsContainer}>
        <TextInput
          className={css.RangeSlider__lesserInput}
          inputProps={{
            'aria-label': 'Lesser Value',
            'data-testid': 'lesserInput',
            id: 'lesserInput',
            value: lesserInputValue,
            onBlur: handleInputBlur,
            onChange: (e) =>
              handleInputChange(e.target.value, setInternalLesserValue),
            onFocus: () => setLesserInputIsFocused(true),
            onKeyDown: handleInputKeyDown,
          }}
          size="md"
        />
        <div aria-hidden="true" className={css.RangeSlider__separator}>
          <Minus className={css.RangeSlider__separatorIcon} />
        </div>
        <TextInput
          className={css.RangeSlider__greaterInput}
          inputProps={{
            'aria-label': 'Greater Value',
            'data-testid': 'greaterInput',
            id: 'greaterInput',
            value: greaterInputValue,
            onBlur: handleInputBlur,
            onChange: (e) =>
              handleInputChange(e.target.value, setInternalGreaterValue),
            onFocus: () => setGreaterInputIsFocused(true),
            onKeyDown: handleInputKeyDown,
          }}
          size="md"
        />
      </div>
    </div>
  )
}

export default RangeSlider
