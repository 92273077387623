export interface AdContent {
  title: string
  body?: string
  ctaUrl?: string
  ctaText?: string
  imageUrl?: string
  secondaryCtaUrl?: string
  secondaryCtaText?: string
}

export interface AdTracking {
  impressionUrl?: string
}

export interface PlacementAd {
  adId: string
  contentType: string
  content: AdContent
  tracking?: AdTracking
  screens?: string[]
}

export const filteredAds = (
  ads: PlacementAd[] | undefined,
  currentScreen: string
) => {
  if (!ads || ads.length === 0) {
    return []
  }
  return ads.filter((ad) => {
    if (!ad.screens || ad.screens.length === 0) {
      return true
    }
    return ad.screens.some((screen: string) => screen === currentScreen)
  })
}
