export const ADD_ARRIVAL_DATE = 'When is this little bean arriving?'
export const ADD_BABYLIST_BUTTON = 'Install the Babylist button'
export const ADD_BABYLIST_BUTTON_EVENT = 'added_babylist_button'
export const ADD_CASH_FUND = 'Add a Cash Fund'
export const ADD_FAMILY_DETAILS = 'Anyone else along for the ride?'
export const ADD_FROM_BABYLIST_STORE = 'Add items from the Babylist Shop'
export const ADD_SHIPPING_ADDRESS = 'Where would you like your gifts sent?'
export const ADD_SHOWER_INFO = 'Shower, sprinkle, or just a list?'
export const CONFIRM_SHIPPING_ADDRESS = 'Check your address'
export const CREATE_ACCOUNT = 'Create account'
export const HELLO_BABY_BOX = 'Hello Baby Box'
export const INSTALL_BABYLIST_APP = 'Babylist App'
export const INSTALL_BABYLIST_EXTENSION = 'Install the Babylist extension'
export const INSTALLED_BABYLIST_APP_EVENT = 'installed_babylist_app'
export const INSTALLED_BABYLIST_EXTENSION_EVENT = 'installed_babylist_extension'
export const INTERNATIONAL_USER = 'Outside the United States?'
export const ORDER_INSERT_CARDS = 'Order free insert cards'
export const PERSONALIZE_YOUR_REGISTRY = 'Personalize your registry'
export const PURCHASE_FROM_BABYLIST_STORE =
  'Complete a purchase from the Babylist Shop'
export const RECOMMEND_SHIPPING_ADDRESS = 'Select an address'
export const REG_ITEM_PLACEHOLDER_TEXT =
  'Help your gift-givers by adding a note. For example, what size or color would you like?'
export const REGISTRY_DISCOUNT = 'Registry Discount'
export const PRIVACY_SETTINGS = 'Visibility and Privacy settings'
export const PRODUCT_OFFERS = 'Buying Options'
export const CHECKLIST = 'Checklist'
export const HOME = 'Home'
export const REGISTRY = 'Registry'
export const SHOP_REGISTRY = 'Shop Registry'
export const GIFT_TRACKER = 'Gift Tracker'
export const GOAL_STATES = {
  INCOMPLETE: 'incomplete',
  COMPLETE: 'completed',
}
export const FEED_STORES_DATA_API_URL = '/api/v3/stores'
export const FEED_BRANDS_DATA_API_URL = '/api/v3/brands/add_items'
export const BOOKMARKLET_URL = '/api/v3/bookmarklet'
export const LEFT_NAV_ADD_ITEM_SCREEN_VIEW_EVENT = 'Home - Left Rail'
export const RIGHT_NAV_ADD_ITEM_SCREEN_VIEW_EVENT = 'Home - Right Rail'
export const REGISTRY_ADD_ITEM_SCREEN_VIEW_EVENT = 'Registry'
export const BIRTHDAY_REGISTRY_TYPE = 'birthday'
export const HOLIDAY_REGISTRY_TYPE = 'holiday'
export const BABY_REGISTRY_TYPE = 'baby_registry'
export const FAVORITE_COPY = 'Most wanted'
export const SECONDHAND_COPY = 'Open to secondhand'

export const GOALS = {
  arrivalDate: 'arrivalDate',
  shippingAddress: 'shippingAddress',
  eventDetails: 'eventDetails',
  otherStoreItems: 'otherStoreItems',
  babylistStoreItems: 'babylistStoreItems',
  babylistStorePurchase: 'babylistStorePurchase',
  helloBabyBox: 'helloBabyBox',
  cashFund: 'cashFund',
  insertCards: 'insertCards',
  personalizeRegistry: 'personalizeRegistry',
  familyDetails: 'familyDetails',
  registryDiscount: 'registryDiscount',
  externalRegistry: 'externalRegistry',
  checklistFortyPercentComplete: 'checklistFortyPercentComplete',
}

export const GOALS_EVENT_CTA = {
  [GOALS.arrivalDate]: 'Arrival Date',
  [GOALS.shippingAddress]: 'Shipping Address',
  [GOALS.insertCards]: 'Insert Cards',
  [GOALS.helloBabyBox]: 'Hello Baby Box',
  [GOALS.registryDiscount]: 'Babylist Shop Discount',
  [GOALS.familyDetails]: 'Add Partner/Collaborator',
  [GOALS.personalizeRegistry]: 'Add Photo or Greeting',
  [GOALS.eventDetails]: 'Shower Date',
}

// We filter these out because we don't want to display them as tiles
// but they are still accessible as requirements for HBB
export const HIDDEN_GOALS = [
  GOALS.externalRegistry,
  GOALS.otherStoreItems,
  GOALS.babylistStoreItems,
  GOALS.babylistStorePurchase,
  GOALS.cashFund,
  GOALS.checklistFortyPercentComplete,
]

export const GOAL_TYPES = {
  GOAL: 'goal',
  REWARD: 'reward',
}

export const REGISTRY_ROLES = {
  OWNER: 'owner',
  PARTNER: 'partner',
  COLLABORATOR: 'collaborator',
}

export const INACCURACY_REPORT_REASONS = {
  INCORRECT_PRICE: 'inaccurate_price',
  INCORRECT_PRODUCT: 'inaccurate_product',
}

export const GROUP_GIFT_ACTIONS = {
  CART: 'add-to-cart',
  DISABLE: 'disable-group-gift',
  DELETE: 'delete-reg-item',
  QUICKADD: 'quick-add-to-cart',
}

export const UPDATE_NAVBAR_USER_ICON = 'navbarUserIcon.update'

export const sentiments = {
  EXCITED: 'excited',
  EXPLORING: 'exploring',
  OVERWHELMED: 'overwhelmed',
}

export const gettingStartedResponse = {
  INSPIRATION: 'inspiration',
  RESEARCH: 'research',
  TASK: 'task',
}

export const roadAheadResponse = {
  PREGNANCY: 'pregnancy',
  STILL_TRYING: 'stillTrying',
  ADOPTION: 'adoption',
  SURROGACY: 'surrogacy',
}

export const registryBuildingProcessResponse = {
  JUST_EXPLORING: 'exploring',
  GETTING_STARTED: 'getting_started',
  RESEARCH_MODE: 'research',
}

export const REGISTRY_DISCOUNT_MODAL_V2_VIEWED_KEY =
  'registry-discount-modal-v2-viewed'
