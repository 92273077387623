// eslint-disable-next-line import/no-extraneous-dependencies
import { NavLink, RightDrawer } from 'baby-design'
import { useEffect, useState } from 'react'
import { successMessage } from 'lib/flash-message'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { CashFundPreferences } from 'src/types/registry'
import { paymentPreferencesSaveRequestBody } from 'src/routes/(registry)/api/helpers'
import PaymentPreferencesForm from 'src/routes/(registry)/list/[slug]/components/CashFundPreferencesDrawer/components/PaymentPreferencesForm/PaymentPreferencesForm'
import useCashFundPreferences from 'src/routes/(registry)/list/[slug]/hooks/useCashFundPreferences'
import { noPaymentPreferencesSelected } from 'src/routes/(registry)/list/[slug]/utils/cashFundHelpers'
import ConfirmUnsavedChangesModal from '../ConfirmUnsavedChangesModal/ConfirmUnsavedChangesModal'
import { useRegistry } from '../../utils/useQueries'
import css from './CashFundPreferencesDrawer.style.scss'

// @ts-ignore
export default NiceModal.create(() => {
  const modal = useModal()

  const registry = useRegistry()

  const [hasUnsavedChanges, sethasUnsavedChanges] = useState<boolean>(false)

  const handleSaveSuccess = (newCashFundPreferences: any) => {
    sethasUnsavedChanges(false)
    successMessage(`Cash fund preferences have been successfully saved!`)
    modal.resolve(newCashFundPreferences)
    modal.hide()
  }

  const {
    isLoading,
    cashFundPreferences,
    setCashFundPreferences,
    onSave,
    setErrors,
    setShowPreferenceRequired,
    showPreferenceRequired,
    errors,
  } = useCashFundPreferences(
    registry?.cashFundPreferences,
    paymentPreferencesSaveRequestBody,
    {
      onSuccess: handleSaveSuccess,
    },
    registry
  )

  const updateCashFundPreferences = (
    newCashFundPreferences: CashFundPreferences
  ) => {
    sethasUnsavedChanges(true)
    setShowPreferenceRequired(false)
    setCashFundPreferences(newCashFundPreferences)
  }

  useEffect(() => {
    if (modal.visible) {
      setCashFundPreferences({
        direct: registry?.cashFundPreferences?.direct || false,
        paypalme: registry?.cashFundPreferences?.paypalme,
        paymentPreferences:
          registry?.cashFundPreferences?.paymentPreferences || [],
      })
    }
  }, [modal.visible])

  const handleClose = async () => {
    if (isLoading) {
      return
    }

    if (hasUnsavedChanges) {
      const confirmUnsavedChanges: boolean | undefined = await NiceModal.show(
        ConfirmUnsavedChangesModal
      )

      if (!confirmUnsavedChanges) {
        return
      }
    }

    modal.resolve(undefined)
    modal.hide()
  }

  const showAppliesToAllFundsWarning =
    !noPaymentPreferencesSelected(cashFundPreferences)

  const handleSave = () => {
    if (noPaymentPreferencesSelected(cashFundPreferences)) {
      setShowPreferenceRequired(true)
      return
    }
    onSave()
  }

  return (
    <RightDrawer
      contextualIcon={
        <NavLink
          disabled={isLoading || !hasUnsavedChanges}
          size="md"
          variant="eggplant"
          onClick={handleSave}
        >
          Save
        </NavLink>
      }
      handleClose={handleClose}
      handleDismount={modal.remove}
      isOpen={modal.visible}
      mainIcon="back"
      title="Payment preferences"
    >
      <div className={css.cash_fund_preferences}>
        <PaymentPreferencesForm
          errors={errors}
          originComponent="Drawer"
          preferences={cashFundPreferences}
          setErrors={setErrors}
          showAppliesToAllFundsWarning={showAppliesToAllFundsWarning}
          showPreferenceRequired={showPreferenceRequired}
          onPreferencesUpdated={updateCashFundPreferences}
        />
      </div>
    </RightDrawer>
  )
})
