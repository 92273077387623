import React from 'react'

interface StolleriaStoreProps {
  className?: string
  rounded?: boolean
}

const StolleriaStore: React.FC<StolleriaStoreProps> = ({
  className,
  rounded = true,
}: StolleriaStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3733_52)">
      {rounded ? (
        <rect fill="#FAFAFA" height="24" rx="12" width="24" />
      ) : (
        <rect fill="#FAFAFA" height="24" width="24" />
      )}
      <path
        d="M5.77283 15.3376C5.78497 15.1819 5.78093 14.9432 5.82744 14.7167C5.98115 13.9643 6.64452 13.485 7.43125 13.5376C8.13305 13.5841 8.65889 14.1504 8.71349 14.9149C8.71956 14.9958 8.72563 15.0767 8.71754 15.1576C8.61035 16.3104 9.29394 17.1275 10.4528 17.3924C11.5328 17.6391 12.7119 17.174 13.2661 16.2356C13.7474 15.4185 13.715 14.567 13.2398 13.7682C12.9323 13.2504 12.5359 12.7832 12.1577 12.31C11.6542 11.6789 11.1081 11.0803 10.6207 10.4351C10.2061 9.88503 9.88248 9.26818 9.78338 8.57245C9.58113 7.13246 10.111 5.97561 11.2011 5.05539C12.722 3.7691 15.1429 3.64169 16.7144 4.76618C17.404 5.25966 17.8692 5.91696 18.0573 6.74819C18.1867 7.32257 18.0552 7.83021 17.5658 8.19627C17.0986 8.54616 16.5788 8.57447 16.0874 8.28121C15.8669 8.14975 15.691 7.90908 15.5494 7.68257C15.331 7.33066 15.1287 6.97673 14.7687 6.74212C13.9962 6.23853 12.8838 6.90392 12.8616 7.6583C12.8474 8.10324 12.991 8.5138 13.258 8.85762C13.7494 9.48862 14.2631 10.1014 14.7849 10.7082C15.3957 11.4221 16.0126 12.132 16.3766 13.0138C17.1492 14.8886 16.7629 16.5774 15.4503 18.0497C13.5755 20.1531 10.4164 20.6142 8.01372 19.1439C6.64047 18.3025 5.84969 17.0648 5.77081 15.3396L5.77283 15.3376Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_3733_52">
        <rect fill="white" height="24" width="24" />
      </clipPath>
    </defs>
  </svg>
)

export default StolleriaStore
