import { PriceDetails } from 'src/types/priceDetails'
import { RegItem } from 'src/types/regItem'
import { FAVORITE_COPY, SECONDHAND_COPY } from 'registry/constants'
import {
  Product,
  ProductCategory,
} from '../components/RegItemCard/RegItemCard.types'

export const DEFAULT_CATEGORY_TAB = {
  label: 'General',
  value: 0,
}

export const getProductFromRegItem = (
  regItem: RegItem,
  categories: ProductCategory[]
): Product => {
  const category = categories.find(
    (category) => category.value === regItem.categoryId
  )
  const tags = []
  if (regItem.isGroupGift) {
    tags.push('Group Gift')
  }
  if (regItem.isFavorite) tags.push(FAVORITE_COPY)
  if (regItem.prelovedAllowed) tags.push(SECONDHAND_COPY)

  let subheading = ''
  if (regItem.isGroupGift && regItem.goalAmount) {
    subheading = `Funded $${regItem.fundedAmount || '0'} of ${formatDisplayPriceForWholeDollars(regItem.goalAmount)}`
  }

  if (regItem.quantity && regItem.quantity > 1) {
    subheading = `Purchased ${regItem.quantityPurchased} of ${regItem.quantity} items`
  }

  if (regItem.isFavor) {
    regItem.offers = []
    if (regItem.quantity && regItem.quantity > 1) {
      subheading = `${regItem.quantityReserved} of ${regItem.quantity} favors gifted`
    }
  }

  if (regItem.isCrowdfund) {
    regItem.offers = []
  }

  let productUrl: string = regItem.primaryOfferUrl
  if (regItem.offers.length === 1 && regItem.offers[0].url) {
    productUrl = regItem.offers[0].url
  }

  return {
    regItemId: regItem.id,
    image: regItem.imgUrl,
    heading: regItem.title,
    subheading,
    price: getRegItemPrice(regItem),
    notes: regItem.description,
    offers: regItem.offers,
    isGiftCard: regItem.isGiftCard,
    tags,
    category,
    url: productUrl,
    priceDetails: regItem.priceDetails || {},
  }
}

export const getRegItemPrice = (regItem: RegItem) => {
  if (regItem.offers.length > 0) {
    const sortedOffers = regItem.offers
      .filter((offer) => (offer.price as number) > 0)
      .sort((a, b) => (a.price as number) - (b.price as number))
    return sortedOffers[0] ? sortedOffers[0].price : regItem.price
  }

  return regItem.price
}

export const formatDisplayPrice = (price?: string | number) => {
  if (!price) return '$0.00'
  const priceString = typeof price === 'string' ? price : price.toString()
  let [dollars, cents] = priceString.split('.')
  cents = cents || '0'
  dollars = dollars.replace(/\B(?=(\d{3})+(?!\d))/g, ',') // add commas
  return `$${dollars}.${cents.padEnd(2, '0')}`
}

export const formatDisplayPriceForWholeDollars = (price?: string | number) => {
  if (!price) return '$0.00'
  const priceString = typeof price === 'string' ? price : price.toString()
  let [dollars, cents] = priceString.split('.')
  cents = cents || '0'
  dollars = dollars.replace(/\B(?=(\d{3})+(?!\d))/g, ',') // add commas
  if (cents === '00' || cents === '0') return `$${dollars}`
  return `$${dollars}.${cents.padEnd(2, '0')}`
}

export const getCategoriesAsKeyValue = (categories: any): ProductCategory[] =>
  categories.map((category: any) => ({
    value: category.id,
    label: category.title,
    isPrivate: category.isPrivate,
  }))

export const getCategoryTabById = (
  categoryTabs: ProductCategory[],
  categoryId: number
) =>
  categoryTabs.find(
    (category: ProductCategory) => category.value === categoryId
  )

export const getCategoryTabByLabel = (
  categoryTabs: ProductCategory[],
  categoryLabel: string
) =>
  categoryTabs.find(
    (category: ProductCategory) => category.label === categoryLabel
  )

export const getPricePropsForRegItem = (
  priceDetails: PriceDetails,
  offerCount: number
): {
  msrp?: string
  currentPrice?: string
  onSale: boolean
} => {
  if (!priceDetails) {
    return {
      onSale: false,
    }
  }

  const { msrp, listPrice, salePrice, saleAttributes } = priceDetails

  const saleType = saleAttributes?.saleType || ''
  const isActiveSale = saleType === 'active_sale'

  const currentPrice = isActiveSale
    ? salePrice?.price || listPrice?.price
    : listPrice?.price

  const includeMsrp = msrp?.price && isActiveSale && offerCount === 1

  return {
    ...(includeMsrp && { msrp: formatDisplayPriceForWholeDollars(msrp.price) }),
    ...(currentPrice && {
      currentPrice: formatDisplayPriceForWholeDollars(currentPrice),
    }),
    onSale: isActiveSale,
  }
}
