import React from 'react'

interface CartersStoreProps {
  className?: string
  rounded?: boolean
}

const CartersStore: React.FC<CartersStoreProps> = ({
  className,
  rounded = true,
}: CartersStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {rounded ? (
      <rect fill="#00A9E0" height="24" rx="12" width="24" />
    ) : (
      <rect fill="#00A9E0" height="24" width="24" />
    )}
    <path
      d="M12.5455 4C12.5 4 12.4545 4 12.4091 4C7.68182 4 4 7.22727 4 12C4 16.7727 7.72727 19.9545 12.3182 20C13.6818 20 16.2727 19.4545 17.8636 17.8636L15.3182 15.1364C14.5909 16.0455 13.5 16.5909 12.3182 16.5909C9.5 16.5909 7.81818 14.4091 7.81818 12.0455C7.72727 9.59091 9.63636 7.54546 12.0455 7.5C12.1364 7.5 12.2273 7.5 12.3182 7.5C13.3636 7.5 14.3636 8.13636 15.2727 9L17.9545 6.36364C16.5455 4.86364 14.5909 4 12.5455 4Z"
      fill="white"
    />
  </svg>
)

export default CartersStore
