import { useCallback, useEffect, useState } from 'react'
import { isString, isUndefined, range } from 'lodash'
import { useForm } from 'react-hook-form'
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3'

import { FLASH_MESSAGE, RECAPTCHA_V3_SITE_KEY } from 'shared/constants'
import MailcheckedInput from 'components/mailchecked-input'
import { InfoButton } from 'components/buttons'
import DueDateSelect from 'components/due-date-select'
import GoogleRecaptchaDisclosureTag from 'components/google-recaptcha-disclosure-tag'

import { babylistUrl, helloBabyPostPath } from 'lib/urls'
import { email as isEmail, dateString } from 'lib/form-validation'
import { parseErrors } from 'lib/api-utils'
import { msPerWeek, parseLocalISODate, today, weeksAgo } from 'lib/date'
import { useTracking, track, withContextualizedTracking } from 'lib/analytics'

import * as api from 'pages/pregnancy-emails/api'
import css from './email-signup-form.scss'
import { EmailTypes } from '../email-callout/email-callout'

export const pregnancyWeek = (dueDate: number) => {
  const conceptionDate = new Date(dueDate - msPerWeek * 40) // -40 weeks
  return weeksAgo(conceptionDate)
}

const currentYear = today().getFullYear()
const years = range(currentYear - 20, currentYear + 2).reverse()

interface Post {
  slug: string
  title: string
}

interface EmailSignupFormProps {
  nextPost?: Post
  emailUrlsByWeek: string[]
  emailType: EmailTypes
  userSource: string
  dateValue?: string
  className?: string
  displayDueDate?: boolean
  submitCallback?: (isSuccessful: boolean) => void
}

interface FormData {
  dueDate?: string | undefined
  email: string
}

const EmailSignupForm = ({
  nextPost,
  emailType,
  emailUrlsByWeek,
  userSource,
  className,
  dateValue,
  displayDueDate = true,
  submitCallback,
}: EmailSignupFormProps) => {
  const {
    register,
    setValue,
    errors,
    setError,
    getValues,
    handleSubmit: rhfHandleSubmit,
  } = useForm<FormData>({
    defaultValues: {
      dueDate: dateValue,
    },
  })
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.error('Execute recaptcha not yet available')
      return
    }

    return await executeRecaptcha('hello_baby_email_signup')
  }, [executeRecaptcha])

  useEffect(() => {
    register('dueDate', { validate: dateString })
  }, [])
  const { dueDate } = getValues()
  const tracker = useTracking()
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const renderConfirmation = () =>
    emailType === EmailTypes.Newsletter
      ? renderNewsletterConfirmation()
      : renderPregnancyConfirmation()

  const renderNewsletterConfirmation = () => (
    <div>
      <div className="text-bold mvm confirmation-text">
        Get ready for some awesomeness in your inbox.
      </div>
      {nextPost ? renderNewsletterContentCallout() : null}
      <InfoButton disabled pill className={css.submitBtn}>
        You're all set!
      </InfoButton>
    </div>
  )

  const renderPregnancyConfirmation = () => (
    <div>
      <div className="text-bold mvm confirmation-text">
        Get ready for some awesomeness in your inbox.
      </div>
      {renderPregnancyContentCallout()}
      <InfoButton disabled pill className={css.submitBtn}>
        You're all set!
      </InfoButton>
    </div>
  )

  const renderNewsletterContentCallout = () => (
    <div className="h6 mvl">
      In the meantime, checkout this related article:{' '}
      <a href={helloBabyPostPath((nextPost as Post).slug)}>
        {(nextPost as Post).title}
      </a>
      .
    </div>
  )

  const renderPregnancyContentCallout = () => {
    let message
    let url
    const dueDateTmp = parseLocalISODate(dueDate)
    const week = pregnancyWeek(dueDateTmp.getTime())
    const lastWeekEmailUrl = emailUrlsByWeek[week]
    if (week > 42) {
      message = 'check out our Complete Childcare Guide'
      url = babylistUrl(helloBabyPostPath('childcare'))
    } else if (week > 4 && lastWeekEmailUrl) {
      message = `check out last week's email`
      url = lastWeekEmailUrl
    } else {
      message = 'check out our Baby Essentials Guide'
      url = babylistUrl(helloBabyPostPath('baby-essentials'))
    }
    return (
      <div className="h6 mvl">
        In the meantime, {message}: <a href={url}>{url}</a>.
      </div>
    )
  }

  const arrivalDateLabel = () =>
    emailType === EmailTypes.Newsletter
      ? "Baby's Birthday/Arrival Date"
      : "Baby's Arrival Date"

  const renderEmailForm = () => (
    <form onSubmit={rhfHandleSubmit(handleSubmit)}>
      {(emailType === EmailTypes.Pregnancy || displayDueDate !== false) && (
        <DueDateSelect
          className={css.dateSelect}
          error={errors.dueDate?.message}
          label={arrivalDateLabel()}
          onChange={handleChangeDueDate}
          preferFutureDates
          name="dueDate"
          years={years}
          value={dueDate}
        />
      )}
      <MailcheckedInput
        mailcheckOnChange
        novalidate
        className={css.emailInput}
        error={errors.email?.message}
        inputRef={register({
          required: 'Please enter your email address',
          validate: isEmail,
        })}
        label="Your Email Address"
        mailcheckDelay={300}
        mailcheckOnBlur={false}
        name="email"
        type="text"
        onChange={handleChangeEmail}
      />
      <InfoButton
        type="submit"
        className={css.submitBtn}
        submitting={submitting}
        pill
      >
        Sign Up
      </InfoButton>
      <GoogleRecaptchaDisclosureTag className={css.grecaptchaLegalNotice} />
    </form>
  )

  const handleChangeEmail = (
    eventOrValue: string | { target: { value: string } }
  ) => {
    const value = isString(eventOrValue)
      ? eventOrValue
      : eventOrValue?.target?.value
    setValue('email', value)
  }

  const handleChangeDueDate = (dueDate?: string) => {
    setValue('dueDate', dueDate)
  }

  const handleSubmit = async ({ dueDate, email }: FormData) => {
    setSubmitting(true)
    const token = await handleReCaptchaVerify()
    let formData = null
    formData = {
      emailType,
      user: {
        email,
        family: {
          arrival_date: dueDate,
        },
        source: userSource,
      },
      recaptcha_token: token,
    }

    api
      .createUser(formData)
      .then(({ user: { id: userId, email: userEmail } }) => {
        setSubmitting(false)
        setSubmitted(true)
        sendTrackingEvents({ userEmail, userId })
        if (submitCallback) submitCallback(true)
      })
      .catch((resp) => {
        setSubmitting(false)
        if (submitCallback) submitCallback(false)
        const apiErrors = parseErrors(resp)
        if (apiErrors.email && apiErrors.email[0]) {
          setError('email', 'apiError', `Email ${apiErrors.email[0]}`)
        }
        if (apiErrors.family?.arrivalDate && apiErrors.family.arrivalDate[0]) {
          setError(
            'dueDate',
            'apiError',
            `${arrivalDateLabel()} ${apiErrors.family.arrivalDate[0]}`
          )
        }
        if (apiErrors._error) {
          PubSub.publish(FLASH_MESSAGE, {
            message: apiErrors._error,
            variant: 'danger',
          })
        }
      })
  }

  const sendTrackingEvents = ({
    userEmail,
    userId,
  }: {
    userEmail: string
    userId: number
  }) => {
    if (!isUndefined(window.ga)) {
      window.ga('set', 'page', `${window.location.pathname}/submitted`)
      window.ga('send', 'pageview')
      window.ga('send', {
        hitType: 'event', // required
        eventCategory: 'Email', // required
        eventAction: 'Subscribe', // required
        eventValue: 0, // required
      })
    }

    tracker.trackEvent({
      event: track.emailCaptured,
      email: userEmail,
      userId,
      eventLocation: track.EventLocation.GUIDE,
      eventUrl: window.location.href,
    })

    // Facebook tracking
    window.fbq('trackCustom', 'EmailSubscribe')
  }

  return (
    <div className={className}>
      {submitted ? renderConfirmation() : renderEmailForm()}
    </div>
  )
}

const EmailSignupFormContext = ({
  nextPost,
  emailType,
  emailUrlsByWeek,
  userSource,
  className,
  dateValue,
  displayDueDate = true,
  submitCallback,
}: EmailSignupFormProps) => (
  <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_V3_SITE_KEY}>
    <EmailSignupForm
      className={className}
      dateValue={dateValue}
      displayDueDate={displayDueDate}
      emailType={emailType}
      emailUrlsByWeek={emailUrlsByWeek}
      nextPost={nextPost}
      submitCallback={submitCallback}
      userSource={userSource}
    />
  </GoogleReCaptchaProvider>
)

export default withContextualizedTracking()(EmailSignupFormContext)
