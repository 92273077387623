import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { TextLink } from 'baby-design'
import { giftGiverFaqPath } from 'lib/urls'
import { onContactUsClick } from 'shared/Footer/constants'
import styles from './MicroNavBar.styles.scss'

const MicroNavBar: React.FC = () => (
  <div className={styles.MicroNavBar} data-testid="MicroNavBar">
    <h6 className={styles.slogan}>We take care of growing families.</h6>
    <div className={styles.help_links}>
      <TextLink
        data-testid="help_center_link"
        href={giftGiverFaqPath}
        rel="noopener noreferrer"
        size="xs"
        target="_blank"
      >
        Help center
      </TextLink>
      <div className={styles.mobile_separator} />
      <TextLink
        data-testid="chat_with_us_link"
        size="xs"
        onClick={onContactUsClick}
      >
        Chat with us
      </TextLink>
    </div>
  </div>
)

export default MicroNavBar
