import React from 'react'

interface AdenAndAnaisStoreProps {
  className?: string
  rounded?: boolean
}

const AdenAndAnaisStore: React.FC<AdenAndAnaisStoreProps> = ({
  className,
  rounded = true,
}: AdenAndAnaisStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {rounded ? (
      <rect fill="#FAFAFA" height="24" rx="12" width="24" />
    ) : (
      <rect fill="#FAFAFA" height="24" width="24" />
    )}
    <path
      d="M16.8065 6.6721V4.39104H20V19.609H16.8065V17.0346C15.8289 18.7943 13.7434 20 11.3971 20C7.42159 20 4 16.6762 4 11.9837C4 7.45418 7.22607 4 11.5275 4C13.7434 4 15.8289 4.94501 16.8065 6.6721ZM11.9511 17.002C14.4929 17.002 16.7739 14.8513 16.7739 11.9837C16.7739 9.05092 14.3299 6.99796 11.8859 6.99796C9.27902 6.99796 7.22607 9.27902 7.22607 11.9837C7.22607 14.721 9.27902 17.002 11.9511 17.002Z"
      fill="#00676F"
    />
  </svg>
)

export default AdenAndAnaisStore
