import React from 'react'

interface KyleCosmeticsStoreProps {
  className?: string
  rounded?: boolean
}

const KyleCosmeticsStore: React.FC<KyleCosmeticsStoreProps> = ({
  className,
  rounded = true,
}: KyleCosmeticsStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {rounded ? (
      <rect fill="#EFD7E5" height="24" rx="12" width="24" />
    ) : (
      <rect fill="#EFD7E5" height="24" width="24" />
    )}
    <path
      d="M14.3143 4H17.9895L13.514 11.1777L17.9895 20H14.22L10.3861 11.7973H10.3421V19.9991H6.99999V4H10.3438V10.8463H10.387L14.3143 4Z"
      fill="#B18590"
    />
  </svg>
)

export default KyleCosmeticsStore
