import React from 'react'

interface MacysStoreProps {
  className?: string
  rounded?: boolean
}

const MacysStore: React.FC<MacysStoreProps> = ({
  className,
  rounded = true,
}: MacysStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {rounded ? (
      <rect fill="#FAFAFA" height="24" rx="12" width="24" />
    ) : (
      <rect fill="#FAFAFA" height="24" width="24" />
    )}
    <path
      d="M12.0094 3.5L9.89277 9.95478H3L8.5841 13.9655L6.46866 20.4226L12.0012 16.4281L17.5665 20.4412L15.4405 13.9551L21 9.95478H14.1283L12.0094 3.5Z"
      fill="#E01A2B"
    />
  </svg>
)

export default MacysStore
