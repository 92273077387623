import useMarkPurchaseFormField, {
  MarkPurchaseFormField,
} from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/hooks/useMarkPurchaseFormField'
import { Reservation } from 'src/types/reservation'
import useMarkAsPurchased, {
  MarkAsPurchasedProps,
} from 'src/routes/(registry)/list/[slug]/hooks/useMarkAsPurchased'
import { RegItem } from 'src/types/regItem'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { errorMessage } from 'lib/flash-message'
import * as Sentry from '@sentry/react'

interface UseMarkPurchaseModalProps {
  fields: (isSelfPurchase?: boolean) => MarkPurchaseFormField<any>[]
  isInitiallySelfPurchase?: boolean
  modal: {
    hide: () => void
    resolve: (value: any) => void
  }
  onMarkedAsPurchased?: (reservation: Reservation) => void
  regItem: RegItem
}

type PayloadBuilder = (formValues: Record<string, any>) => MarkAsPurchasedProps

const PURCHASE_OPTION_ERROR_MESSAGE = 'Select who purchased this'

const useMarkPurchaseModal = ({
  fields,
  isInitiallySelfPurchase,
  modal,
  onMarkedAsPurchased,
  regItem,
}: UseMarkPurchaseModalProps) => {
  const [currentUser] = useCurrentUser()
  const isSelfPurchaseField = useMarkPurchaseFormField({
    name: 'isSelfPurchase',
    defaultValue: isInitiallySelfPurchase,
    errorMessage: (value?: boolean) =>
      value === undefined ? PURCHASE_OPTION_ERROR_MESSAGE : undefined,
  })

  const {
    value: isSelfPurchase,
    updateValue: setIsSelfPurchase,
    error: isSelfPurchaseError,
  } = isSelfPurchaseField

  const allFields = () => [isSelfPurchaseField, ...fields(isSelfPurchase)]

  const values = () =>
    allFields().reduce(
      (acc, field) => {
        acc[field.name] = field.value
        return acc
      },
      {} as Record<string, any>
    )

  const clearFields = () => {
    allFields().forEach((field) => field.clear())
  }

  const closeModal = (reservation?: Reservation) => {
    clearFields()
    modal.resolve(reservation)
    modal.hide()
  }

  const { markAsPurchased, isLoading: isCreatingReservation } =
    useMarkAsPurchased({
      regItem,
      onSuccess: (reservation: Reservation) => {
        onMarkedAsPurchased?.(reservation)
        clearFields()
        closeModal(reservation)
      },
      onError: (errorResponse: APIResponse.StandardError) => {
        console.error(errorResponse)
        Sentry.captureException(errorResponse)
        errorMessage(errorResponse.error.message || undefined)
      },
    })

  const currentUserName = [currentUser?.firstName, currentUser?.lastName]
    .filter((n) => !!n)
    .join(' ')

  const handleMarkAsPurchased = (buildPayload?: PayloadBuilder) => {
    const basePayload = {
      createdBy: currentUser?.id,
      isSelfPurchase,
      name: currentUserName,
      quantity: 1,
      ...values(),
    }

    const payload = buildPayload
      ? buildPayload(basePayload)
      : (basePayload as MarkAsPurchasedProps)

    markAsPurchased(payload)
  }

  const handleSubmit = (onSubmit: () => void) => {
    const allFieldsValid = allFields().reduce(
      (valid, field) => field.validate() && valid,
      true
    )

    if (allFieldsValid) {
      onSubmit()
    }
  }

  return {
    closeModal,
    handleMarkAsPurchased,
    handleSubmit,
    isCreatingReservation,
    isSelfPurchase,
    isSelfPurchaseError,
    setIsSelfPurchase,
  }
}

export default useMarkPurchaseModal
