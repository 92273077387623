const GreenSmileLight = () => (
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0001 27.0673C22.0993 27.0673 27.0742 22.0485 27.0742 15.8183C27.0742 9.58804 22.1334 4.56923 16.0001 4.56923C9.86675 4.56923 4.92601 9.58804 4.92601 15.8183C4.92601 22.0485 9.90082 27.0673 16.0001 27.0673Z"
      fill="url(#paint0_linear_253_20552)"
    />
    <path
      d="M16 27.5C9.66222 27.5 4.5 22.3341 4.5 16C4.5 9.66591 9.66222 4.5 16 4.5C22.3378 4.5 27.5 9.65726 27.5 16C27.5 22.3427 22.3378 27.5 16 27.5ZM16 5.36531C10.1307 5.36531 5.35185 10.1332 5.35185 16C5.35185 21.8668 10.1307 26.6347 16 26.6347C21.8693 26.6347 26.6481 21.8668 26.6481 16C26.6481 10.1332 21.8693 5.36531 16 5.36531Z"
      fill="#6E3264"
    />
    <path
      d="M16.0004 22.9052C14.1604 22.9052 12.4226 22.187 11.1278 20.889C9.82448 19.5824 9.1004 17.8431 9.1004 16C9.1004 15.7577 9.28781 15.5674 9.52633 15.5674C9.76485 15.5674 9.95226 15.7577 9.95226 16C9.95226 17.6095 10.5826 19.1238 11.7241 20.266C12.8656 21.4082 14.3819 22.0399 16.0004 22.0399C17.6189 22.0399 19.1352 21.4082 20.2767 20.266C21.4182 19.1238 22.0486 17.6008 22.0486 16C22.0486 15.7577 22.236 15.5674 22.4745 15.5674C22.713 15.5674 22.9004 15.7577 22.9004 16C22.9004 17.8431 22.1848 19.5737 20.873 20.8804C19.5611 22.187 17.8319 22.9052 15.9919 22.9052H16.0004Z"
      fill="#6E3264"
    />
    <path
      d="M12.6432 13.7242C13.2054 13.7242 13.6654 13.2656 13.6654 12.7032C13.6654 12.1407 13.2054 11.6734 12.6432 11.6734C12.081 11.6734 11.621 12.1321 11.621 12.7032C11.621 13.2743 12.081 13.7242 12.6432 13.7242ZM19.3558 13.7242C19.9266 13.7242 20.378 13.2656 20.378 12.7032C20.378 12.1407 19.918 11.6734 19.3558 11.6734C18.7936 11.6734 18.3336 12.1321 18.3336 12.7032C18.3336 13.2743 18.7936 13.7242 19.3558 13.7242Z"
      fill="#6E3264"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_253_20552"
        x1="16"
        x2="16"
        y1="27.5"
        y2="4.5"
      >
        <stop stopColor="#A0DCFF" />
        <stop offset="0.74" stopColor="#56BB6C" />
      </linearGradient>
    </defs>
  </svg>
)

export default GreenSmileLight
