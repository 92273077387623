import React from 'react'

interface KohlsStoreProps {
  className?: string
  rounded?: boolean
}

const KohlsStore: React.FC<KohlsStoreProps> = ({
  className,
  rounded = true,
}: KohlsStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {rounded ? (
      <g clipPath="url(#clip0_3135_597)">
        <rect fill="#FAFAFA" height="24" rx="12" width="24" />
        <path
          d="M3 6H8.93187V10.0289H8.97033L13.4936 6.00018H20.1438L14.6877 10.9412L21 17.7216H13.4936L8.97033 12.9236H8.93187V17.7214H3V6Z"
          fill="#800033"
        />
      </g>
    ) : (
      <g clipPath="url(#clip0_3733_48)">
        <rect fill="#FAFAFA" height="24" width="24" />
        <path
          d="M3 6H8.93187V10.0289H8.97033L13.4936 6.00018H20.1438L14.6877 10.9412L21 17.7216H13.4936L8.97033 12.9236H8.93187V17.7214H3V6Z"
          fill="#800033"
        />
      </g>
    )}
    {rounded ? (
      <defs>
        <clipPath id="clip0_3135_597">
          <rect fill="white" height="24" rx="12" width="24" />
        </clipPath>
      </defs>
    ) : (
      <defs>
        <clipPath id="clip0_3733_48">
          <rect fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    )}
  </svg>
)

export default KohlsStore
