export const CONSOLIDATED_SHIPPING_COPY = 'Bundled shipping'
export const USER_UPLOADED_CONTENT_CLOUDINARY_FOLDER_BASE =
  'user_uploaded_content'

// Breakpoints
export const BREAKPOINT_MOBILE = 525
export const BREAKPOINT_TABLET = 768
export const BREAKPOINT_LARGE_TABLET = 1024
export const BREAKPOINT_DESKTOP = 1224
export const BREAKPOINT_LARGE_DESKTOP = 1440

// Reg Item Types
export const REG_ITEM_TYPE_PRODUCT = 'product'
export const REG_ITEM_TYPE_GIFT_CARD = 'electronic_gift_card'
export const REG_ITEM_TYPE_FAVOR = 'favor'
export const REG_ITEM_TYPE_EXTERNAL_REGISTRY = 'external_registry'
export const REG_ITEM_TYPE_CASH_FUND = 'cash_fund' // Cash funds are deprecated in favor of crowdfunds
export const REG_ITEM_TYPE_CROWDFUND = 'crowd_fund'
export const REG_ITEM_TYPE_GROUP_GIFT = 'group_gift'

// DD RUM custom view names
export const DD_RUM_VIEW_NAME = {
  RegistryGiftGiver: 'RegistryGiftGiver',
  RegistryGiftGiverLegacy: 'RegistryGiftGiverLegacy',
}

// DD RUM custom timings
export const DD_RUM_CUSTOM_TIMINGS = {
  PageContentAppearsLoaded: 'page_content_appears_loaded',
  PageContentFullyLoaded: 'page_content_fully_loaded',
}
