const CrayonsDark = () => (
  <svg
    fill="none"
    height="30"
    viewBox="0 0 24 30"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2596_999)">
      <g clipPath="url(#clip1_2596_999)">
        <path
          d="M20.8696 18.0484C20.9186 18.1464 20.9063 18.2567 20.8206 18.3303L18.0628 20.7203H18.0506C18.0506 20.7203 18.0015 20.61 17.977 20.5487L16.8862 18.5999C16.8862 18.5999 16.8126 18.4896 16.7759 18.4406H16.7881L20.269 17.313C20.3671 17.2762 20.4774 17.3252 20.5387 17.4233L20.8818 18.0361L20.8696 18.0484Z"
          fill="#FFCF3F"
        />
      </g>
      <path
        d="M22.681 1.94429C22.7561 1.99515 22.7867 2.0933 22.7506 2.17678L21.4776 5.00276L21.4645 5.01331C21.4267 4.97491 21.3837 4.93918 21.338 4.90608L19.7625 3.8198C19.7168 3.78669 19.6686 3.76141 19.6204 3.73612L19.6255 3.72308L21.9399 1.6119C22.0082 1.55133 22.1119 1.54475 22.1897 1.59817L22.6836 1.93907L22.681 1.94429Z"
        fill="url(#paint0_linear_2596_999)"
      />
      <path
        d="M21.403 5.30762C21.7843 5.62499 21.8426 6.1291 21.5158 6.50622L21.0431 7.05448L17.276 4.86614L17.7488 4.32053C18.0756 3.94077 18.6797 3.81898 19.172 4.01061C19.2304 4.03626 19.2887 4.06191 19.3439 4.09553L21.2501 5.19865C21.3054 5.23227 21.3574 5.26858 21.403 5.30762Z"
        fill="url(#paint1_linear_2596_999)"
      />
      <path
        d="M21.1311 6.85042L20.3145 7.77067L20.3144 7.76807L16.4996 5.61523L17.3162 4.69498L21.1311 6.85042Z"
        fill="url(#paint2_linear_2596_999)"
      />
      <path
        d="M20.2324 7.83787L13.653 16.736L10.1705 14.3716L11.6751 12.3364C11.4761 13.0174 11.5069 13.5385 11.8072 13.7482C12.4497 14.1899 14.0885 13.062 15.4619 11.2281C16.8383 9.39406 17.4323 7.54776 16.7897 7.10609C16.4805 6.89364 15.944 7.04051 15.316 7.45652L15.295 7.4397L16.7529 5.47064L20.2323 7.83502L20.2324 7.83787Z"
        fill="url(#paint3_linear_2596_999)"
      />
      <path
        d="M16.9851 7.09143C17.7015 7.54496 17.0365 9.42988 15.4985 11.3061C13.9605 13.1824 12.1339 14.3372 11.4176 13.8866C11.0795 13.6727 11.0458 13.14 11.2721 12.4437L15.317 7.43429L15.3404 7.45145C16.0383 7.02551 16.637 6.87472 16.9851 7.09143Z"
        fill="url(#paint4_linear_2596_999)"
      />
      <path
        d="M10.1678 14.7434L9.50388 15.6581L12.6198 17.8085L13.2837 16.8938L10.1678 14.7434Z"
        fill="url(#paint5_linear_2596_999)"
      />
      <path
        d="M12.5923 18.2615L12.1871 18.9326C11.8743 19.4499 11.256 19.5719 10.8118 19.2044L9.16149 17.8368C8.71729 17.4693 8.61309 16.7498 8.92581 16.2293L9.33106 15.5614L12.5923 18.2647L12.5923 18.2615Z"
        fill="url(#paint6_linear_2596_999)"
      />
      <path
        d="M6.15155 28.1596L4.05678 25.2074L13.5997 19.7618L15.9613 23.2714L6.15155 28.1596Z"
        fill="url(#paint7_linear_2596_999)"
      />
      <path
        d="M17.7873 20.894C17.9244 21.3015 17.7474 21.7574 17.347 21.9701L16.7677 22.2743L14.9862 19.0523L15.5656 18.7481C15.9659 18.5379 16.4551 18.6451 16.7291 18.9808C16.7624 19.0222 16.793 19.066 16.8184 19.1123L17.7219 20.7426C17.7498 20.7915 17.7699 20.8428 17.7874 20.8915L17.7873 20.894Z"
        fill="url(#paint8_linear_2596_999)"
      />
      <path
        d="M14.7066 18.9985L16.9838 22.7253L15.7224 23.3294L15.7191 23.3264L13.442 19.5996L14.7066 18.9985Z"
        fill="url(#paint9_linear_2596_999)"
      />
      <path
        d="M4.28813 24.8318L3.29569 25.3542L5.07738 28.5754L6.06982 28.053L4.28813 24.8318Z"
        fill="url(#paint10_linear_2596_999)"
      />
      <path
        d="M4.63108 28.847L4.05171 29.1512C3.60133 29.3912 3.03993 29.2267 2.79634 28.7845L1.89549 27.1542C1.65183 26.717 1.81734 26.1666 2.27029 25.9292L2.84966 25.625L4.63112 28.8445L4.63108 28.847Z"
        fill="url(#paint11_linear_2596_999)"
      />
      <path
        d="M5.35581 19.515C5.14958 20.0668 4.58979 20.3243 4.10767 20.0882L2.31849 19.2085C1.83905 18.9724 1.61406 18.3348 1.8203 17.7831L2.08814 17.0719L5.62365 18.8069L5.35849 19.5181L5.35581 19.515Z"
        fill="url(#paint12_linear_2596_999)"
      />
      <path
        d="M6.31944 17.4297L5.87106 18.6244L1.74357 16.8019L2.24107 15.6441L6.31944 17.4297Z"
        fill="url(#paint13_linear_2596_999)"
      />
      <path
        d="M6.14428 17.8479L2.5197 16.2335L6.65501 6.20806L10.2796 7.8224L9.71393 9.19146C9.65353 9.13156 9.59037 9.08878 9.51623 9.05455C8.7007 8.69232 7.34972 10.0671 6.4985 12.1292C5.65002 14.1914 5.62256 16.1594 6.43809 16.5216C6.51223 16.553 6.58911 16.573 6.67149 16.5758L6.14702 17.8479H6.14428Z"
        fill="url(#paint14_linear_2596_999)"
      />
      <path
        d="M9.5036 8.73548L6.45281 16.2959C6.37028 16.293 6.29326 16.2725 6.21898 16.2404C5.40195 15.8696 5.42946 13.8546 6.2795 11.7433C7.13229 9.63198 8.48575 8.22444 9.30278 8.59531C9.37706 8.63035 9.44308 8.67415 9.50085 8.73548H9.5036Z"
        fill="url(#paint15_linear_2596_999)"
      />
      <path
        d="M11.0556 6.47852L10.5244 7.76003L6.39964 5.93437L6.93086 4.65608L11.0556 6.47852Z"
        fill="url(#paint16_linear_2596_999)"
      />
      <path
        d="M10.9785 5.49763L10.7107 6.20858L7.17584 4.47409L7.44363 3.76314C7.62841 3.26976 8.09437 3.01234 8.53354 3.13492C8.5871 3.14718 8.64066 3.16556 8.69154 3.19314L10.4804 4.06958C10.5339 4.0941 10.5848 4.12781 10.6277 4.16458C11.0053 4.44958 11.1633 5.00731 10.9785 5.49763Z"
        fill="url(#paint17_linear_2596_999)"
      />
      <path
        d="M11.1022 4.63931L11.0931 4.65654C11.0447 4.62208 10.9872 4.59049 10.9267 4.56751L8.90613 3.74619C8.84866 3.72035 8.78816 3.70311 8.72766 3.69163L8.73372 3.6744L10.7876 0.865817C10.8481 0.785408 10.963 0.753819 11.0598 0.794023L11.6951 1.05248C11.7919 1.09269 11.8463 1.19033 11.8282 1.29084L11.1052 4.63931H11.1022Z"
        fill="url(#paint18_linear_2596_999)"
      />
      <path
        d="M20.8254 17.4915C20.6857 17.2432 20.3986 17.1268 20.1115 17.2044L16.9765 18.1976C16.5264 17.8329 15.8978 17.7631 15.3779 18.0579L4.21145 24.2736L3.92434 24.4366L3.80794 24.4986L3.74586 24.5374L2.0542 25.4764C1.72053 25.6626 1.47221 25.9653 1.37133 26.3377C1.27046 26.7025 1.31702 27.0827 1.50325 27.4164L2.54308 29.271C2.80691 29.7366 3.29579 30.0004 3.79242 30.0004C4.03298 30.0004 4.26577 29.9383 4.49081 29.8219L17.8223 22.4112C18.3422 22.1241 18.6138 21.5499 18.5362 20.9834L21.0349 18.8339C21.2444 18.6399 21.291 18.3373 21.1513 18.089L20.8254 17.5147V17.4915ZM4.11058 29.1235C3.79242 29.302 3.39667 29.1856 3.21819 28.8752L2.17836 27.0206C2.093 26.8732 2.06972 26.7025 2.11628 26.5395C2.16284 26.3765 2.27148 26.2369 2.42668 26.1515L2.73707 25.9808L4.42097 28.9606L4.11058 29.1313V29.1235ZM5.09608 28.5726L3.41219 25.5928L3.84674 25.3522L5.53063 28.3243L5.09608 28.5648V28.5726ZM7.43957 27.2689C7.43181 27.1836 7.43181 27.0982 7.46285 26.9818C7.64132 26.3377 8.54147 25.2979 10.14 24.4133C11.9946 23.3812 13.5388 23.2726 13.9967 23.6218L7.43957 27.2689ZM14.6951 23.2415C13.9346 22.2095 11.6299 22.6984 9.76753 23.7382C8.169 24.6306 6.99725 25.7945 6.72566 26.7801C6.63254 27.0982 6.64806 27.4008 6.74894 27.6647L6.20574 27.9673L4.52185 24.9875L13.6552 19.9048L15.3391 22.8846L14.6873 23.2493L14.6951 23.2415ZM16.022 22.4966L14.3381 19.5168L14.7649 19.2762L16.4488 22.256L16.022 22.4966ZM17.4421 21.7051L17.1317 21.8758L15.4478 18.896L15.7504 18.7253C16.0298 18.5701 16.379 18.6399 16.5962 18.896C16.6195 18.9193 16.635 18.9503 16.6506 18.9736L17.6904 20.8127C17.7137 20.8515 17.7292 20.8903 17.7447 20.9291C17.8456 21.224 17.7214 21.5499 17.4498 21.6973L17.4421 21.7051ZM18.2258 20.1997L17.4653 18.8572L18.8388 18.4227L20.2123 17.9881L20.4063 18.3295L18.2258 20.1997Z"
        fill="#121112"
      />
      <path
        d="M22.7802 1.35037L22.237 0.954613C22.0042 0.791655 21.6938 0.807175 21.4765 0.993412L19.0399 3.18946C18.4812 3.04202 17.8759 3.24378 17.519 3.72489L8.51751 15.97C8.29247 16.2804 8.19935 16.6529 8.26143 17.0331C8.32351 17.4056 8.52527 17.7392 8.83566 17.9643L10.5661 19.2136C10.8222 19.3999 11.1171 19.4852 11.4042 19.4852C11.8465 19.4852 12.2811 19.2835 12.5682 18.9032L21.5696 6.65813C21.9188 6.17702 21.9111 5.55623 21.6084 5.08287L22.9742 2.10308C23.0983 1.83924 23.013 1.52884 22.7802 1.35813V1.35037ZM11.8931 12.6953L15.416 7.89971C15.9903 7.51948 16.3317 7.48844 16.4326 7.55828C16.4792 7.59708 16.5024 7.69019 16.5102 7.74451C16.5956 8.33426 16.0989 9.70776 14.9815 11.2132C13.5692 13.1066 12.1724 13.8826 11.8698 13.6575C11.7844 13.5955 11.7223 13.2773 11.8853 12.6953H11.8931ZM11.9319 18.4376C11.7146 18.7248 11.3033 18.7946 11.0162 18.5851L9.28574 17.3357C9.14606 17.2349 9.05294 17.0874 9.02966 16.9167C8.99862 16.746 9.04518 16.5753 9.14606 16.4356L9.35558 16.1485L12.1414 18.1583L11.9319 18.4376ZM12.5992 17.5297L9.81341 15.5199L10.1083 15.1242L12.8941 17.134L12.607 17.5297H12.5992ZM13.3519 16.5132L10.5661 14.5034L11.086 13.7972C11.1636 14.03 11.2878 14.1852 11.4197 14.2861C11.5827 14.4025 11.7767 14.4646 11.9862 14.4646C13.0027 14.4646 14.4849 13.192 15.61 11.6788C16.7663 10.1346 17.4181 8.54378 17.2862 7.62812C17.2319 7.24012 17.0456 7.03836 16.8982 6.92973C16.7507 6.82109 16.549 6.74349 16.2619 6.75901L16.7352 6.11494L19.521 8.13251L13.3519 16.5287V16.5132ZM19.9789 7.4962L17.1931 5.47863L17.4802 5.09063L20.266 7.1082L19.9789 7.4962ZM20.9333 6.19254L20.7238 6.47965L17.938 4.46208L18.1398 4.18273C18.326 3.92665 18.6752 3.84129 18.9468 3.97321C18.9778 3.98873 19.0166 4.00425 19.0554 4.03529L20.7936 5.28463C20.7936 5.28463 20.8557 5.33119 20.8712 5.35447C21.0885 5.5795 21.1118 5.9287 20.9256 6.18478L20.9333 6.19254ZM21.0109 4.49312L19.7538 3.58521L21.8878 1.66076L22.206 1.89356L21.0109 4.49312Z"
        fill="#121112"
      />
      <path
        d="M12.0496 0.970132C12.0961 0.690776 11.9487 0.41918 11.6849 0.310542L11.0641 0.0467055C10.8002 -0.0619329 10.4976 0.0234258 10.3269 0.256222L8.45674 2.93338C7.8825 2.9101 7.33155 3.24378 7.09876 3.78697L6.30725 5.62606L1.08484 17.7237C0.937406 18.0729 0.929646 18.4609 1.06932 18.8101C1.209 19.1671 1.4806 19.4387 1.82979 19.5939L3.79304 20.4319C3.97928 20.5095 4.16552 20.5483 4.35176 20.5483C4.91047 20.5483 5.43814 20.2224 5.67094 19.687L11.6849 5.75798C11.9254 5.21479 11.7935 4.594 11.3822 4.19049L12.0496 0.977892V0.970132ZM4.97255 19.3766C4.83287 19.7025 4.44488 19.8577 4.10344 19.718L2.14019 18.8877C1.97723 18.8179 1.86083 18.6937 1.79099 18.5308C1.72891 18.3678 1.72891 18.1971 1.79099 18.0341L1.93067 17.7082L5.10447 19.0584L4.96479 19.3766H4.97255ZM5.41486 18.3445L2.24107 16.9943L2.43506 16.5442L5.6011 17.8944L5.4071 18.3368L5.41486 18.3445ZM6.47021 15.8924C6.40813 15.8303 6.35381 15.7527 6.30725 15.6441C6.05893 15.0233 6.12877 13.6575 6.85044 11.9969C7.70403 10.0104 8.85249 9.00937 9.42672 9.04041L6.46245 15.9002L6.47021 15.8924ZM9.75264 8.30322C8.51882 7.93851 6.98236 9.7388 6.14429 11.6943C5.42262 13.3549 5.2131 14.9845 5.59334 15.939C5.7175 16.2494 5.91149 16.4821 6.15981 16.6296L5.91925 17.1961L2.75322 15.8458L6.87372 6.30117L10.0398 7.65139L9.75264 8.31098V8.30322ZM10.3424 6.92973L7.17636 5.5795L7.37035 5.13719L10.5364 6.48741L10.3424 6.93748V6.92973ZM10.9865 5.44759L10.8468 5.76574L7.68075 4.42328L7.82043 4.10513C7.94458 3.81801 8.25498 3.65505 8.5809 3.73265C8.61969 3.74041 8.65073 3.74817 8.68177 3.76369L10.645 4.60176C10.645 4.60176 10.7149 4.64056 10.7459 4.65608C11.0097 4.8268 11.1106 5.16047 10.9865 5.44759ZM10.6838 3.77145L9.25601 3.16618L10.9011 0.822694L11.2658 0.977892L10.6838 3.77921V3.77145Z"
        fill="#121112"
      />
    </g>
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_2596_999"
        x1="12.0001"
        x2="12.0001"
        y1="30.0007"
        y2="0"
      >
        <stop stopColor="#A0DCFF" />
        <stop offset="0.7359" stopColor="#56BB6C" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_2596_999"
        x1="12.0001"
        x2="12.0001"
        y1="30.0007"
        y2="0"
      >
        <stop stopColor="#A0DCFF" />
        <stop offset="0.7359" stopColor="#56BB6C" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint2_linear_2596_999"
        x1="12.0001"
        x2="12.0001"
        y1="30.0007"
        y2="0"
      >
        <stop stopColor="#A0DCFF" />
        <stop offset="0.7359" stopColor="#56BB6C" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint3_linear_2596_999"
        x1="12.0001"
        x2="12.0001"
        y1="30.0007"
        y2="0"
      >
        <stop stopColor="#A0DCFF" />
        <stop offset="0.7359" stopColor="#56BB6C" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint4_linear_2596_999"
        x1="12.0001"
        x2="12.0001"
        y1="30.0007"
        y2="0"
      >
        <stop stopColor="#A0DCFF" />
        <stop offset="0.7359" stopColor="#56BB6C" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint5_linear_2596_999"
        x1="12.0001"
        x2="12.0001"
        y1="30.0007"
        y2="0"
      >
        <stop stopColor="#A0DCFF" />
        <stop offset="0.7359" stopColor="#56BB6C" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint6_linear_2596_999"
        x1="12.0001"
        x2="12.0001"
        y1="30.0007"
        y2="0"
      >
        <stop stopColor="#A0DCFF" />
        <stop offset="0.7359" stopColor="#56BB6C" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint7_linear_2596_999"
        x1="12.0002"
        x2="12.0002"
        y1="30.0003"
        y2="-2.62309"
      >
        <stop offset="0.239141" stopColor="#FFD43E" />
        <stop offset="1" stopColor="#FF6D4E" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint8_linear_2596_999"
        x1="12.0002"
        x2="12.0002"
        y1="30.0003"
        y2="-2.62309"
      >
        <stop offset="0.239141" stopColor="#FFD43E" />
        <stop offset="1" stopColor="#FF6D4E" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint9_linear_2596_999"
        x1="12.0002"
        x2="12.0002"
        y1="30.0003"
        y2="-2.62309"
      >
        <stop offset="0.239141" stopColor="#FFD43E" />
        <stop offset="1" stopColor="#FF6D4E" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint10_linear_2596_999"
        x1="12.0002"
        x2="12.0002"
        y1="30.0003"
        y2="-2.62309"
      >
        <stop offset="0.239141" stopColor="#FFD43E" />
        <stop offset="1" stopColor="#FF6D4E" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint11_linear_2596_999"
        x1="12.0002"
        x2="12.0002"
        y1="30.0003"
        y2="-2.62309"
      >
        <stop offset="0.239141" stopColor="#FFD43E" />
        <stop offset="1" stopColor="#FF6D4E" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint12_linear_2596_999"
        x1="9.78124"
        x2="17.783"
        y1="32.1141"
        y2="-1.25792"
      >
        <stop offset="0.0795732" stopColor="#FF6D4E" />
        <stop offset="0.772297" stopColor="#FED7FF" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint13_linear_2596_999"
        x1="9.78124"
        x2="17.783"
        y1="32.1141"
        y2="-1.25792"
      >
        <stop offset="0.0795732" stopColor="#FF6D4E" />
        <stop offset="0.772297" stopColor="#FED7FF" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint14_linear_2596_999"
        x1="9.78124"
        x2="17.783"
        y1="32.1141"
        y2="-1.25792"
      >
        <stop offset="0.0795732" stopColor="#FF6D4E" />
        <stop offset="0.772297" stopColor="#FED7FF" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint15_linear_2596_999"
        x1="9.78124"
        x2="17.783"
        y1="32.1141"
        y2="-1.25792"
      >
        <stop offset="0.0795732" stopColor="#FF6D4E" />
        <stop offset="0.772297" stopColor="#FED7FF" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint16_linear_2596_999"
        x1="9.78124"
        x2="17.783"
        y1="32.1141"
        y2="-1.25792"
      >
        <stop offset="0.0795732" stopColor="#FF6D4E" />
        <stop offset="0.772297" stopColor="#FED7FF" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint17_linear_2596_999"
        x1="9.78124"
        x2="17.783"
        y1="32.1141"
        y2="-1.25792"
      >
        <stop offset="0.0795732" stopColor="#FF6D4E" />
        <stop offset="0.772297" stopColor="#FED7FF" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint18_linear_2596_999"
        x1="9.78124"
        x2="17.783"
        y1="32.1141"
        y2="-1.25792"
      >
        <stop offset="0.0795732" stopColor="#FF6D4E" />
        <stop offset="0.772297" stopColor="#FED7FF" />
      </linearGradient>
      <clipPath id="clip0_2596_999">
        <rect
          fill="white"
          height="30.0004"
          transform="translate(0.969238)"
          width="22.0619"
        />
      </clipPath>
      <clipPath id="clip1_2596_999">
        <rect
          fill="white"
          height="3.40733"
          transform="translate(16.7759 17.3135)"
          width="4.13047"
        />
      </clipPath>
    </defs>
  </svg>
)

export default CrayonsDark
