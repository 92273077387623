const UpsideDownSmileDark = () => (
  <svg
    fill="none"
    height="31"
    viewBox="0 0 31 31"
    width="31"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.6365 26.9308C21.7357 26.9308 26.7106 21.912 26.7106 15.6817C26.7106 9.45147 21.7698 4.43266 15.6365 4.43266C9.50314 4.43266 4.5624 9.45147 4.5624 15.6817C4.5624 21.912 9.53722 26.9308 15.6365 26.9308Z"
      fill="url(#paint0_linear_253_20567)"
    />
    <path
      d="M15.5 26.9913C9.16222 26.9913 4 21.8341 4 15.4913C4 9.14861 9.16222 4 15.5 4C21.8378 4 27 9.15726 27 15.5C27 21.8427 21.8378 27 15.5 27V26.9913ZM15.5 4.86531C9.63074 4.86531 4.85185 9.63318 4.85185 15.5C4.85185 21.3668 9.63074 26.1347 15.5 26.1347C21.3693 26.1347 26.1481 21.3668 26.1481 15.5C26.1481 9.63318 21.3693 4.86531 15.5 4.86531Z"
      fill="#121112"
    />
    <path
      d="M21.9828 15.9327C21.7442 15.9327 21.5568 15.7423 21.5568 15.5C21.5568 13.8905 20.9265 12.3762 19.785 11.234C18.6435 10.0918 17.1272 9.46013 15.5087 9.46013C13.8902 9.46013 12.3739 10.0918 11.2324 11.234C10.0909 12.3762 9.46055 13.8905 9.46055 15.5C9.46055 15.7423 9.27314 15.9327 9.03462 15.9327C8.7961 15.9327 8.6087 15.7423 8.6087 15.5C8.6087 13.6569 9.32425 11.9263 10.6361 10.6196C11.948 9.31303 13.6772 8.59482 15.5172 8.59482C17.3572 8.59482 19.095 9.31303 20.3898 10.6196C21.6931 11.9349 22.4172 13.6655 22.4172 15.5C22.4172 15.7423 22.2298 15.9327 21.9913 15.9327H21.9828Z"
      fill="#121112"
    />
    <path
      d="M18.8558 17.7758C18.2936 17.7758 17.8336 18.2344 17.8336 18.7968C17.8336 19.3593 18.2936 19.8179 18.8558 19.8179C19.418 19.8179 19.878 19.3593 19.878 18.7968C19.878 18.2344 19.418 17.7758 18.8558 17.7758ZM12.1432 17.7758C11.5725 17.7758 11.121 18.2344 11.121 18.7968C11.121 19.3593 11.581 19.8179 12.1432 19.8179C12.7054 19.8179 13.1654 19.3593 13.1654 18.7968C13.1654 18.2344 12.7054 17.7758 12.1432 17.7758Z"
      fill="#121112"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_253_20567"
        x1="13.1823"
        x2="17.8088"
        y1="28.6188"
        y2="2.38115"
      >
        <stop offset="0.08" stopColor="#FF6D4E" />
        <stop offset="0.77" stopColor="#FED7FF" />
      </linearGradient>
    </defs>
  </svg>
)

export default UpsideDownSmileDark
