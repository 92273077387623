import { Component } from 'react'
import PropTypes from 'prop-types'
import { bowserFlags } from 'lib/bowser'
import cx from 'classnames'

import * as blUrls from 'lib/urls'
import Tappable from 'react-tappable'
import withCurrentUser from 'shared/HOC/withCurrentUser'
import { tracking, track, withContextualizedTracking } from 'lib/analytics'

import { SHOW_MOBILE_NAV_EVENT } from 'shared/constants'
import css from './mobile-tab-bar.scss'

const HOME_CTA = 'Home'
const REGISTRY_CTA = 'Registry'
const SIGN_UP_CTA = 'Sign Up'
const ADD_ITEMS_CTA = 'Add Items'
const GUIDES_CTA = 'Guides'
const SHOP_CTA = 'Shop'

@withContextualizedTracking(() => ({
  eventLocation: track.EventLocation.MOBILE_WEB_BOTTOM_NAV,
}))
class MobileTabBar extends Component {
  constructor(props) {
    super(props)
    const { isVisible } = props
    this.state = { isVisible: isVisible || false }
  }

  componentDidMount() {
    PubSub.subscribe(SHOW_MOBILE_NAV_EVENT, (event) => {
      if (bowserFlags.mobile) {
        this.setState({ isVisible: true })
      }
    })

    // bootstrap
    setTimeout(() => {
      PubSub.publish(SHOW_MOBILE_NAV_EVENT)
    }, 1)
  }

  handleSignUpClick = () => {
    window.location.href = `${blUrls.newRegistryPath}?loc=${track.EventLocation.MOBILE_WEB_BOTTOM_NAV}`
  }

  @tracking((state, props, [cta]) => ({
    event: track.mobileTabBarClicked,
    eventCta: cta,
    eventLocation: track.EventLocation.MOBILE_WEB_BOTTOM_NAV,
    eventType: track.EventType.CLICK,
  }))
  handleOnClick = (_cta, href = undefined) => {
    if (!href || typeof window === 'undefined') {
      return
    }

    window.location.href = href
  }

  render() {
    const { isVisible } = this.state
    if (!isVisible) {
      return null
    }
    const {
      isHomePage,
      isAddItemsPage,
      isRegistryPage,
      isHelloBabyPage,
      isStorePage,
      currentUser,
    } = this.props
    const homeUrl = currentUser ? blUrls.registryHomePath : blUrls.indexPath
    const registryUrl = currentUser
      ? blUrls.registryPath(currentUser?.currentRegistry?.urlSlug)
      : blUrls.whyBabyListPath

    return (
      <div className={css.affixMobileNav} data-testid="MobileTabBar">
        <div className={css.mobileMenuContainer}>
          <Tappable
            className={cx({ [css.active]: isHomePage, [css.tabButton]: true })}
            component="a"
            role="link"
            onTap={() => this.handleOnClick(HOME_CTA, homeUrl)}
          >
            <div className={cx(css.iconMobileNav, css.iconHome)} />
            <span className={css.iconText}>{HOME_CTA}</span>
          </Tappable>
          <Tappable
            className={cx({
              [css.active]: isRegistryPage,
              [css.tabButton]: true,
            })}
            component="a"
            role="link"
            onTap={() => this.handleOnClick(REGISTRY_CTA, registryUrl)}
          >
            <div className={cx(css.iconMobileNav, css.iconRegistry)} />
            <span className={css.iconText}>{REGISTRY_CTA}</span>
          </Tappable>
          {!currentUser ? (
            <Tappable
              onTap={() => {
                this.handleSignUpClick()
                this.handleOnClick(SIGN_UP_CTA)
              }}
            >
              <div className={css.signUpButton}>
                <div className={cx(css.iconSignUpHeart)} />
                <span>{SIGN_UP_CTA}</span>
              </div>
            </Tappable>
          ) : (
            <Tappable
              className={cx({
                [css.active]: isAddItemsPage,
                [css.tabButton]: true,
              })}
              component="a"
              role="link"
              onTap={() =>
                this.handleOnClick(ADD_ITEMS_CTA, blUrls.registryAddItemsPath)
              }
            >
              <div className={cx(css.iconMobileNav, css.iconAnyStore)} />
              <span className={css.iconText}>{ADD_ITEMS_CTA}</span>
            </Tappable>
          )}
          <Tappable
            className={cx({
              [css.active]: isHelloBabyPage,
              [css.tabButton]: true,
            })}
            component="a"
            role="link"
            onTap={() => this.handleOnClick(GUIDES_CTA, blUrls.helloBabyPath)}
          >
            <div className={cx(css.iconMobileNav, css.iconGuides)} />
            <span className={css.iconText}>{GUIDES_CTA}</span>
          </Tappable>
          <Tappable
            className={cx({ [css.active]: isStorePage, [css.tabButton]: true })}
            component="a"
            role="link"
            onTap={() => this.handleOnClick(SHOP_CTA, blUrls.catalogPath)}
          >
            <div className={cx(css.iconMobileNav, css.iconStore)} />
            <span className={css.iconText}>{SHOP_CTA}</span>
          </Tappable>
        </div>
      </div>
    )
  }
}

MobileTabBar.propTypes = {
  isRegistryPage: PropTypes.bool,
  isAddItemsPage: PropTypes.bool,
  isHelloBabyPage: PropTypes.bool,
  isHomePage: PropTypes.bool,
  isStorePage: PropTypes.bool,
  isVisible: PropTypes.bool,
  currentUser: PropTypes.object,
}

export default withCurrentUser(MobileTabBar)
