import React, { useState } from 'react'
import { RecordContributionProps } from 'registry/components/CrowdfundReserveFormFlow/components/RecordContribution/RecordContribution.types'
import ContributionInfo from 'registry/components/CrowdfundReserveFormFlow/components/RecordContribution/components/ContributionInfo/ContributionInfo'
import { Reservation } from 'registry/components/CrowdfundReserveFormFlow/CrowdfundReserveFormFlow.types'
import { ERROR_MESSAGES } from 'registry/components/CrowdfundReserveFormFlow/components/RecordContribution/constants'
import CrowdfundReserveModalTitle from 'registry/components/CrowdfundReserveFormFlow/components/CrowdfundReserveModalTitle/CrowdfundReserveModalTitle'
import { email as isInvalidEmail } from 'lib/form-validation'
import { track, useTracking } from 'lib/analytics'
import { Button, TextLink } from '../../../../../baby-design'
import css from './RecordContribution.styles.scss'
import EditContributionInfoForm from './components/EditContributionInfoForm/EditContributionInfoForm'

const RecordContribution: React.FC<RecordContributionProps> = ({
  isLoading = false,
  onCancel,
  onPaymentPreferenceUpdate,
  onReservationUpdate,
  paymentPreferences,
  registryFirstNames,
  reservation,
  selectedPreference,
  regItem,
}) => {
  const tracker = useTracking()
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [changes, setChanges] = useState<any>({})
  const [errors, setErrors] = useState<any>()

  const handleUpdate = (fieldChanges: Partial<Reservation>) => {
    setErrors(undefined)
    setChanges({ ...changes, ...fieldChanges })
  }

  const trackPurchaseConfirmed = () => {
    tracker.trackEvent({
      event: track.purchaseConfirmed,
      eventType: track.EventType.MARK_AS_PURCHASED,
      regItemIds: [regItem?.id],
      regItemQuantity: reservation.quantity,
      registry: regItem?.registry,
    })
  }

  const handleSubmit = () => {
    if (isLoading) return

    const currentErrors: Record<string, string> = {}

    // check presence of required values
    Object.keys(changes).forEach((key) => {
      if (changes[key] && changes[key] !== '') return
      currentErrors[key] = ERROR_MESSAGES[key]
    })

    // ensure email follows a valid format
    const emailFieldKey = 'email'
    const email = changes[emailFieldKey] || reservation.email
    if (isInvalidEmail(email)) {
      currentErrors[emailFieldKey] = ERROR_MESSAGES[emailFieldKey]
    }

    if (Object.keys(currentErrors).length === 0) {
      trackPurchaseConfirmed()
      onReservationUpdate?.({ ...reservation, ...changes })
    } else {
      setErrors(currentErrors)
    }
  }

  return (
    <div className={css.RecordContribution}>
      <CrowdfundReserveModalTitle>
        Record your contribution
      </CrowdfundReserveModalTitle>
      <div className={css.RecordContribution__Details}>
        <p>
          Verify the info below and we'll let {registryFirstNames} know who to
          thank.
        </p>
        {isEditing ? (
          <EditContributionInfoForm
            errors={errors}
            paymentPreferences={paymentPreferences}
            reservation={{ ...reservation, ...changes } as Reservation}
            selectedPreference={selectedPreference}
            onPaymentOptionSelectionChanged={onPaymentPreferenceUpdate}
            onReservationUpdated={handleUpdate}
          />
        ) : (
          <ContributionInfo
            paymentPreference={selectedPreference}
            reservation={reservation}
            onEdit={() => setIsEditing(true)}
          />
        )}
      </div>
      <div className={css.RecordContribution__Buttons}>
        <Button
          className={css.RecordContribution__Buttons__Submit}
          loading={isLoading}
          size="md"
          variant="primary"
          onClick={handleSubmit}
        >
          I sent this gift
        </Button>
        <TextLink
          underline
          size="sm"
          variant="default"
          onClick={() => onCancel?.()}
        >
          I decided not to contribute
        </TextLink>
      </div>
    </div>
  )
}

export default RecordContribution
