import { find, values, maxBy } from 'lodash'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal } from 'components/modal'
import { ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap'
import { InfoButton } from 'components/buttons'
import GiftMessageCard from 'registry/gift-reservations/gift-message-card'
import {
  getReservationsByCurrentVisitor,
  getFirstNames,
} from 'registry/reducers'
import {
  openGiftMessageModal,
  openGiftTrackerModal,
} from 'registry/modals/actions'
import { updateGiftMessage } from 'registry/actions'
import { getGiftMessage } from 'cart/reducers/reservations-reducer'
import { GiftCountExpander } from 'registry/gift-reservations/gift-giver-wrapper'
import dateformat from 'dateformat'
import ReservationCard from './components/reservation-card'
import css from './gift-tracker-modal.styles.scss'

export const GiftTrackerModal = ({
  reservations,
  firstNames,
  onHide,
  reservationWithGiftMessage,
  openGiftMessage,
  openGiftTracker,
  giftMessage,
  saveGiftMessage,
  onDeleteReservation,
}) => {
  const ggName = reservations[0].name
  const ggEmail = reservations[0].email
  const latestDate = maxBy(reservations, (res) => res.createdAt).createdAt
  const handleUpdateGiftMessage = (data) => {
    saveGiftMessage(data).then(openGiftTracker())
  }

  const handleOpenGiftMessageModal = () => {
    openGiftMessage(giftMessage, handleUpdateGiftMessage)
  }

  return (
    <Modal show size="small" onHide={onHide}>
      <ModalHeader className="with-title" closeButton onHide={onHide}>
        <ModalTitle className="h3" style={{ textAlign: 'center' }}>
          Ta-da! 🎉 You're done!
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <p className={classNames(['text-center', 'pbl', css.tadaDetails])}>
          If you marked your gift a surprise, this information will be hidden on{' '}
          {firstNames}'s Gift Tracker. Otherwise, here's what they'll see.
        </p>
        <section className="flex pbl">
          <div className="flex-grow">
            <span className="h7 mtn mbs">
              {dateformat(latestDate, 'longDate')}
            </span>
            <div className="h5 text-bold mvn">{ggName}</div>
            <div className="h7 mvn">{ggEmail}</div>
          </div>
          <div>
            <GiftCountExpander reservations={reservations} />
          </div>
        </section>
        <GiftMessageCard onEdit={handleOpenGiftMessageModal}>
          {reservationWithGiftMessage?.giftMessage || `Add a gift message`}
        </GiftMessageCard>
        {reservations &&
          reservations.map((reservation) => {
            if (
              !reservation.regItem ||
              (!reservation.isPurchased && !reservation.regItem?.isFavor)
            ) {
              return false
            }
            return (
              <ReservationCard
                key={reservation.id}
                reservation={reservation}
                regItem={reservation.regItem}
                onDeleteReservation={onDeleteReservation}
              />
            )
          })}
        <InfoButton className="mtl" pill block onClick={onHide}>
          I'm Finished
        </InfoButton>
      </ModalBody>
    </Modal>
  )
}

GiftTrackerModal.propTypes = {
  reservations: PropTypes.arrayOf(PropTypes.object).isRequired,
  firstNames: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  reservationWithGiftMessage: PropTypes.object,
  openGiftMessage: PropTypes.func.isRequired,
  openGiftTracker: PropTypes.func.isRequired,
  saveGiftMessage: PropTypes.func.isRequired,
  giftMessage: PropTypes.string,
  onDeleteReservation: PropTypes.func,
}

GiftTrackerModal.defaultProps = {
  firstNames: 'the family',
  giftMessage: '',
}

const mapStateToProps = (state) => {
  const reservations = values(getReservationsByCurrentVisitor(state))
  const reservationWithGiftMessage = find(
    reservations,
    (res) => res.giftMessage
  )
  return {
    reservations,
    reservationWithGiftMessage,
    firstNames: getFirstNames(state),
    giftMessage: getGiftMessage(reservations),
  }
}

const mapDispatchToProps = (dispatch) => ({
  openGiftTracker: () => dispatch(openGiftTrackerModal()),
  openGiftMessage: (data, onSubmit) =>
    dispatch(openGiftMessageModal(data, onSubmit)),
  saveGiftMessage: (data) => dispatch(updateGiftMessage(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(GiftTrackerModal)
