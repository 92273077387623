import React from 'react'

interface BedBathAndBeyondStoreProps {
  className?: string
  rounded?: boolean
}

const BedBathAndBeyondStore: React.FC<BedBathAndBeyondStoreProps> = ({
  className,
  rounded = true,
}: BedBathAndBeyondStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {rounded ? (
      <rect fill="#1A4E8A" height="24" rx="12" width="24" />
    ) : (
      <rect fill="#1A4E8A" height="24" width="24" />
    )}
    <path
      clipRule="evenodd"
      d="M6 18.5H12.853C15.8168 18.5 18.1948 17.3747 18.1948 14.9601C18.1948 12.5455 15.641 11.8656 15.641 11.8656C17.6911 11.174 17.4802 8.80628 17.4802 8.80628C17.4802 5.36014 12.2907 5.5008 12.2907 5.5008H6V18.5ZM11.9744 10.588H10.7444V8.61874H11.9744C13.2864 8.61874 13.2864 10.588 11.9744 10.588ZM12.1501 13.2956H10.7444V15.4407H12.1501C13.9893 15.4407 13.9893 13.2956 12.1501 13.2956Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
)

export default BedBathAndBeyondStore
