import React from 'react'

interface CostcoStoreProps {
  className?: string
  rounded?: boolean
}

const CostcoStore: React.FC<CostcoStoreProps> = ({
  className,
  rounded = true,
}: CostcoStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {rounded ? (
      <rect fill="#FAFAFA" height="24" rx="12" width="24" />
    ) : (
      <rect fill="#FAFAFA" height="24" width="24" />
    )}
    <path
      d="M19.1026 5.09768C17.769 4.71193 16.2558 4.5 14.7478 4.5C9.37341 4.5 4.48864 7.89338 3.64203 12.1353C2.81469 16.2809 6.2572 19.5 11.4853 19.5C12.6771 19.5 15.1928 19.3266 16.3434 18.9225L17.6232 13.4843C16.3881 14.3141 15.068 14.8347 13.536 14.8347C11.5418 14.8347 10.0615 13.6004 10.3812 11.9991C10.697 10.4185 12.5768 9.16447 14.57 9.16447C16.0771 9.16447 17.2802 9.83963 18.1925 10.63L19.1026 5.09768Z"
      fill="#E31837"
    />
  </svg>
)

export default CostcoStore
