import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Input, Checkbox } from 'components/forms'
import Link from 'components/link'
import QuantityField from './quantity-field'

export default class OrderFields extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      hideStoreInput: this.props.hideStoreInput,
      originalStoreName: this.props.storeName.value,
      // We are re-adding the currently purchased quantity of the reservation to the remaining quantity needed to determine how much in total is still needed
      totalQuantityNeeded:
        this.props.quantityNeeded &&
        this.props.quantityNeeded + parseInt(this.props.quantity.value),
    }

    this.handleWrongStore = this.handleWrongStore.bind(this)
  }

  static propTypes = {
    isPurchasedPreloved: PropTypes.object.isRequired,
    onSecondhandChanged: PropTypes.func.isRequired,
    orderNumber: PropTypes.object.isRequired,
    showSecondhandCheckbox: PropTypes.bool,
    storeName: PropTypes.object.isRequired,
    orderNumberNotAvailable: PropTypes.object.isRequired,
  }

  static defaultProps = {
    hideStoreInput: false,
    showSecondhandCheckbox: false,
  }

  handleWrongStore() {
    this.setState({
      hideStoreInput: false,
      originalStoreName: '',
    })
  }

  render() {
    const {
      isPurchasedPreloved,
      storeName,
      onSecondhandChanged,
      orderNumber,
      orderNumberNotAvailable,
      registry,
      autofillStore,
      quantity,
      purchaseQuantityLimit,
      showPurchaseQuantityLimit,
      showSecondhandCheckbox,
    } = this.props

    return (
      <>
        {autofillStore && this.state.hideStoreInput ? (
          <Input type="hidden" {...storeName} />
        ) : (
          <>
            <Input
              label="Store Name"
              placeholder="(e.g. Amazon)"
              type="text"
              {...storeName}
            />
            {showSecondhandCheckbox && (
              <Checkbox
                {...isPurchasedPreloved}
                label="I am gifting this secondhand"
                onChange={onSecondhandChanged}
              />
            )}
          </>
        )}
        {this.state.totalQuantityNeeded > 1 && (
          <div className="mbl mtn">
            <QuantityField
              labelClassName="control-label"
              name="quantity"
              purchaseQuantityLimit={purchaseQuantityLimit}
              quantityNeeded={this.state.totalQuantityNeeded}
              showPurchaseQuantityLimit={showPurchaseQuantityLimit}
              {...quantity}
            />
          </div>
        )}
        <Input
          {...orderNumber}
          error={orderNumber.touched ? orderNumber.error : null}
          groupClassName="mbs"
          help="Not sure where to find it? Check your email from the store."
          label="Order Number"
          labelAddon={
            this.state.hideStoreInput ? (
              <Link
                className="tiny text-center text-uppercase"
                onClick={this.handleWrongStore}
              >
                Change Store
              </Link>
            ) : null
          }
          type="text"
        />
        <Checkbox
          {...orderNumberNotAvailable}
          error={
            orderNumberNotAvailable.touched
              ? orderNumberNotAvailable.error
              : null
          }
          label="I don't have an order number"
        />
      </>
    )
  }
}
