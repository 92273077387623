import { useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { numberToCurrency } from 'lib/money'
import { CONFIG } from 'shared/constants'
import { LinkButton } from 'components/buttons'
import { SHOULD_SAVE_REGISTRY_DISCOUNT_KEY } from 'cart/constants'
import { useTracking } from 'lib/analytics/track'
import { trackRegistryDiscountToggled } from 'src/routes/(shop)/lib/tracking'
import { CartAPI } from 'src/routes/(shop)/api/cart'
import css from './registry-discount-display.scss'

export const RegistryDiscountDisplay = ({
  amount,
  className,
  isDiscountActive,
  registryDiscountUsageMessage,
}) => {
  const { mutate: updateCart } = CartAPI.useUpdateMutation()
  const { trackEvent } = useTracking()
  const handleApply = (applyRegistryDiscount) => {
    trackRegistryDiscountToggled({
      trackEvent,
      regDiscountApplied: applyRegistryDiscount,
    })
    updateCart({ applyRegistryDiscount })
  }

  useEffect(() => {
    const saveDiscountForLater = localStorage.getItem(
      SHOULD_SAVE_REGISTRY_DISCOUNT_KEY
    )
    if (!saveDiscountForLater) {
      handleApply(true)
    }
  }, [])

  return (
    <div className={classNames(css.RegistryDiscountDisplay, className)}>
      <div className={css.RegistryDiscountDisplay__row}>
        <span>
          {CONFIG.store.registryDiscountPctDisplay} registry discount{' '}
          {isDiscountActive ? (
            <LinkButton
              className={css.RegistryDiscountDisplay__cta}
              onClick={() => handleApply(false)}
            >
              Remove
            </LinkButton>
          ) : (
            <LinkButton
              className={css.RegistryDiscountDisplay__cta}
              onClick={() => handleApply(true)}
            >
              Apply
            </LinkButton>
          )}
        </span>
        <span>-{numberToCurrency(amount)}</span>
      </div>
      {registryDiscountUsageMessage && (
        <div
          className={css.RegistryDiscountDisplay__message}
          data-testid="registry-discount-usage-message"
        >
          {registryDiscountUsageMessage.split('\n').map((line) => (
            <div key={line}>{line}</div>
          ))}
        </div>
      )}
    </div>
  )
}

RegistryDiscountDisplay.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.object, PropTypes.number]), // Big.js object
  className: PropTypes.string,
  isDiscountActive: PropTypes.bool.isRequired,
  registryDiscountUsageMessage: PropTypes.string,
}

RegistryDiscountDisplay.defaultProps = {
  className: undefined,
  amount: 0,
  registryDiscountUsageMessage: null,
}

export default RegistryDiscountDisplay
