import { map, fromPairs } from 'lodash'

import fetch from 'lib/fetch'
import {
  apiV3AmazonCartItemsPath,
  apiV3ReservationsPath,
  reservationPath,
  reservationsPath,
  unreserveReservationPath,
} from 'lib/urls'
import { SYSTEM_ERROR_KEY, UPDATE_CART_SUMMARY } from 'shared/constants'

import { errorMessage, infoMessage, successMessage } from 'lib/flash-message'
import {
  DID_NOT_BUY_CHANGED_MIND,
  DID_NOT_BUY_LOOKING,
  DID_NOT_BUY_NA,
  NEW_RESERVATION_ID,
  GIFT_MESSAGE_SEPARATOR,
} from '../constants'
import { getGiftMessageReservation } from '../reducers'

export const createReservation = (reservation) => {
  return (dispatch) => {
    return submitReservation(reservation).then((newReservation) => {
      dispatch(updateReservation(newReservation))
      dispatch(
        setUser({ email: newReservation.email, name: newReservation.name })
      )
    })
  }
}

export const submitReservation = (reservation) => {
  if (reservation.token === undefined || reservation.token === 'undefined') {
    const error = new Error('Reservation token is undefined')
    if (window.DD_RUM) {
      window.DD_RUM.addError(error, { reservation })
    }
    return Promise.reject(error)
  }

  const path =
    reservation.id == NEW_RESERVATION_ID
      ? reservationsPath
      : reservationPath(reservation.token)

  const method = reservation.id == NEW_RESERVATION_ID ? 'POST' : 'PUT'
  return new Promise((resolve, reject) => {
    fetch(path, {
      body: JSON.stringify({ reservation }),
      method,
    })
      .then((resp) => {
        resolve(resp.reservation)
      })
      .catch((resp) => {
        let errors = fromPairs(
          map(resp.errors, (error) => {
            return [error.id, error.title]
          })
        )
        if (errors.base) {
          errors = { _error: errors.base }
        }
        reject(
          errors || {
            [SYSTEM_ERROR_KEY]:
              'There was a problem reserving your gift. Please contact customer service if this problem persists. Sorry!',
          }
        )
      })
  })
}

export const INIT_REG_ITEMS_STATE = 'INIT_REG_ITEMS_STATE'
export const initRegItemsState = (regItems) => {
  return {
    type: INIT_REG_ITEMS_STATE,
    bootstrapRegItems: regItems,
  }
}

export const MARK_AS_PURCHASED = 'MARK_AS_PURCHASED'
export const markAsPurchased = (
  reservation,
  purchaseInfo,
  { showSuccessMessage = true } = {}
) => {
  return (dispatch) => {
    const updatedReservation = { ...reservation, ...purchaseInfo }

    return submitReservation(updatedReservation)
      .then((resp) => {
        const obj = resp
        dispatch({ type: UPDATE_RESERVATION, obj })
        if (showSuccessMessage) {
          let successMsg
          if (updatedReservation.isPurchased) {
            successMsg = `This item is now listed under your Order History.`
          } else {
            successMsg = `We've moved this gift back to your cart.`
          }

          successMessage(successMsg)
        }

        dispatch(updateReservation({ ...updatedReservation }))
        return updatedReservation
      })
      .catch((resp) => {
        // roll back to original reservation
        dispatch(updateReservation(reservation))
        errorMessage()
      })
  }
}

export const REMOVE_ALERT = 'REMOVE_ALERT'
export const removeAlert = (sectionName) => {
  return {
    type: REMOVE_ALERT,
    sectionName,
  }
}

export const SET_ACTIVE_MODAL = 'SET_ACTIVE_MODAL'
export const setActiveModal = (activeModal, modalProps = {}) => ({
  type: SET_ACTIVE_MODAL,
  activeModal,
  modalProps: activeModal ? modalProps : {},
}) // Clear ModalProps if clearing active modal

export const SET_APPLY_REGISTRY_DISCOUNT = 'SET_APPLY_REGISTRY_DISCOUNT'
export const setApplyRegistryDiscount = (applyRegistryDiscount) => {
  return {
    type: SET_APPLY_REGISTRY_DISCOUNT,
    applyRegistryDiscount,
  }
}

export const SET_AMAZON_CART = 'SET_AMAZON_CART'
export const setAmazonCart = (cart) => {
  return {
    type: SET_AMAZON_CART,
    cart,
  }
}

export const SET_CART = 'SET_CART'
export const setCart = (cart) => {
  return {
    type: SET_CART,
    cart,
  }
}

export const SET_PRODUCT_RECOMMENDATIONS = 'SET_PRODUCT_RECOMMENDATIONS'
export const setProductRecommendations = (productRecommendations) => ({
  type: SET_PRODUCT_RECOMMENDATIONS,
  productRecommendations,
})

export const SET_USER = 'SET_USER'
export const setUser = (obj) => {
  return {
    type: SET_USER,
    obj,
  }
}

export const SET_REGISTRY = 'SET_REGISTRY'
export const setRegistry = (registry) => {
  return {
    type: SET_REGISTRY,
    registry,
  }
}

export const SET_RESERVATIONS = 'SET_RESERVATIONS'
export const setReservations = (reservations) => {
  return {
    type: SET_RESERVATIONS,
    reservations,
  }
}

export const SHOW_PURCHASE_INFO_FORM = 'SHOW_PURCHASE_INFO_FORM'
export const showPurchaseInfoForm = (id, show) => {
  return {
    type: SHOW_PURCHASE_INFO_FORM,
    id,
    show,
  }
}

export const UPDATE_RESERVATION = 'UPDATE_RESERVATION'
export const updateReservation = (obj) => {
  return {
    type: UPDATE_RESERVATION,
    obj,
  }
}

export const CHANGE_STORE = 'CHANGE_STORE'
export const changeStore = (id, prevStore, store) => {
  return {
    type: CHANGE_STORE,
    id,
    prevStore,
    store,
  }
}

export const UPDATE_GIFT_MESSAGE = 'UPDATE_GIFT_MESSAGE'
export const updateGiftMessage = (giftMessage) => {
  const formattedGiftMessage = giftMessage.note
    ? `${giftMessage.note} ${GIFT_MESSAGE_SEPARATOR} ${giftMessage.name}`
    : null
  return (dispatch, getState) => {
    const res = getGiftMessageReservation(getState())
    return submitReservation({ ...res, note: formattedGiftMessage }).then(
      (resp) => {
        const obj = resp
        infoMessage('Your gift message has been updated!')
        dispatch({ type: UPDATE_RESERVATION, obj })
      }
    )
  }
}

export const UPDATE_GIFT_AMOUNT = 'UPDATE_GIFT_AMOUNT'
export const updateGiftAmount = (token, { giftAmount, productId }) => {
  return (dispatch) => {
    fetch(`${apiV3ReservationsPath}/${token}`, {
      body: JSON.stringify({ reservation: { giftAmount, productId } }),
      method: 'PUT',
    }).then((resp) => {
      const obj = resp.reservation
      dispatch({ type: UPDATE_RESERVATION, obj })
      PubSub.publish(UPDATE_CART_SUMMARY)
      infoMessage('Your gift card has been updated!')
    })
  }
}

export const UPDATE_QUANTITY = 'UPDATE_QUANTITY'
export const updateQuantity = (token, quantity) => {
  return (dispatch) => {
    fetch(`${apiV3ReservationsPath}/${token}`, {
      body: JSON.stringify({ reservation: { quantity } }),
      method: 'PUT',
    }).then((resp) => {
      const obj = resp.reservation
      dispatch({ type: UPDATE_RESERVATION, obj })
      PubSub.publish(UPDATE_CART_SUMMARY)
      infoMessage('Your reservation has been updated!')
    })
  }
}

export const submitRemoveReservation = (token) => {
  return new Promise((resolve, reject) => {
    fetch(unreserveReservationPath(token), {
      method: 'POST',
    })
      .then((resp) => {
        resolve(resp.reservation)
      })
      .catch((resp) => {
        const errors = fromPairs(
          map(resp.errors, (error) => {
            return [error.id, error.title]
          })
        )
        // if we send back a "base" error translate it into a keyed error that redux-form understands
        if (errors.base) {
          errors[SYSTEM_ERROR_KEY] = errors.base
        }
        reject(
          errors || {
            [SYSTEM_ERROR_KEY]:
              'There was a problem removing your reservation. Please contact customer service if this problem persists. Sorry!',
          }
        )
      })
  })
}

export const deleteAmazonCartItem = (id) => {
  return new Promise((resolve, reject) => {
    fetch(apiV3AmazonCartItemsPath(id), {
      method: 'DELETE',
    })
      .then((resp) => {
        resolve(resp.amazonCart)
      })
      .catch((resp) => {
        errorMessage()
        reject({ [SYSTEM_ERROR_KEY]: resp })
      })
  })
}

export const removeAmazonCartItem = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      deleteAmazonCartItem(id)
        .then((resp) => {
          dispatch(setAmazonCart(resp))
          resolve(resp)
        })
        .catch((resp) => {
          errorMessage()
          reject({ [SYSTEM_ERROR_KEY]: resp })
        })
    })
  }
}

export const updateAmazonCartItemQuantity = (id, quantity) => {
  return (dispatch) => {
    fetch(apiV3AmazonCartItemsPath(id), {
      body: JSON.stringify({ quantity }),
      method: 'PUT',
    })
      .then(({ amazonCart }) => {
        PubSub.publish(UPDATE_CART_SUMMARY)
        dispatch(setAmazonCart(amazonCart))
        infoMessage('Your Amazon cart has been updated!')
      })
      .catch(() => {
        errorMessage()
      })
  }
}

export const REMOVE_RESERVATION = 'REMOVE_RESERVATION'
export const removeReservation = (id) => {
  return {
    type: REMOVE_RESERVATION,
    id,
  }
}

export const cancelReservation = (reservation, reason) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (reason === DID_NOT_BUY_NA) {
        submitReservation({ ...reservation, isAvailable: false })
          .then(() => submitRemoveReservation(reservation.token))
          .then(() => {
            dispatch(removeReservation(reservation.id))
            dispatch(setActiveModal(null))
            PubSub.publish(UPDATE_CART_SUMMARY)
            resolve(reservation)
          })
          .catch((res) => {
            errorMessage()
            reject({ [SYSTEM_ERROR_KEY]: res })
          })
      } else if (
        !reason ||
        reason === DID_NOT_BUY_CHANGED_MIND ||
        reason === DID_NOT_BUY_LOOKING
      ) {
        submitRemoveReservation(reservation.token)
          .then((res) => {
            dispatch(removeReservation(res.id))
            dispatch(setActiveModal(null))
            PubSub.publish(UPDATE_CART_SUMMARY)
            resolve(reservation)
          })
          .catch((res) => {
            errorMessage()
            reject({ [SYSTEM_ERROR_KEY]: res })
          })
      }
    })
  }
}
