export const CART_ENDPOINT = '/health/api/v3/cart_items'
export const CATALOG_CATEGORY_ENDPOINT = (catalogId: number | string) =>
  `/health/api/v3/catalog_categories/${catalogId}/products`
export const CHECKOUT_ENDPOINT = '/api/v3/dme_checkouts'
export const CUSTOMER_ENDPOINT = '/api/v3/dme_customers'
export const CURRENT_CUSTOMER_ENDPOINT = '/api/v3/dme_customers/current'
export const CUSTOMER_INSURANCES_ENDPOINT = '/api/v3/dme_customer_insurances'
export const CUSTOMER_PHYSICIAN_ENDPOINT = '/api/v3/dme_customer_physicians'
export const CUSTOMER_PHYSICIAN_DETAILS_ENDPOINT =
  '/api/v3/dme_customer_physician_details'
export const INSURANCE_CATALOG_ENDPOINT = '/api/v3/dme_insurances'
export const PHYSICIAN_SEARCH_ENDPOINT = (query: string) =>
  `/api/v3/dme_physicians?limit=200&${query}`
export const OTP_VERIFICATION_ENDPOINT =
  '/api/v3/dme_customers/otp_verifications'
export const OTP_CREATION_ENDPOINT = '/api/v3/dme_customers/otp'
export const MEMBER_CARD_CREATION_ENDPOINT = (orderId: number | string) =>
  `/api/v3/dme_orders/${orderId}/member_cards`
export const LEAD_CREATION_ENDPOINT = '/api/v3/dme_leads'
export const SUBSCRIPTION_PRODUCTS_ENDPOINT = (subscriptionId: string) =>
  `/api/v3/dme_subscriptions/${subscriptionId}/products`
export const VERIFY_CUSTOMER_INSURANCE_ENDPOINT = (
  phiCustomerInsuranceToken: string
) => `/api/v3/dme_customer_insurances/${phiCustomerInsuranceToken}/verify`
export const UPDATE_DUE_DATE_ENDPOINT = (orderId: number | string) =>
  `/api/v3/dme_orders/${orderId}`

export const REPLACEMENT_PARTS_CATALOG_ENDPOINT = `/api/v3/dme_replacement_parts_catalog`

export const REPLACEMENT_PARTS_PRODUCT_PARTS_ENDPOINT = (productId: number) =>
  `/api/v3/dme_replacement_parts_catalog/${productId}/parts`

export const REQUEST_SUBSCRIPTION_LINK_ENDPOINT =
  '/api/v3/dme_existing_customer/send_subscription_status_link'

export const CART_ADD_ONS_ENDPOINT = '/api/v3/dme_cart_add_ons'

export const INSURANCE_VALIDATOR_CAROUSEL_PRODUCTS_ENDPOINT =
  '/api/v3/dme_contents/insurance_validator_carousel_products'

export const GUIDES_ENDPOINT = '/health/api/v3/guides'

export const POPULAR_PUMPS_ENDPOINT =
  '/api/v3/health_featured_products/popular_pumps'
