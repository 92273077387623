import { values } from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Col } from 'react-bootstrap'
import Image from 'components/image'
import Card from 'components/card'
import { InfoButton, LinkButton } from 'components/buttons'
import { getRegItem, getReservationsByCurrentVisitor } from 'registry/reducers'
import {
  openReservePurchasedGiftModal,
  openUnreserveModal,
} from 'registry/modals/actions'
import { successMessage } from 'lib/flash-message'
import { fetchCategorizedRegItems } from 'registry/actions'
import { submitReservation } from 'cart/actions'

export const ReservationCard = ({
  reservation,
  registry,
  regItem,
  openPurchaseInfoModal,
  openRemoveModal,
}) => {
  const {
    displayTitle,
    isEditable,
    orderNumber,
    storeName,
    printableUrl,
    quantity,
  } = reservation
  const { imgUrl = '' } = regItem || {}
  const title = displayTitle

  return (
    <Card className="pal mbl">
      <Col className="pln" xs={3}>
        <Image role="presentation" src={imgUrl} width="100%" />
      </Col>
      <Col xs={9}>
        <div className="h5">{title}</div>
        <div className="small">
          {!regItem?.isFavor && (
            <>
              <div className="text-bold">Purchase Details</div>
              <div>Qty: {quantity}</div>
              <div>Store: {storeName}</div>
              <div>Order #: {orderNumber}</div>
            </>
          )}
          <div>
            {isEditable && (
              <div className="flex align-center mtm">
                {!regItem?.isFavor && (
                  <InfoButton
                    empty
                    pill
                    size="small"
                    onClick={() =>
                      openPurchaseInfoModal(regItem, registry, reservation)
                    }
                  >
                    Edit Gift Details
                  </InfoButton>
                )}
                <div className="flex-grow align-end text-right">
                  <LinkButton
                    className="pan man"
                    opensize="small"
                    onClick={() => openRemoveModal(registry, reservation)}
                  >
                    Remove
                  </LinkButton>
                </div>
              </div>
            )}
            {regItem?.isFavor && (
              <InfoButton
                empty
                pill
                className="mtm"
                href={printableUrl}
                size="small"
              >
                Download PDF
              </InfoButton>
            )}
          </div>
        </div>
      </Col>
    </Card>
  )
}

ReservationCard.propTypes = {
  reservation: PropTypes.object,
  regItem: PropTypes.object,
  openPurchaseInfoModal: PropTypes.func.isRequired,
  onDeleteReservation: PropTypes.func,
}

const mapStateToProps = (state, ownProps) => ({
  registry: state.registry,
  regItem: getRegItem(state, ownProps.reservation.regItemId),
  reservations: values(getReservationsByCurrentVisitor(state)),
})

const mapDispatchToProps = (dispatch, ownProps) => {
  const handleRemoveSuccess = () => {
    successMessage(`${ownProps.reservation?.regItem?.title} has been removed.`)
    dispatch(fetchCategorizedRegItems())
    if (ownProps.onDeleteReservation) {
      ownProps.onDeleteReservation(ownProps.reservation, ownProps.regItem)
    }
  }
  return {
    openPurchaseInfoModal: (regItem, registry, reservation) => {
      dispatch(
        openReservePurchasedGiftModal(
          regItem,
          registry,
          submitReservation,
          reservation
        )
      )
    },
    openRemoveModal: (registry, reservation) => {
      dispatch(openUnreserveModal(handleRemoveSuccess, registry, reservation))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservationCard)
