// eslint-disable-next-line import/no-extraneous-dependencies
import { Switch, Tooltip } from 'baby-design'
import React from 'react'
import { BaseRegItemFieldsProps } from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/fields/RegItemFields.types'
import css from './SecondhandItem.style.scss'
import { Info } from '../../../../../../../../../baby-design/icons'

type EditItemDetailsSecondhandItemSettingProps = Pick<
  BaseRegItemFieldsProps,
  'regItemFields'
> & {
  updateRegItemFields: (changes: Record<string, unknown>) => void
}

const TOOLTIP_COPY = 'For safety reasons, register for new car seats only.'

const EditItemDetailsSecondhandItemSetting = ({
  regItemFields,
  updateRegItemFields,
}: EditItemDetailsSecondhandItemSettingProps) => {
  const { prelovedAllowed } = regItemFields

  return (
    <div className={css.SecondhandItem}>
      <div
        className={css.SecondhandItem__label}
        id="edit-item-details-secondhand-item-label"
      >
        <div className={css.SecondhandItem__heading}>
          <span>Open to secondhand</span>
          <Tooltip
            content={TOOLTIP_COPY}
            trigger={<Info className={css.SecondhandItem__Tooltip_Icon} />}
            triggerClassName={css.SecondhandItem__Tooltip_Trigger}
          />
        </div>
        <div className={css.SecondhandItem__subheading}>
          Accept used versions of this item.
        </div>
      </div>
      <Switch
        aria-labelledby="edit-item-details-secondhand-item-label"
        checked={prelovedAllowed}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          updateRegItemFields({ prelovedAllowed: e.target.checked })
        }
      />
    </div>
  )
}

export default EditItemDetailsSecondhandItemSetting
