import React from 'react'

interface SkipHopStoreProps {
  className?: string
  rounded?: boolean
}

const SkipHopStore: React.FC<SkipHopStoreProps> = ({
  className,
  rounded = true,
}: SkipHopStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {rounded ? (
      <rect fill="#EE4037" height="24" rx="12" width="24" />
    ) : (
      <rect fill="#EE4037" height="24" width="24" />
    )}
    <path
      clipRule="evenodd"
      d="M10.4155 4.5H13.8062V9.29894L17.9234 6.92187L19.6187 9.85824L15.5276 12.2202L19.7218 14.6418L18.0264 17.5781L13.8062 15.1415V20H10.4155V15.1717L6.19528 17.6082L4.49997 14.6719L8.74632 12.2202L4.60304 9.82812L6.29836 6.89176L10.4155 9.26881V4.5Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
)

export default SkipHopStore
