import { Helmet } from 'react-helmet'
import { babylistUrl, helloBabyPostPath } from '../../../lib/urls'

interface HelloBabyMetaProps {
  articleTags?: string[]
  canonical: string
  keywords?: string
  locales?: { locale: string; slug: string }[]
  noindex?: boolean
  seoDescription: string
  seoTitle?: string
  socialDescription?: string
  socialImage?: string
  socialTitle?: string
  socialUrl?: string
  title: string
  type?: string
}

const HelloBabyMeta = ({
  articleTags,
  canonical,
  keywords,
  locales,
  noindex,
  seoDescription,
  seoTitle,
  socialDescription,
  socialImage,
  socialTitle,
  socialUrl,
  title,
  type,
}: HelloBabyMetaProps) => (
  <Helmet>
    <title>{seoTitle || title}</title>
    <meta content={seoTitle || title} name="title" />
    <meta content={socialTitle || title} property="og:title" />
    <meta content={seoDescription} name="description" />
    <meta
      content={socialDescription || seoDescription}
      property="og:description"
    />
    <meta content={socialImage} property="og:image" />
    <meta content={type} property="og:type" />
    <meta content={socialUrl || canonical} property="og:url" />
    <link href={canonical} rel="canonical" />
    {keywords && <meta content={keywords} name="keywords" />}
    {articleTags &&
      articleTags.map((tag) => (
        <meta content={tag} key={tag} property="article:tag" />
      ))}
    {locales &&
      locales.map((locale) => (
        <link
          href={babylistUrl(helloBabyPostPath(locale.slug))}
          hrefLang={locale.locale}
          rel="alternate"
        />
      ))}
    {noindex && <meta content="noindex, follow" name="robots" />}
    <script async src="//www.instagram.com/embed.js" />
  </Helmet>
)
export default HelloBabyMeta
