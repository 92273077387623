import React from 'react'

interface GapStoreProps {
  className?: string
  rounded?: boolean
}

const GapStore: React.FC<GapStoreProps> = ({
  className,
  rounded = true,
}: GapStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {rounded ? (
      <rect fill="#000D42" height="24" rx="12" width="24" />
    ) : (
      <rect fill="#000D42" height="24" width="24" />
    )}
    <path
      d="M17.8452 6.50837H15.8435V6.68282H16.3709V16.9976C16.3583 17.2209 16.2743 17.299 15.9512 17.3116H15.8449V17.5H18.1026V17.3116H17.8648C17.4857 17.299 17.3458 17.1455 17.3402 16.819V12.6657H17.9977C18.9544 12.6657 19.5 12.2079 19.5 11.5953V7.66531C19.4986 7.05265 19.0286 6.50837 17.8452 6.50837ZM18.5334 11.7935C18.5386 11.8799 18.5255 11.9665 18.495 12.0476C18.4645 12.1287 18.4172 12.2025 18.3563 12.2642C18.2954 12.3259 18.2221 12.3742 18.1413 12.4058C18.0605 12.4375 17.9739 12.4519 17.8872 12.448H17.3346V6.67724H17.8382C17.9308 6.67723 18.0224 6.69556 18.1078 6.73114C18.1932 6.76673 18.2707 6.81888 18.3357 6.88457C18.4008 6.95026 18.4521 7.02817 18.4868 7.1138C18.5214 7.19943 18.5387 7.29107 18.5376 7.3834L18.5334 11.7935ZM13.5691 16.8036V16.812L12.2934 6.5H11.4387L10.1994 16.7045C10.1252 17.0716 10.0889 17.2962 9.49997 17.306V17.4944H11.0079V17.306C10.5882 17.306 10.3882 17.0604 10.4302 16.7966L10.9323 12.6573H12.1045L12.6319 16.9348C12.6319 17.1916 12.6039 17.2865 12.2934 17.306H12.1549V17.4944H14.1636V17.306H14.1104C14.0417 17.3096 13.9731 17.2993 13.9085 17.2757C13.8439 17.2522 13.7848 17.2159 13.7346 17.1689C13.6844 17.122 13.6443 17.0655 13.6166 17.0027C13.5888 16.9399 13.5741 16.8722 13.5733 16.8036H13.5691ZM10.9659 12.4633L11.4933 8.04352L12.0905 12.4689L10.9659 12.4633Z"
      fill="white"
    />
    <path
      d="M7.14791 6.74208C7.14791 6.91577 7.03696 6.90209 6.94791 6.84602C6.63301 6.63465 6.26544 6.51485 5.88627 6.50001C5.32189 6.49864 4.49997 6.74208 4.49997 7.8362V15.8424C4.50956 17.2333 5.24928 17.4904 5.97531 17.5C6.54106 17.5 6.8123 17.2333 7.04107 17.1417C7.16161 17.0952 7.29449 17.206 7.29449 17.4152H7.52737V12.6339H7.99997V12.4356H6.00682V12.6339H6.58216V16.6986C6.58472 16.7791 6.57113 16.8593 6.54219 16.9345C6.51326 17.0097 6.46956 17.0784 6.41366 17.1365C6.35776 17.1946 6.29079 17.2409 6.21669 17.2728C6.14259 17.3047 6.06285 17.3215 5.98216 17.3222C5.90335 17.3194 5.82588 17.301 5.75427 17.268C5.68266 17.235 5.61833 17.1881 5.56504 17.1301C5.51175 17.0721 5.47056 17.004 5.44387 16.9299C5.41717 16.8558 5.40551 16.7772 5.40956 16.6986V7.5189C5.40956 7.26999 5.46024 6.68737 5.8849 6.69148C6.23559 6.69148 6.47942 7.01287 6.59312 7.49976C6.71093 8.00989 6.7849 9.24214 6.7849 9.75911C6.7849 9.93417 6.84928 9.99435 7.04243 9.99435H7.41093V6.59301H7.14791V6.74208Z"
      fill="white"
    />
  </svg>
)

export default GapStore
