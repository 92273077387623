import { Node } from '@contentful/rich-text-types'
import { isHelloBabyUrl } from 'lib/urls'
import { ReactNode } from 'react'

export interface HyperlinkProps {
  node: Node
  children: ReactNode
  className?: string
}

const Hyperlink: React.FC<HyperlinkProps> = ({
  node,
  children,
  className = '',
}: HyperlinkProps) => {
  const uri = node.data?.uri
  if (!uri) return null

  const hasNoRewrite = uri.toLowerCase().includes('blnorw=true')
  const noRewrite = hasNoRewrite ? ' norewrite nooptimize' : ''

  let processedUri = uri

  if (hasNoRewrite) {
    try {
      const url = new URL(uri)
      url.searchParams.delete('blnorw')

      processedUri = url.href
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  if (isHelloBabyUrl(uri) || uri.startsWith('#')) {
    return (
      <a className={className} href={uri}>
        {children}
      </a>
    )
  }
  return (
    <a
      className={className}
      href={processedUri}
      rel={`noopener noreferrer${noRewrite}`}
      target="_blank"
    >
      {children}
    </a>
  )
}

export default Hyperlink
