import React from 'react'
import { EditContributionInfoFormProps } from 'registry/components/CrowdfundReserveFormFlow/components/RecordContribution/components/EditContributionInfoForm/EditContributionInfoForm.types'
import AccountAttributes from 'registry/components/CrowdfundReserveFormFlow/components/ContributionDetails/components/AccountAttributes/AccountAttributes'
import PaymentPreferenceTextField from 'src/routes/(registry)/list/[slug]/components/CashFundPreferencesDrawer/components/PaymentPreferenceTextField/PaymentPreferenceTextField'
import { Reservation } from 'registry/components/CrowdfundReserveFormFlow/CrowdfundReserveFormFlow.types'
import { RadioButton } from '../../../../../../../baby-design'
import css from './EditContributionInfoForm.styles.scss'

const EditContributionInfoForm: React.FC<EditContributionInfoFormProps> = ({
  errors,
  onPaymentOptionSelectionChanged,
  onReservationUpdated,
  paymentPreferences,
  reservation,
  selectedPreference,
}) => {
  const handleReservationChanged = (changes: Partial<Reservation>) =>
    onReservationUpdated?.(changes)

  const handleNameChanged = (name?: string | null) =>
    handleReservationChanged({ name: name?.toString() })

  const handleEmailChanged = (email?: string | null) =>
    handleReservationChanged({ email: email?.toString() })

  const handleAmountChanged = (amount?: string | null) =>
    handleReservationChanged({
      giftAmount: amount ? Number(amount) : undefined,
    } as Partial<Reservation>)

  return (
    <div className={css.EditContributionInfoForm}>
      <PaymentPreferenceTextField
        required
        errorMessage={errors?.name}
        label="Name"
        name="name"
        value={reservation.name?.toString()}
        onValueChanged={handleNameChanged}
      />

      <PaymentPreferenceTextField
        required
        errorMessage={errors?.email}
        label="Email"
        name="email"
        value={reservation.email?.toString()}
        onValueChanged={handleEmailChanged}
      />

      <PaymentPreferenceTextField
        required
        errorMessage={errors?.giftAmount}
        label="Gift amount"
        name="giftAmount"
        prefix="$"
        value={reservation.giftAmount?.toString()}
        onValueChanged={handleAmountChanged}
      />

      {paymentPreferences.length > 0 && (
        <div
          className={
            css.EditContributionInfoForm__PaymentPreferences__Container
          }
        >
          <p
            className={css.EditContributionInfoForm__PaymentPreferences__Header}
          >
            Payment preference
          </p>
          <div className={css.EditContributionInfoForm__PaymentPreferences}>
            {paymentPreferences.map((preference) => (
              <AccountAttributes
                InputComponent={RadioButton}
                expanded={preference === selectedPreference}
                preference={preference}
                selected={preference === selectedPreference}
                onSelectionChanged={(option) =>
                  onPaymentOptionSelectionChanged?.(option)
                }
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default EditContributionInfoForm
