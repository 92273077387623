import fetch from 'lib/fetch'
import { Cart } from 'src/types/cart'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { UPDATE_CART_SUMMARY } from 'shared/constants'
import { errorMessage } from 'lib/flash-message'
import { SHOULD_SAVE_REGISTRY_DISCOUNT_KEY } from '../../../../cart/constants'

const API_PATH = '/store/api/cart'
export const CACHE_KEY = ['cart']

const fetchCart = async (
  cart: Partial<Cart> = {},
  method: 'GET' | 'PATCH' = 'GET'
): Promise<Cart> =>
  fetch(API_PATH, {
    method,
    ...(method === 'PATCH' && { body: JSON.stringify(cart) }),
  })

const useShow = () =>
  useQuery<Cart, Error>({ queryKey: CACHE_KEY, queryFn: () => fetchCart() })

const useUpdateMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (cart: Cart) => fetchCart(cart, 'PATCH'),
    onSuccess: (_data, cart) => {
      localStorage.setItem(
        SHOULD_SAVE_REGISTRY_DISCOUNT_KEY,
        !cart.applyRegistryDiscount ? 'false' : 'true'
      )
      PubSub.publish(UPDATE_CART_SUMMARY)
    },
    onError: () => {
      errorMessage()
    },
    onSettled: () => {
      queryClient.invalidateQueries(CACHE_KEY)
    },
  })
}

export const CartAPI = {
  useShow,
  useUpdateMutation,
}
